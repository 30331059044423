import { isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../../hooks'
import { HistoricalPayment, HistoricalPaymentType } from '../../../types'

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaymentCycleCell = ({ payment }: Props) => {
  const frequency = usePaymentFrequency({
    dayType:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDayType
        : undefined,
    day:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDay
        : undefined,
    frequency:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentFrequency
        : undefined,
  })

  switch (payment.type) {
    case HistoricalPaymentType.PAYMENT_CYCLE:
      return (
        <div className="flex flex-col justify-center">
          <div className="flex items-center gap-2">
            <Typography bold>
              {intersperse(
                [
                  formatDate(
                    payment.paymentDetails.paymentCycleStart,
                    isSameYear(
                      payment.paymentDetails.paymentCycleStart,
                      payment.paymentDetails.paymentCycleEnd,
                    )
                      ? 'dd MMM.'
                      : 'dd MMM. yyyy',
                  ),
                  formatDate(
                    payment.paymentDetails.paymentCycleEnd,
                    'dd MMM. yyyy',
                  ),
                ],
                ' - ',
              )}
            </Typography>
          </div>
          <Typography
            variant="body-small"
            className="hidden text-neutral-gray-600 md:block"
          >
            {frequency}
          </Typography>
        </div>
      )

    case HistoricalPaymentType.OFF_CYCLE:
      return (
        <div className="flex flex-col justify-center">
          <div className="flex items-center gap-2">
            <Typography bold>{payment.paymentDetails.paymentLabel}</Typography>
          </div>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {intersperse(
              [
                <FormattedMessage
                  key="paymentFrequency"
                  id="contractor.paymentType.label"
                  defaultMessage="{type, select, OFF_CYCLE {Off-cycle} PAYMENT_CYCLE {Payment cycle} other {}}"
                  values={{ type: payment.type }}
                />,
                <FormattedMessage
                  key="paymentCategory"
                  id="contractor.paymentCategory.label"
                  defaultMessage="{type, select, BONUS {Bonus} COMMISSION {Commission} REIMBURSEMENT {Reimbursement} EXTRA_HOURS {Extra hours} ADJUSTMENT {Adjustment} SETTLEMENT {Settlement} other {}}"
                  values={{ type: payment.paymentCategory }}
                />,
              ],
              ' • ',
            )}
          </Typography>
        </div>
      )
  }
}
