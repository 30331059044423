import { forwardRef, Ref, type SVGProps } from 'react'

const SvgClock = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 12 12"
    role="img"
    ref={ref}
    {...props}
  >
    <circle
      cx={6}
      cy={6}
      r={5.5}
      fill={props.color || `currentColor`}
      stroke="#fff"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.7 2.5a.7.7 0 0 0-.7.7v3.2a.7.7 0 0 0 .403.634l2.384 1.192a.7.7 0 0 0 .626-1.252L6.4 5.967V3.2a.7.7 0 0 0-.7-.7"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgClock)
export default ForwardRef
