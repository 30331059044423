import { useIntl } from 'react-intl'

import { intersperse } from '@/lib/typography'

import { ContractorPaymentFrequency, ContractPaymentDayType } from '../types'

import { usePaymentDate } from './usePaymentDate'

type Props = {
  dayType?: ContractPaymentDayType
  day?: number | string | null
  frequency?: ContractorPaymentFrequency
}

export function usePaymentFrequency({ dayType, day, frequency }: Props) {
  const intl = useIntl()

  const paymentDate = usePaymentDate({
    dayType,
    day,
  })

  return intersperse(
    [
      intl.formatMessage(
        {
          id: 'contractor.paymentFrequency.label',
          defaultMessage:
            '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
        },
        { paymentFrequency: frequency },
      ),
      paymentDate,
    ],
    ', ',
  )
}
