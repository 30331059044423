import { api } from '@/lib/api'
import { handleDateQueryParams } from '@/lib/date'

import { ContractorsTransaction } from '../types'

type Params = {
  contractorId: string
  params?: Record<string, string>
}

export function getContractorPayments({ contractorId, params }: Params) {
  const searchQuery = handleDateQueryParams(params)

  const search =
    searchQuery.toString().length > 0 ? `?${searchQuery.toString()}` : ''

  return api.get<ContractorsTransaction[]>(
    `/v1/me/contractor-payments/${contractorId}/search${search}`,
  )
}
