import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { DeleteDialog } from '@/shared/components'
import { Button } from '@/shared/ui'

import { deleteRecipient } from '../api'
import { CreatedRecipient, DolarAppRecipient } from '../types'

type Props = {
  onDelete: () => void
  recipient?: CreatedRecipient | DolarAppRecipient
  recipientId?: string
}

export const DeleteRecipientAction = ({
  onDelete,
  recipient,
  recipientId,
}: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const [isDeleting, setIsDeleting] = useState(false)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: deleteRecipient,
  })

  const handleDeleteRecipient = async () => {
    if (!recipientId) {
      toast.error('Recipient ID not found')
      return
    }

    try {
      await mutateAsync({ id: recipientId })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getSingleRecipient, recipientId],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getRecipients],
      })

      setIsDeleting(false)

      toast.success(
        intl.formatMessage(
          {
            id: 'recipient.delete.success',
            defaultMessage: '{name} has been successfully deleted',
          },
          { name: recipient?.nickname },
        ),
      )

      onDelete()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <Button
        width="full"
        onClick={() => setIsDeleting(true)}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage
          defaultMessage="Delete recipient"
          id="action.deleteRecipient"
        />
      </Button>

      <DeleteDialog
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending || isSuccess}
        title={
          <FormattedMessage
            defaultMessage="Delete recipient?"
            id="recipient.delete.dialog.title"
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Are you sure you want to delete {recipient}?"
            id="recipient.delete.dialog.description"
            values={{
              recipient: recipient?.nickname,
            }}
          />
        }
        descriptionAdditional={
          <FormattedMessage
            defaultMessage="The recipient will be deleted permanently"
            id="recipient.delete.dialog.deletedPermanently"
          />
        }
        onDelete={handleDeleteRecipient}
      />
    </>
  )
}
