import { cn } from '@/lib/utils'
import { Skeleton, Typography } from '@/shared/ui'

import { Card, CardState } from '../../features/Cards/types'
import { useWallets } from '../../hooks'
import { AccountIcon } from '../AccountIcon'

type Props = {
  card: Card
}

export const CardWalletCell = ({ card }: Props) => {
  const { wallets, isPending } = useWallets({ showAll: true })

  const wallet = wallets?.find((wallet) => wallet.id === card.walletId)

  const isFailed = card.state === CardState.TERMINATED

  return (
    <div className="flex max-w-36 items-center gap-2">
      {isPending ? (
        <Skeleton className="h-8 w-36" />
      ) : (
        <>
          <AccountIcon id={wallet?.id ?? ''} />
          <Typography
            title={wallet?.label}
            className={cn('line-clamp-1', isFailed && 'line-through')}
          >
            {wallet?.label}
          </Typography>
        </>
      )}
    </div>
  )
}
