import { FormattedMessage } from 'react-intl'

import { Typography, TypographyProps } from '../ui'

export const FreeLabel = ({
  variant,
}: {
  variant?: TypographyProps['variant']
}) => {
  return (
    <Typography bold variant={variant} className="uppercase text-primary">
      <FormattedMessage id="label.free" defaultMessage="Free" />
    </Typography>
  )
}
