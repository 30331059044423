import { api } from '@/lib/api'

import { HistoricalPaymentState, RunPaymentContractor } from '../types'

type RunPaymentRequest = {
  requestId: string
  walletId: string
  paymentCycleId?: string
  fixedRatePayments: RunPaymentContractor[]
  payAsYouGoPayments: RunPaymentContractor[]
  paymentLabel?: string
  paymentCategory?: string
}

export function runPayment(data: RunPaymentRequest) {
  return api.post<{
    payrollBulkPaymentId: string
    state: HistoricalPaymentState
  }>('/v1/me/contractor-payments', data)
}
