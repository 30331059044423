import { FormattedMessage } from 'react-intl'

import { getAnimationKey } from '@/lib/utils'
import { CountryName, Widget } from '@/shared/components'
import { Document } from '@/shared/icons/solid'
import { Details, MotionDiv, Typography } from '@/shared/ui'

import { ContractorDetails } from '../../types'

type Props = {
  contractor?: ContractorDetails
  isPending: boolean
}

export const ContractorTaxInformation = ({ contractor, isPending }: Props) => {
  return (
    <Widget
      title={
        <FormattedMessage
          defaultMessage="Tax information"
          id="label.taxInformation"
        />
      }
    >
      <MotionDiv
        key={getAnimationKey(isPending)}
        className="flex flex-col gap-6"
      >
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Tax residence"
              id="label.taxResidence"
            />
          </Details.Label>

          {contractor ? (
            <CountryName
              countryCode={contractor.taxDetails.countryOfTaxResidence}
            />
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Tax identification number"
              id="label.taxIdentificationNumber"
            />
          </Details.Label>
          {contractor ? (
            <Details.Value>{contractor.taxDetails.taxId ?? '-'}</Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="Tax form" id="label.taxForm" />
          </Details.Label>
          {contractor ? (
            <div className="flex items-center gap-1">
              {contractor.taxDetails.taxForm && <Document className="size-4" />}
              <Typography className="text-right">
                {contractor.taxDetails.taxForm ?? '-'}
              </Typography>
            </div>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      </MotionDiv>
    </Widget>
  )
}
