import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import { useLocation, useParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Loader, ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { getPaymentCycleDetails } from '../api/getPaymentCycleDetails'
import {
  FixedContractorsStep,
  PayAsYouGoContractorStep,
  RunPaymentReviewStep,
} from '../components'
import { RunPaymentContractor } from '../types'

enum FormStep {
  FixedContractors = 'fixedContractors',
  PayAsYouGoContractors = 'payAsYouGoContractors',
  Review = 'review',
}

type FormState =
  | { type: FormStep.FixedContractors }
  | { type: FormStep.PayAsYouGoContractors }
  | { type: FormStep.Review }

export const RunPayment = () => {
  const location = useLocation()
  const { id: paymentCycleId } = useParams()

  const contractorsQuery = useQuery({
    queryKey: [queryKeys.getContractors, paymentCycleId],
    queryFn: () => getPaymentCycleDetails({ id: paymentCycleId ?? '' }),
    select: (data) => data?.data,
    enabled: !!paymentCycleId,
  })

  const [activeStep, setActiveStep] = useState<FormState>({
    type: FormStep.FixedContractors,
  })

  const [fixedIdsAndAmounts, setFixedIdsAndAmounts] = useState<
    RunPaymentContractor[]
  >([])

  const [payAsYouGoIdsAndAmounts, setPayAsYouGoIdsAndAmounts] = useState<
    RunPaymentContractor[]
  >([])

  useEffect(() => {
    if (contractorsQuery.data) {
      if (contractorsQuery.data.fixedRatePayments.length > 0) {
        setActiveStep({ type: FormStep.FixedContractors })
        return
      }

      if (contractorsQuery.data.payAsYouGoPayments.length > 0) {
        setActiveStep({ type: FormStep.PayAsYouGoContractors })
      }
    }
  }, [contractorsQuery.data])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep?.type) {
      case FormStep.FixedContractors:
        return 50
      case FormStep.PayAsYouGoContractors:
        return 75
      case FormStep.Review:
        return 100
    }
  }, [activeStep?.type])

  if (contractorsQuery.isPending) {
    return <Loader />
  }

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.ContractorsOverview}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === FormStep.FixedContractors ? (
          <FixedContractorsStep
            fixedIdsAndAmounts={fixedIdsAndAmounts}
            contractors={contractorsQuery.data?.fixedRatePayments ?? []}
            onContinue={(contractors) => {
              setFixedIdsAndAmounts(contractors)

              if (contractorsQuery.data?.payAsYouGoPayments?.length) {
                setActiveStep({ type: FormStep.PayAsYouGoContractors })
              } else {
                setActiveStep({ type: FormStep.Review })
              }
            }}
          />
        ) : null}

        {activeStep.type === FormStep.PayAsYouGoContractors ? (
          <PayAsYouGoContractorStep
            payAsYouGoIdsAndAmounts={payAsYouGoIdsAndAmounts}
            onContinue={(data) => {
              setPayAsYouGoIdsAndAmounts(data)

              setActiveStep({ type: FormStep.Review })
            }}
            onBack={
              contractorsQuery.data?.fixedRatePayments?.length
                ? () => setActiveStep({ type: FormStep.FixedContractors })
                : undefined
            }
            contractors={contractorsQuery.data?.payAsYouGoPayments ?? []}
          />
        ) : null}

        {activeStep.type === FormStep.Review ? (
          <RunPaymentReviewStep
            paymentCycle={contractorsQuery.data}
            onBack={() => {
              if (contractorsQuery.data?.payAsYouGoPayments?.length) {
                setActiveStep({ type: FormStep.PayAsYouGoContractors })
              } else {
                setActiveStep({ type: FormStep.FixedContractors })
              }
            }}
            fixedIdsAndAmounts={fixedIdsAndAmounts}
            payAsYouGoIdsAndAmounts={payAsYouGoIdsAndAmounts}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
