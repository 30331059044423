import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useReset } from '@/hooks/useReset'
import { isAPIError } from '@/lib/error'
import { Loader } from '@/shared/components'

import { getCurrentContractor } from '../api'
import { ExternalContractorState } from '../types'

export const ContractorStateChecker = () => {
  const navigate = useNavigate()
  const reset = useReset()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getCurrentContractor],
    queryFn: getCurrentContractor,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (
      contractorQuery.error &&
      isAPIError(contractorQuery.error) &&
      contractorQuery.error.response?.status === 401
    ) {
      reset()
    }
  }, [contractorQuery.error, reset])

  useEffect(() => {
    if (!contractorQuery.data?.state) return

    switch (contractorQuery.data.state) {
      case ExternalContractorState.ACTIVE:
        navigate(ContractorRoute.Dashboard, {
          replace: true,
        })
        break

      case ExternalContractorState.ACCEPTED_INVITE:
      case ExternalContractorState.ONBOARDING:
        navigate(ContractorRoute.Onboarding, { replace: true })
        break

      default:
        break
    }
  }, [navigate, contractorQuery.data])

  return <Loader />
}
