import { FormattedMessage } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { getFullName } from '@/lib/typography'
import { CountryWithFlag, Widget } from '@/shared/components'
import { Avatar, Details, Typography } from '@/shared/ui'

import { CreatedRecipient, EntityType } from '../../types'
import { getCountryByRails, getIconByEntityType } from '../../utils'
import { RecipientPaymentDetails } from '../RecipientPaymentDetails'

type Props = {
  recipient?: CreatedRecipient
}

export const RecipientReview = ({ recipient }: Props) => {
  const Icon = getIconByEntityType(recipient?.recipient.type)

  const getRecipientName = () => {
    switch (recipient?.recipient.type) {
      case EntityType.INDIVIDUAL:
        return getFullName(recipient.recipient.details)

      case EntityType.BUSINESS:
        return recipient.recipient.details.legalName

      default:
        return ''
    }
  }

  return (
    <div className="flex flex-col gap-8">
      {recipient ? (
        <Widget
          title={
            <FormattedMessage
              id="recipient.recipientDetails"
              defaultMessage="Recipient details"
            />
          }
        >
          {recipient ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Bank country"
                  id="label.bankCountry"
                />
              </Details.Label>

              <CountryWithFlag
                country={getCountryByRails(recipient.paymentInformation.rails)}
              />
            </Details>
          ) : null}

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Recipient type"
                id="label.recipientType"
              />
            </Details.Label>

            <div className="flex items-center gap-1">
              <Avatar icon={<Icon className="size-3" />} size="sm" />

              <Typography>
                <FormattedMessage
                  defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
                  id="recipients.entity"
                  values={{ entity: recipient.recipient.type }}
                />
              </Typography>
            </div>
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Recipient nickname"
                id="label.recipientNickname"
              />
            </Details.Label>

            <Details.Value>{recipient.nickname}</Details.Value>
          </Details>

          <Details>
            <Details.Label>
              {(() => {
                switch (recipient.recipient.type) {
                  case EntityType.INDIVIDUAL:
                    return (
                      <FormattedMessage defaultMessage="Name" id="label.name" />
                    )

                  case EntityType.BUSINESS:
                    return (
                      <FormattedMessage
                        defaultMessage="Legal Name"
                        id="label.legalName"
                      />
                    )

                  default:
                    return ''
                }
              })()}
            </Details.Label>

            <Details.Value>{getRecipientName()}</Details.Value>
          </Details>
        </Widget>
      ) : null}

      {recipient?.contactDetails?.email ||
      recipient?.contactDetails?.localPhoneNumber ? (
        <Widget
          title={
            <FormattedMessage
              id="recipient.contactDetails"
              defaultMessage="Contact details"
            />
          }
        >
          {recipient?.contactDetails.email ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Contact email"
                  id="label.contactEmail"
                />
              </Details.Label>

              <Details.Value>{recipient.contactDetails.email}</Details.Value>
            </Details>
          ) : null}

          {recipient?.contactDetails.localPhoneNumber ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Contact phone number"
                  id="label.contactPhoneNumber"
                />
              </Details.Label>

              <Details.Value>
                {formatPhoneNumberIntl(
                  recipient.contactDetails.internationalPhonePrefix +
                    recipient.contactDetails.localPhoneNumber,
                )}
              </Details.Value>
            </Details>
          ) : null}
        </Widget>
      ) : null}

      {recipient ? (
        <Widget
          title={
            <FormattedMessage
              id="label.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <RecipientPaymentDetails recipient={recipient} />
        </Widget>
      ) : null}
    </div>
  )
}
