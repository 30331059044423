import { useState } from 'react'
import { matchSorter } from 'match-sorter'
import { FormattedMessage } from 'react-intl'
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { Permission } from '@/domains/Business/types'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useSidebar } from '@/hooks/useSidebar'
import { getAnimationKey } from '@/lib/utils'
import { Plus } from '@/shared/icons/outline'
import {
  Button,
  DataTable,
  MotionDiv,
  SearchInput,
  Typography,
} from '@/shared/ui'

import {
  AccountBalance,
  MoveMoneyWidget,
  WithPermissions,
} from '../../components'
import { useWallets } from '../../hooks'

import { AccountDetailsSidebar } from './components/AccountDetailsSidebar'
import { useAccountsColumns } from './components/AccountsTable'
import { EditAccountSidebar } from './components/EditAccountSidebar'
import { StatementsSidebar } from './components/StatementsSidebar'
import { WalletState } from './types'

export const Accounts = () => {
  const columns = useAccountsColumns()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  const [accountId] = useSearchParamsValue(['id'])
  const [showDetails, setShowDetails] = useSidebar({ key: 'id' })

  const { wallets, isPending } = useWallets({
    showAll: true,
  })

  const filteredData = matchSorter(wallets ?? [], search.trim().toLowerCase(), {
    keys: [
      'label',
      'currency',
      'balance',
      'monthlyTotalMoneyIn',
      'monthlyTotalMoneyOut',
    ],
    sorter: (rankedItems) => rankedItems,
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6 md:flex-nowrap md:gap-0">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.accounts.title"
              defaultMessage="My accounts"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.accounts.subtitle"
              defaultMessage="Create accounts for different purposes and easily move your funds between them"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput value={search} onChange={setSearch} />

        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <Button
            size="md"
            leftIcon={<Plus className="size-5" />}
            variant="tertiary"
            asChild
          >
            <Link to={BusinessRoute.CreateAccount} state={{ from: location }}>
              <FormattedMessage
                id="action.newAccount"
                defaultMessage="New account"
              />
            </Link>
          </Button>
        </WithPermissions>
      </div>

      <MotionDiv>
        <div className="p-3" />

        <AccountBalance />

        <div className="p-3" />

        <MotionDiv key={getAnimationKey(isPending)}>
          <DataTable
            isLoading={isPending}
            data={filteredData ?? []}
            columns={columns}
            onRowClick={(account) => {
              if (account.state === WalletState.CLOSED) {
                return
              }

              navigate(
                generatePath(BusinessRoute.SingleAccount, { id: account.id }),
              )
            }}
            loaderOptions={{ rows: 3 }}
          />
        </MotionDiv>
      </MotionDiv>

      <AccountDetailsSidebar
        wallet={wallets?.find((w) => w.id === accountId)}
        isOpen={showDetails}
        onOpenChange={() => {
          setShowDetails((d) => !d)
          navigate(BusinessRoute.Accounts, { preventScrollReset: true })
        }}
      />

      <EditAccountSidebar
        isOpen={!!searchParams.get('edit')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('walletId')
              params.delete('edit')
              return params
            },
            { preventScrollReset: true },
          )
        }}
        account={wallets?.find((w) => w.id === searchParams.get('walletId'))}
        onDelete={() => navigate(BusinessRoute.Accounts)}
      />

      <StatementsSidebar
        account={wallets?.find((w) => w.id === searchParams.get('walletId'))}
        type={searchParams.get('statements')}
        isOpen={!!searchParams.get('statements')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('statements')
              params.delete('walletId')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </div>
  )
}
