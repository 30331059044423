import { AccountIcon } from '@/domains/Business/components'
import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { Wallet } from '../../types'

type Props = {
  wallet: Wallet
}

export const LabelCell = ({ wallet }: Props) => {
  return (
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-3">
        <AccountIcon size="lg" id={wallet.id} />
        <div className="flex flex-col">
          <Typography bold>{wallet.label}</Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {formatAmount({
              amount: wallet.balance,
              currency: wallet.currency,
            })}
          </Typography>
        </div>
      </div>
    </div>
  )
}
