import { Currency } from '@/constants/currency'
import { CountryCode } from '@/types/country'

export enum ContractorAgreementType {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
}

export enum ContractorPaymentType {
  FIXED_RATE = 'FIXED_RATE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum ContractorState {
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR',
  ACTION_NEEDED = 'ACTION_NEEDED',
  ACTIVE = 'ACTIVE',
}

export enum ContractState {
  ACTION_NEEDED = 'ACTION_NEEDED',
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  TERMINATED = 'TERMINATED',
  TERMINATION_PENDING = 'TERMINATION_PENDING',
}

export enum ContractorPaymentMethodState {
  LINKED = 'LINKED',
  NOT_LINKED = 'NOT_LINKED',
  INACTIVE = 'INACTIVE',
}

export type Contractor = {
  id: string
  firstName: string
  lastName: string
  secondLastName?: string
  email: string
  contractorRole: string
  countryOfCitizenship: null | string
  paymentType: ContractorPaymentType
  startDate: string
  lastPaymentDate: null | string
  state: ContractorState
}

export enum ContractPaymentDayType {
  LAST_BUSINESS_DAY_OF_PERIOD = 'LAST_BUSINESS_DAY_OF_PERIOD',
  LAST_FRIDAY_OF_PERIOD = 'LAST_FRIDAY_OF_PERIOD',
  CUSTOM_DAY_OF_MONTH = 'CUSTOM_DAY_OF_MONTH',
  CUSTOM_DAY_OF_WEEK = 'CUSTOM_DAY_OF_WEEK',
}

export enum ContractorFirstCycleMethod {
  FULL_AMOUNT = 'FULL_AMOUNT',
  CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
  NO_PAYMENT = 'NO_PAYMENT',
  PRO_RATA = 'PRO_RATA',
}

export enum ContractorPaymentFrequency {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  MONTHLY = 'MONTHLY',
}

export enum ContractorPaymentMethodType {
  DOLAR_TAG = 'DOLAR_TAG',
  US_BANK_ACCOUNT = 'US_BANK_ACCOUNT',
  MX_BANK_ACCOUNT = 'MX_BANK_ACCOUNT',
}

export type DolarTagDetails = {
  id: string
  type: ContractorPaymentMethodType.DOLAR_TAG
  paymentMethodInformation: {
    dolarTag: string
  }
}

export type USBankAccountDetails = {
  id: string
  type: ContractorPaymentMethodType.US_BANK_ACCOUNT
  paymentMethodInformation: {
    accountNumber: string
    routingNumber: string
    address: {
      addressStreetNumber: string
      addressStreet: string
      addressDistrict?: string
      addressCity: string
      addressState: string
      addressPostCode: string
      addressCountry: CountryCode
    }
  }
}

export type MXBankAccountDetails = {
  id: string
  type: ContractorPaymentMethodType.MX_BANK_ACCOUNT
  paymentMethodInformation: { clabe: string }
}

export type ContractorPaymentMethodDetails =
  | DolarTagDetails
  | USBankAccountDetails
  | MXBankAccountDetails

export type ContractorPaymentInformation = {
  currency: Currency
  amount: number
  frequency: ContractorPaymentFrequency
  dayType: ContractPaymentDayType
  day?: number
  firstCycleMethod: ContractorFirstCycleMethod
  firstCycleAmount?: number
}

export type Contract = {
  id: string
  state: ContractState
  paymentType: ContractorPaymentType
  agreementType: ContractorAgreementType
  contractorRole: string
  scopeOfWork: string
  startDate: string
  endDate: null | string
  createdByBusinessUserId: string
  paymentDetails?: ContractorPaymentInformation
}

export type ContractorDetails = {
  identityId: string
  id: string
  state: ContractorState
  taxDetails: {
    countryOfTaxResidence?: string
    taxId?: string
    taxIdType?: string
    taxForm?: string
  }
  paymentMethod: {
    linkedOn?: string
    state: ContractorPaymentMethodState
    paymentMethodDetails?: ContractorPaymentMethodDetails
  }
  personalDetails: {
    firstName: string
    lastName: string
    secondLastName?: string
    email: string
    internationalPhonePrefix: string
    localPhoneNumber: string
    dateOfBirth?: string
    countryOfCitizenship?: string
    residentialAddress?: {
      addressStreetNumber: string
      addressStreet: string
      addressDistrict: string
      addressCity: string
      addressState: string
      addressPostCode: string
      addressCountry: string
    }
  }
  contracts: Contract[]
}

export type ContractDocument = {
  id: string
  fileName: string
  uploadedOn: string
}

export type ContractorPayment = {
  id: string
  businessId: string
  state: 'CREATED'
  paymentCycleStart: string
  paymentCycleEnd: string
  paymentDueDate: string
  paymentFrequency: ContractorPaymentFrequency
  paymentDayType: ContractPaymentDayType
  paymentDay?: number | null
  numberOfPayments: number
}

export type ContractorPaymentCyclePayment = {
  contractId: string
  contractorId: string
  personalDetails: {
    firstName: string
    lastName: string
    secondLastName?: string
  }
  paymentMethodType: ContractorPaymentMethodType
  paymentAmount?: { amount: string; currency: Currency } | null
}

export type ContractorPaymentCycleDetails = {
  id: string
  businessId: string
  paymentCycleStart: string
  paymentCycleEnd: string
  paymentFrequency: ContractorPaymentFrequency
  paymentDayType: ContractPaymentDayType
  paymentDay: number | null
  fixedRatePayments: ContractorPaymentCyclePayment[]
  payAsYouGoPayments: ContractorPaymentCyclePayment[]
}

export type ContractorOffCycleDetails = {
  fixedRatePayments: ContractorPaymentCyclePayment[]
  payAsYouGoPayments: ContractorPaymentCyclePayment[]
}

export enum HistoricalPaymentState {
  FAILED_TO_EXECUTE = 'FAILED_TO_EXECUTE',
  CREATED = 'CREATED',
  EXECUTING = 'EXECUTING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}

export enum HistoricalPaymentType {
  OFF_CYCLE = 'OFF_CYCLE',
  PAYMENT_CYCLE = 'PAYMENT_CYCLE',
}

type SharedHistoricalPayment = {
  id: string
  bulkPaymentId: string | null
  state: HistoricalPaymentState
  paymentCategory: OffCyclePaymentCategory
  paidOn: string
  numberOfPayments: number
  totalAmount: {
    amount: number
    currency: Currency
  }
}

export type PaymentCycleHistoricalPayment = SharedHistoricalPayment & {
  type: HistoricalPaymentType.PAYMENT_CYCLE
  paymentDetails: {
    paymentCycleStart: string
    paymentCycleEnd: string
    paymentFrequency: ContractorPaymentFrequency
    paymentDayType: ContractPaymentDayType
    paymentDay?: number
  }
}

export type OffCycleHistoricalPayment = SharedHistoricalPayment & {
  type: HistoricalPaymentType.OFF_CYCLE
  paymentDetails: {
    paymentLabel?: string
  }
}

export type HistoricalPayment =
  | PaymentCycleHistoricalPayment
  | OffCycleHistoricalPayment

export type HistoricalPaymentDetails = HistoricalPayment & {
  walletId: string
}

export type RunPaymentContractor = {
  contractorId: string
  contractId: string
  amount: { amount: string; currency: Currency }
}

export type DisplayableRunPaymentContractor = {
  fullName: string
  amount: { amount: string; currency: Currency }
  paymentMethodType?: ContractorPaymentMethodType
}

export enum OffCyclePaymentCategory {
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
  REIMBURSEMENT = 'REIMBURSEMENT',
  EXTRA_HOURS = 'EXTRA_HOURS',
  ADJUSTMENT = 'ADJUSTMENT',
  SETTLEMENT = 'SETTLEMENT',
}

export enum ContractorTransactionState {
  EXECUTING = 'EXECUTING',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

type SharedContractorTransaction = {
  state: ContractorTransactionState
  paymentMethodId: string
  paymentMethodType: ContractorPaymentMethodType
  paymentCategory: OffCyclePaymentCategory
  amount: { amount: number; currency: Currency }
  paymentDate: string
  transactionId: string | null
  payrollPaymentId: string | null
}

type PaymentCycleContractorTransaction = SharedContractorTransaction & {
  type: HistoricalPaymentType.PAYMENT_CYCLE
  paymentDetails: {
    paymentCycleStart: string
    paymentCycleEnd: string
    paymentFrequency: ContractorPaymentFrequency
    paymentDayType: ContractPaymentDayType
    paymentDay?: number
    dolarTag?: string
  }
}

type OffCycleContractorTransaction = SharedContractorTransaction & {
  type: HistoricalPaymentType.OFF_CYCLE
  paymentDetails: {
    paymentLabel?: string
    dolarTag?: string
  }
}

export type ContractorsTransaction =
  | PaymentCycleContractorTransaction
  | OffCycleContractorTransaction
