import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { Wallet, WalletState } from '../../types'

const badgeVariantByWalletState: Record<WalletState, StatusVariant> = {
  [WalletState.ACTIVE]: 'active',
  [WalletState.CLOSED]: 'failed',
}

type Props = {
  wallet: Wallet
}

export const AccountStateCell = ({ wallet }: Props) => {
  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariantByWalletState[wallet.state]}
        title={
          <FormattedMessage
            id="account.state.label"
            defaultMessage="{state, select, ACTIVE {Active} CLOSED {Terminated} other {}}"
            values={{ state: wallet.state }}
          />
        }
      />
    </div>
  )
}
