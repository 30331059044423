import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { formatDate, isSameYear } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { AccountSelect } from '@/domains/Business/components'
import { useWallets } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatAmount } from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { getFullName, intersperse } from '@/lib/typography'
import { FreeLabel, GoBackButton, Widget } from '@/shared/components'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Card,
  Details,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getOffCycleDetails, runPayment } from '../../../api'
import { ContractorsReviewRow } from '../../ContractorsReviewRow'

const RUN_PAYMENT_FORM_ID = 'run-payment-form'

const runPaymentSchema = z.object({
  walletId: z.string().min(1),
  fromBalance: z.string(),
})

type RunPaymentSchema = z.infer<typeof runPaymentSchema>

type Props = {
  onBack: () => void
}

export const UploadManualCSVReview = ({ onBack }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  // const navigate = useNavigate()

  const contractorsQuery = useQuery({
    queryKey: [queryKeys.getOffCycleDetails],
    queryFn: getOffCycleDetails,
    select: (data) => data?.data,
  })

  const { isPending, isSuccess } = useMutation({
    mutationFn: runPayment,
  })

  const { wallets, isPending: walletsPending } = useWallets()

  // const frequency = usePaymentFrequency({
  //   dayType: paymentCycle?.paymentDayType ?? undefined,
  //   day: paymentCycle?.paymentDay ?? undefined,
  //   frequency: paymentCycle?.paymentFrequency ?? undefined,
  // })

  const form = useForm<RunPaymentSchema>({
    mode: 'onChange',
    resolver: zodResolver(runPaymentSchema),
    values: {
      walletId: wallets?.[0].id ?? '',
      fromBalance: Big(wallets?.[0].balance ?? 0).toFixed(),
    },
  })

  const totalFixedAmount = contractorsQuery.data?.fixedRatePayments
    .reduce((acc, row) => {
      return acc.add(row.paymentAmount?.amount ?? 0)
    }, Big(0))
    .toFixed()

  const totalPayAsYouGoAmount = contractorsQuery.data?.payAsYouGoPayments
    .reduce((acc, row) => {
      return acc.add(row.paymentAmount?.amount ?? 0)
    }, Big(0))
    .toFixed()

  const totalAmount = Big(totalFixedAmount ?? 0)
    .add(Big(totalPayAsYouGoAmount ?? 0))
    .toFixed(2)

  const onSubmit: SubmitHandler<RunPaymentSchema> = async () => {
    try {
      // const response = await mutateAsync({
      //   requestId: crypto.randomUUID(),
      //   walletId: data.walletId,
      //   fixedRatePayments: fixedIdsAndAmounts,
      //   payAsYouGoPayments: payAsYouGoIdsAndAmounts,
      //   paymentCycleId: paymentCycle?.id,
      // })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCycles],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCyclesCount],
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Payment sent successfully',
          id: 'contractor.runPayment.success',
        }),
      )

      // navigate(
      //   `${BusinessRoute.ContractorsHistoricalPayments}?id=${response.data.payrollBulkPaymentId}`,
      // )
    } catch (error) {
      notifyError(error)
    }
  }

  const notEnoughBalance = Big(form.watch('fromBalance')).lt(Big(totalAmount))

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review your payment"
            id="contractor.runPayment.review.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="contractor.runPayment.review.subtitle"
            defaultMessage="Choose account, review and confirm your payment"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={RUN_PAYMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    label={
                      notEnoughBalance && !walletsPending
                        ? intl.formatMessage({
                            defaultMessage: 'Insufficient balance',
                            id: 'validation.balance.insufficient',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Paying from',
                            id: 'label.payingFrom',
                          })
                    }
                    showLabel
                    hasError={notEnoughBalance && !walletsPending}
                    onChange={(v) => {
                      field.onChange(v)

                      const selected = wallets?.find(
                        (wallet) => wallet.id === v,
                      )

                      if (selected) {
                        form.setValue(
                          'fromBalance',
                          Big(selected.balance).toFixed(),
                        )

                        form.trigger()
                      }
                    }}
                  />
                )
              }}
            />

            <div className="p-3" />

            <div className="flex flex-col gap-6">
              <Widget
                title={
                  <FormattedMessage
                    defaultMessage="Payment summary"
                    id="label.paymentSummary"
                  />
                }
              >
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="label.paymentCycle"
                      defaultMessage="Payment cycle"
                    />
                  </Details.Label>

                  <div className="flex items-center gap-1">
                    <Typography>
                      {intersperse(
                        [
                          formatDate(
                            '2025-04-01',
                            isSameYear('2025-04-01', '2025-04-30')
                              ? 'dd MMM.'
                              : 'dd MMM. yyyy',
                          ),
                          formatDate('2025-04-30', 'dd MMM. yyyy'),
                        ],
                        ' - ',
                      )}
                    </Typography>

                    {/* <Typography className="hidden md:block">
                      ({frequency})
                    </Typography> */}
                  </div>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total contractors"
                      id="label.totalContractors"
                    />
                  </Details.Label>

                  <Details.Value>
                    {(contractorsQuery.data?.fixedRatePayments.length ?? 0) +
                      (contractorsQuery.data?.payAsYouGoPayments.length ?? 0)}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total payment amount"
                      id="label.totalPaymentAmount"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatAmount({
                      amount: totalAmount,
                      currency: Currency.USDC,
                    })}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Your fee"
                      id="label.yourFee"
                    />
                  </Details.Label>

                  <FreeLabel />
                </Details>
              </Widget>

              <Widget
                variant="form"
                title={
                  <FormattedMessage
                    defaultMessage="Contractors"
                    id="label.contractors"
                  />
                }
              >
                {contractorsQuery.data?.fixedRatePayments.length ? (
                  <Accordion type="single" collapsible>
                    <Card asChild size="upload">
                      <AccordionItem value="fixed-rate">
                        <AccordionTrigger>
                          <div className="flex flex-col">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="Fixed rate"
                                id="label.fixedRate"
                              />
                            </Typography>
                            <Typography
                              className="text-neutral-gray-600"
                              variant="body-small"
                            >
                              <FormattedMessage
                                id="contractor.runPayment.totalContractors"
                                defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                values={{
                                  count:
                                    contractorsQuery.data?.fixedRatePayments
                                      .length ?? 0,
                                }}
                              />
                            </Typography>
                          </div>

                          <div className="ml-auto mr-2">
                            <Typography>
                              {formatAmount({
                                amount: totalFixedAmount ?? 0,
                                currency: Currency.USDC,
                              })}
                            </Typography>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <ContractorsReviewRow
                            contractors={
                              contractorsQuery.data?.fixedRatePayments.map(
                                (contractor) => ({
                                  fullName: getFullName(
                                    contractor.personalDetails,
                                  ),
                                  amount: {
                                    amount:
                                      contractor.paymentAmount?.amount ?? '0',
                                    currency:
                                      contractor.paymentAmount?.currency ??
                                      Currency.USDC,
                                  },
                                  paymentMethodType:
                                    contractor.paymentMethodType,
                                }),
                              ) ?? []
                            }
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Card>
                  </Accordion>
                ) : null}

                {contractorsQuery.data?.payAsYouGoPayments.length ? (
                  <Accordion type="single" collapsible>
                    <Card asChild size="upload">
                      <AccordionItem value="fixed-rate">
                        <AccordionTrigger>
                          <div className="flex flex-col">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="Pay-as-you-go"
                                id="label.payAsYouGo"
                              />
                            </Typography>
                            <Typography
                              className="text-neutral-gray-600"
                              variant="body-small"
                            >
                              <FormattedMessage
                                id="contractor.runPayment.totalContractors"
                                defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                values={{
                                  count:
                                    contractorsQuery.data?.payAsYouGoPayments
                                      .length ?? 0,
                                }}
                              />
                            </Typography>
                          </div>

                          <div className="ml-auto mr-2">
                            <Typography>
                              {formatAmount({
                                amount: totalPayAsYouGoAmount ?? '0',
                                currency: Currency.USDC,
                              })}
                            </Typography>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <ContractorsReviewRow
                            contractors={
                              contractorsQuery.data?.payAsYouGoPayments.map(
                                (contractor) => ({
                                  fullName: getFullName(
                                    contractor.personalDetails,
                                  ),
                                  amount: {
                                    amount:
                                      contractor.paymentAmount?.amount ?? '0',
                                    currency:
                                      contractor.paymentAmount?.currency ??
                                      Currency.USDC,
                                  },
                                  paymentMethodType:
                                    contractor.paymentMethodType,
                                }),
                              ) ?? []
                            }
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Card>
                  </Accordion>
                ) : null}
              </Widget>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={notEnoughBalance || isPending || isSuccess}
            loading={isPending || isSuccess}
            form={RUN_PAYMENT_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Send {amount}"
              id="contractor.runPayment.action.send"
              values={{
                amount: formatAmount({
                  amount: totalAmount,
                  currency: Currency.USDC,
                }),
              }}
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
