import { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton, ProgressButton } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FullScreen,
  Input,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { useWallets } from '../../hooks'

import { createAccount, getAccountDetails } from './api'

const CREATE_ACC_FORM_ID = 'create-account-form'

const DEFAULT_VALUES: CreateAccountSchema = {
  label: '',
  currency: Currency.USDC,
}

const createAccountSchema = z.object({
  label: z.string().min(1, { message: 'validation.accountName.required' }),
  currency: z.nativeEnum(Currency),
})

export type CreateAccountSchema = z.infer<typeof createAccountSchema>

export const CreateAccount = () => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { wallets, accountId } = useWallets()

  const location = useLocation()
  const navigate = useNavigate()

  const { mutateAsync: createAccountAsync, isPending: createAccountIsPending } =
    useMutation({
      mutationFn: createAccount,
    })

  const {
    mutateAsync: accountDetailsAsync,
    isPending: accountDetailsIsPending,
  } = useMutation({
    mutationFn: getAccountDetails,
  })

  const form = useForm<CreateAccountSchema>({
    mode: 'onChange',
    resolver: zodResolver(createAccountSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const onSubmit: SubmitHandler<CreateAccountSchema> = useCallback(
    async (data) => {
      try {
        if (
          wallets?.find(
            (wallet) =>
              wallet.label.toLocaleLowerCase() ===
              data.label.toLocaleLowerCase(),
          )
        ) {
          form.setError('label', {
            type: 'manual',
            message: 'validation.accountName.exists',
          })
          return
        }

        if (!accountId) {
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Account not found',
              id: 'account.error.accountNotFound',
            }),
          )
          return
        }

        const account = await createAccountAsync({
          accountId,
          ...data,
        })

        const lastCreatedWallet =
          account.data.wallets[account.data.wallets.length - 1]

        // trigger BE to update newly created account details
        await accountDetailsAsync({ id: lastCreatedWallet.id })

        queryClient.refetchQueries({
          queryKey: [queryKeys.getAccount],
        })

        toast.success(
          intl.formatMessage({
            defaultMessage: 'Account created successfully',
            id: 'account.create.createdSuccessfully',
          }),
        )

        navigate(BusinessRoute.Accounts)
      } catch (error) {
        notifyError(error)
      }
    },
    [
      accountDetailsAsync,
      accountId,
      createAccountAsync,
      form,
      intl,
      navigate,
      notifyError,
      wallets,
    ],
  )

  return (
    <FullScreen>
      <ProgressButton
        progress={0}
        to={location.state?.from ?? BusinessRoute.Accounts}
      />

      <GoBackButton to={location.state?.from ?? BusinessRoute.Accounts} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Create a new account"
            id="account.create.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Name your account and start transacting!"
            id="account.create.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CREATE_ACC_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Account name',
                        id: 'label.accountName',
                      })}
                      {...field}
                    />
                  </FormControl>

                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Account name"
                      id="label.accountName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <div className="p-8" />

        <Button
          width="full"
          form={CREATE_ACC_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          loading={createAccountIsPending || accountDetailsIsPending}
          disabled={
            createAccountIsPending ||
            accountDetailsIsPending ||
            !form.formState.isValid
          }
          type="submit"
          className="mt-auto"
        >
          <FormattedMessage
            defaultMessage="Create account"
            id="action.createAccount"
          />
        </Button>

        <div className="p-6" />
      </SlideInScreen>
    </FullScreen>
  )
}
