import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { AccountSelect } from '@/domains/Business/components'
import { useWallets } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatAmount } from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { FreeLabel, GoBackButton, Widget } from '@/shared/components'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AnimatedFormLabel,
  Button,
  Card,
  Details,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { runPayment } from '../../../api'
import { ContractorOffCycleDetails, RunPaymentContractor } from '../../../types'
import {
  getDisplayableContractors,
  getTotalPaymentAmount,
} from '../../../utils'
import { ContractorsReviewRow } from '../../ContractorsReviewRow'
import { OffCycleCategorySelect } from '../OffCycleCategorySelect'

const RUN_PAYMENT_FORM_ID = 'run-payment-form'

const offCycleReviewSchema = z.object({
  walletId: z.string().min(1),
  fromBalance: z.string(),
  paymentLabel: z.string().min(1, {
    message: 'validation.label.required',
  }),
  paymentCategory: z.string().min(1),
})

type OffCycleReviewSchema = z.infer<typeof offCycleReviewSchema>

type Props = {
  paymentCycle?: ContractorOffCycleDetails
  onBack: () => void
  fixedIdsAndAmounts: RunPaymentContractor[]
  payAsYouGoIdsAndAmounts: RunPaymentContractor[]
}

export const OffCycleReviewStep = ({
  onBack,
  fixedIdsAndAmounts,
  payAsYouGoIdsAndAmounts,
  paymentCycle,
}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const { wallets, isPending } = useWallets()

  const form = useForm<OffCycleReviewSchema>({
    mode: 'onChange',
    resolver: zodResolver(offCycleReviewSchema),
    values: {
      walletId: wallets?.[0].id ?? '',
      fromBalance: Big(wallets?.[0].balance ?? 0).toFixed(),
      paymentLabel: '',
      paymentCategory: '',
    },
  })

  const {
    mutateAsync,
    isPending: isRunningPayment,
    isSuccess,
  } = useMutation({
    mutationFn: runPayment,
  })

  const totalFixedAmount = getTotalPaymentAmount(fixedIdsAndAmounts)
  const totalPayAsYouGoAmount = getTotalPaymentAmount(payAsYouGoIdsAndAmounts)

  const totalAmount = totalFixedAmount.add(totalPayAsYouGoAmount).toFixed(2)

  const fixedRatePayments = getDisplayableContractors(
    paymentCycle?.fixedRatePayments,
    fixedIdsAndAmounts,
  )

  const payAsYouGoPayments = getDisplayableContractors(
    paymentCycle?.payAsYouGoPayments,
    payAsYouGoIdsAndAmounts,
  )

  const hasSomeContractors =
    Big(totalFixedAmount).gt(0) || Big(totalPayAsYouGoAmount).gt(0)

  const onSubmit: SubmitHandler<OffCycleReviewSchema> = async (data) => {
    try {
      const response = await mutateAsync({
        requestId: crypto.randomUUID(),
        walletId: data.walletId,
        fixedRatePayments: fixedIdsAndAmounts,
        payAsYouGoPayments: payAsYouGoIdsAndAmounts,
        paymentLabel: data.paymentLabel,
        paymentCategory: data.paymentCategory,
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCycles],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCyclesCount],
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Payment sent successfully',
          id: 'contractor.runPayment.success',
        }),
      )

      navigate(
        `${BusinessRoute.ContractorsHistoricalPayments}?id=${response.data.payrollBulkPaymentId}`,
      )
    } catch (error) {
      notifyError(error)
    }
  }

  const notEnoughBalance = Big(form.watch('fromBalance')).lt(Big(totalAmount))

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review your payment"
            id="contractor.runPayment.review.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="contractor.runPayment.review.subtitle"
            defaultMessage="Choose account, review and confirm your payment"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={RUN_PAYMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="walletId"
                render={({ field }) => {
                  return (
                    <AccountSelect
                      value={field.value}
                      label={
                        notEnoughBalance && !isPending
                          ? intl.formatMessage({
                              defaultMessage: 'Insufficient balance',
                              id: 'validation.balance.insufficient',
                            })
                          : intl.formatMessage({
                              defaultMessage: 'Paying from',
                              id: 'label.payingFrom',
                            })
                      }
                      showLabel
                      hasError={notEnoughBalance && !isPending}
                      onChange={(v) => {
                        field.onChange(v)

                        const selected = wallets?.find(
                          (wallet) => wallet.id === v,
                        )

                        if (selected) {
                          form.setValue(
                            'fromBalance',
                            Big(selected.balance).toFixed(),
                          )

                          form.trigger()
                        }
                      }}
                    />
                  )
                }}
              />

              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="paymentLabel"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Label (e.g. Laptop Bonus)',
                            id: 'contractor.offCyclePayment.review.paymentLabel',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Label (e.g. Laptop Bonus)"
                          id="contractor.offCyclePayment.review.paymentLabel"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentCategory"
                  render={({ field }) => (
                    <OffCycleCategorySelect
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>

            <div className="p-3" />

            <div className="flex flex-col gap-6">
              <Widget
                title={
                  <FormattedMessage
                    defaultMessage="Payment summary"
                    id="label.paymentSummary"
                  />
                }
              >
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total contractors"
                      id="label.totalContractors"
                    />
                  </Details.Label>

                  <Details.Value>
                    {fixedIdsAndAmounts.length + payAsYouGoIdsAndAmounts.length}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total payment amount"
                      id="label.totalPaymentAmount"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatAmount({
                      amount: totalAmount,
                      currency: Currency.USDC,
                    })}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Your fee"
                      id="label.yourFee"
                    />
                  </Details.Label>

                  <FreeLabel />
                </Details>
              </Widget>

              {hasSomeContractors ? (
                <Widget
                  variant="form"
                  title={
                    <FormattedMessage
                      defaultMessage="Contractors"
                      id="label.contractors"
                    />
                  }
                >
                  {fixedIdsAndAmounts.length > 0 ? (
                    <Accordion type="single" collapsible>
                      <Card asChild size="upload">
                        <AccordionItem value="fixed-rate">
                          <AccordionTrigger>
                            <div className="flex flex-col">
                              <Typography>
                                <FormattedMessage
                                  defaultMessage="Fixed rate"
                                  id="label.fixedRate"
                                />
                              </Typography>
                              <Typography
                                className="text-neutral-gray-600"
                                variant="body-small"
                              >
                                <FormattedMessage
                                  id="contractor.runPayment.totalContractors"
                                  defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                  values={{
                                    count: fixedIdsAndAmounts?.length ?? 0,
                                  }}
                                />
                              </Typography>
                            </div>

                            <div className="ml-auto mr-2">
                              <Typography>
                                {formatAmount({
                                  amount: totalFixedAmount.toNumber(),
                                  currency: Currency.USDC,
                                })}
                              </Typography>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <ContractorsReviewRow
                              contractors={fixedRatePayments}
                            />
                          </AccordionContent>
                        </AccordionItem>
                      </Card>
                    </Accordion>
                  ) : null}

                  {payAsYouGoIdsAndAmounts.length > 0 ? (
                    <Accordion type="single" collapsible>
                      <Card asChild size="upload">
                        <AccordionItem value="fixed-rate">
                          <AccordionTrigger>
                            <div className="flex flex-col">
                              <Typography>
                                <FormattedMessage
                                  defaultMessage="Pay-as-you-go"
                                  id="label.payAsYouGo"
                                />
                              </Typography>
                              <Typography
                                className="text-neutral-gray-600"
                                variant="body-small"
                              >
                                <FormattedMessage
                                  id="contractor.runPayment.totalContractors"
                                  defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                  values={{
                                    count: payAsYouGoIdsAndAmounts?.length ?? 0,
                                  }}
                                />
                              </Typography>
                            </div>

                            <div className="ml-auto mr-2">
                              <Typography>
                                {formatAmount({
                                  amount: totalPayAsYouGoAmount.toNumber(),
                                  currency: Currency.USDC,
                                })}
                              </Typography>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <ContractorsReviewRow
                              contractors={payAsYouGoPayments}
                            />
                          </AccordionContent>
                        </AccordionItem>
                      </Card>
                    </Accordion>
                  ) : null}
                </Widget>
              ) : null}
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={
              notEnoughBalance ||
              !hasSomeContractors ||
              isRunningPayment ||
              isSuccess ||
              !form.formState.isValid
            }
            loading={isRunningPayment || isSuccess}
            form={RUN_PAYMENT_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Send {amount}"
              id="contractor.runPayment.action.send"
              values={{
                amount: formatAmount({
                  amount: totalAmount,
                  currency: Currency.USDC,
                }),
              }}
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
