import { formatMoney } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { ContractorsTransaction } from '../../../types'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentAmountCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>{formatMoney(payment.amount.amount)}</Typography>
    </div>
  )
}
