import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { DESKTOP_BREAKPOINT } from '@/constants/breakpoints'
import { queryKeys } from '@/constants/queryKeys'
import { getFullName } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { Avatar, Badge, Skeleton, Typography } from '@/shared/ui'

import { getIdentity } from '../../api'
import { Card } from '../../features/Cards/types'
import { useBusinessRole, useBusinessUser } from '../../hooks'
import { Role } from '../../types'

type Props = {
  card: Card
}

const useContentWidth = (content: string) => {
  const measureRef = useRef<HTMLDivElement>(null)
  const [contentWidth, setContentWidth] = useState<number | null>(null)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= DESKTOP_BREAKPOINT && measureRef.current) {
        measureRef.current.style.position = 'absolute'
        measureRef.current.style.visibility = 'hidden'
        measureRef.current.style.whiteSpace = 'nowrap'
        const rect = measureRef.current.getBoundingClientRect()
        setContentWidth(rect.width)
      } else {
        setContentWidth(null)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [content])

  return { measureRef, contentWidth }
}

export const CardholderCell = ({ card }: Props) => {
  const { role, isPending } = useBusinessRole()
  const businessUser = useBusinessUser()
  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  const isUser = useMemo(
    () => card.identityId === userQuery.data?.id,
    [card.identityId, userQuery.data?.id],
  )

  const cardUserName = card.printedUserName
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase())

  const fullName = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
      case Role.READ_ONLY:
        return cardUserName

      case Role.PAYMENT_OPS:
      case Role.CARD_USER:
        return getFullName(businessUser)

      default:
        return ''
    }
  }, [cardUserName, businessUser, role])

  const { measureRef, contentWidth } = useContentWidth(fullName)

  const CardholderCard = ({
    isMeasurement = false,
  }: {
    isMeasurement?: boolean
  }) => (
    <div className="flex items-center gap-2">
      <Avatar size="md" variant="neutral" name={card.printedUserName} />
      <Typography className={!isMeasurement ? cn('line-clamp-1') : undefined}>
        {fullName}
      </Typography>
      {isUser && (
        <Badge className="capitalize" variant="neutral">
          <Typography bold variant="body-small">
            <FormattedMessage id="label.you" defaultMessage="You" />
          </Typography>
        </Badge>
      )}
    </div>
  )

  return (
    <div className="flex items-center pr-10">
      {isPending ? (
        <Skeleton className="h-[21px] w-36" />
      ) : (
        <>
          <div
            style={contentWidth ? { width: `${contentWidth}px` } : undefined}
          >
            <CardholderCard />
          </div>

          <div ref={measureRef}>
            <CardholderCard isMeasurement />
          </div>
        </>
      )}
    </div>
  )
}
