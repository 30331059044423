import { useEffect } from 'react'
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage, useIntl } from 'react-intl'

import { PAGE_SIZE } from '@/constants/pagination'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchInput } from '@/hooks/useSearchInput'
import { downloadFile, getAnimationKey } from '@/lib/utils'
import { DownloadFile } from '@/shared/icons/outline'
import { Button, MotionDiv, SearchInput, Typography } from '@/shared/ui'

import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  StateBadge,
  StateFilter,
  TransactionDetailsSidebar,
  TransactionsTable,
  TypeBadge,
  TypeFilter,
  useTransactionColumns,
} from '../../components'
import { useTransactionUtils } from '../../hooks'

import { getTransactionByType, getTransactions } from './api'
import { getTransactionsCSV } from './api/getTransactionsCSV'
import { Transaction } from './types'

export const Transactions = () => {
  const {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const intl = useIntl()
  const notifyError = useErrorToast()
  const columns = useTransactionColumns()

  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const {
    data,
    isPending,
    isFetching,
    isFetchingNextPage,
    isFetched,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getTransactions, params],
    queryFn: ({ pageParam }) =>
      getTransactions({
        ...params,
        page: String(pageParam),
        limit: String(PAGE_SIZE),
      }),
    select: (data) => data.pages.flatMap((page) => page.data),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.data?.length < PAGE_SIZE) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const singleTransaction = useQuery({
    queryKey: [
      queryKeys.getTransaction,
      transactionIdFromQuery,
      transactionTypeFromQuery,
    ],
    queryFn: () =>
      getTransactionByType({
        type: transactionTypeFromQuery,
        id: transactionIdFromQuery,
      }),
    select: (data: AxiosResponse<Transaction>) => data?.data,
    enabled: !!transactionIdFromQuery,
  })

  const { ref, inView } = useInView({
    threshold: 0.1,
    rootMargin: '100px',
  })

  useEffect(() => {
    if (inView && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetching, isFetchingNextPage])

  const { mutateAsync, isPending: isDownloadingCSV } = useMutation({
    mutationFn: getTransactionsCSV,
  })

  const downloadCSV = async () => {
    try {
      const response = await mutateAsync(params)

      downloadFile(
        response.data,
        intl.formatMessage({
          id: 'transactions.statement.csv',
          defaultMessage: 'transactions.csv',
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.transactions.title"
              defaultMessage="Transactions"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.transactions.subtitle"
              defaultMessage="Search, filter and export all your past transactions"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <TypeFilter />
          <AccountsFilter />
          <DateFilter />
          <StateFilter />
          <CurrencyFilter />
        </FiltersWidget>

        <Button
          size="md"
          onClick={downloadCSV}
          loading={isDownloadingCSV}
          disabled={isDownloadingCSV}
          leftIcon={<DownloadFile className="size-5" />}
          loaderPosition="left"
          variant="tertiary"
        >
          <FormattedMessage
            id="action.exportCSV"
            defaultMessage="Export .csv"
          />
        </Button>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <TypeBadge />
        <AccountsBadge />
        <DateBadge />
        <StateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(isPending, params)}>
        {data?.length === 0 && Object.keys(params).length === 0 ? (
          <EmptyTransactionsCard />
        ) : (
          <>
            <TransactionsTable
              isLoading={isPending || (!isFetchingNextPage && isFetching)}
              onRowClick={openSidebar}
              isLoadingMore={isFetchingNextPage}
              columns={columns}
              data={data ?? []}
              loaderOptions={{ rows: 4 }}
            />

            {isFetched && <div ref={ref} />}
          </>
        )}
      </MotionDiv>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
