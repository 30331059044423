import { FormattedMessage, useIntl } from 'react-intl'

import {
  MotionDiv,
  MotionSpan,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
  placeholder?: string | React.ReactNode
  volumes: string[]
}

export const MonthlyVolumeSelectField = ({
  value,
  onChange,
  volumes,
}: Props) => {
  const intl = useIntl()

  return (
    <Select onValueChange={onChange} defaultValue={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.monthlyTransactionVolume"
                defaultMessage="Monthly transaction volume"
              />
            </Typography>
            <MotionSpan key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="monthlyTransactionVolume.label"
                  defaultMessage="{method, select, LESS_THAN_10K {Up to $10,000.00 USD} BETWEEN_10K_50K {Between $10,000.00 USD and $50,000.00 USD} BETWEEN_50K_100K {Between $50,000.00 USD and $100,000.00 USD} BETWEEN_100K_250K {Between $100,000.00 USD and $250,000.00 USD} BETWEEN_250K_500K {Between $250,000.00 USD and $500,000.00 USD} MORE_THAN_500K {More than $500,000.00 USD} other {}}"
                  values={{ method: value }}
                />
              </Typography>
            </MotionSpan>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={intl.formatMessage({
              id: 'label.monthlyTransactionVolume',
              defaultMessage: 'Monthly transaction volume',
            })}
          />
        )}
      </SelectTrigger>

      <SelectContent>
        {volumes.map((volume) => (
          <SelectItem disabled={volume === value} key={volume} value={volume}>
            <Typography>
              <FormattedMessage
                id="monthlyTransactionVolume.label"
                defaultMessage="{method, select, LESS_THAN_10K {Up to $10,000.00 USD} BETWEEN_10K_50K {Between $10,000.00 USD and $50,000.00 USD} BETWEEN_50K_100K {Between $50,000.00 USD and $100,000.00 USD} BETWEEN_100K_250K {Between $100,000.00 USD and $250,000.00 USD} BETWEEN_250K_500K {Between $250,000.00 USD and $500,000.00 USD} MORE_THAN_500K {More than $500,000.00 USD} other {}}"
                values={{ method: volume }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
