import { Fragment } from 'react'

import {
  RecipientAccountAvatar,
  RecipientAvatar,
} from '@/domains/Business/components'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import {
  getRecipientAccountNumber,
  getRecipientPaymentMethod,
} from '@/lib/recipient'
import { Button, Card, Skeleton, Typography } from '@/shared/ui'

type Props = {
  recipient: Recipient
  onSelect: (id: string) => void
}

export const RecipientCard = ({ recipient, onSelect }: Props) => {
  return (
    <Card
      size="small"
      key={recipient.id}
      asChild
      variant="transparent"
      scalable
      className="grid w-full grid-cols-3 gap-6 rounded-none border-b border-neutral-gray-200 last:border-b-0 md:grid-cols-[20rem_auto_auto]"
      onClick={() => onSelect(recipient.id)}
    >
      <Button
        size="inline"
        variant="ghost"
        onClick={() => onSelect(recipient.id)}
      >
        <div className="flex items-center gap-3">
          <RecipientAvatar recipient={recipient} size="sm" />
          <Typography
            title={recipient.nickname}
            className="max-w-96 overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {recipient.nickname}
          </Typography>
        </div>

        <div className="flex w-24 items-center gap-2">
          <RecipientAccountAvatar paymentRails={recipient.paymentRails} />
          <Typography>{getRecipientPaymentMethod(recipient)}</Typography>
        </div>

        <div className="flex w-24 items-center gap-3">
          <Typography title={recipient.nickname}>
            {getRecipientAccountNumber(recipient)}
          </Typography>
        </div>
      </Button>
    </Card>
  )
}

const RecipientCardSkeleton = ({ length = 5 }: { length?: number }) => {
  return Array.from({ length }).map((_, i) => (
    <Fragment key={i}>
      <Card
        size="small"
        variant="transparent"
        className="grid w-full grid-cols-3 gap-6 rounded-none border-b border-neutral-gray-200 last:border-b-0 md:grid-cols-[24rem_auto_auto]"
      >
        <Skeleton className="h-[22px]" />
        <Skeleton className="h-[22px]" />
        <Skeleton className="h-[22px]" />
      </Card>
    </Fragment>
  ))
}

RecipientCard.Skeleton = RecipientCardSkeleton
