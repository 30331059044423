import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { HistoricalPayment, HistoricalPaymentType } from '../../../types'

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaymentDateCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      {(() => {
        switch (payment.type) {
          case HistoricalPaymentType.PAYMENT_CYCLE:
            return (
              <Typography className="whitespace-nowrap">
                {formatDate(
                  payment.paymentDetails.paymentCycleEnd,
                  'dd MMM. yyyy',
                )}
              </Typography>
            )

          default:
            return <Typography>-</Typography>
        }
      })()}
    </div>
  )
}
