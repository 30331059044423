import { Skeleton, Typography } from '@/shared/ui'

import { useWallets } from '../hooks'

import { AccountIcon } from './AccountIcon'

type Props = {
  id: string
}

export const WalletLabel = ({ id }: Props) => {
  const { wallets, isPending } = useWallets()

  const wallet = wallets?.find((w) => w.id === id)

  return isPending ? (
    <Skeleton className="h-[21px] w-32" />
  ) : (
    <div className="flex items-center gap-1">
      <AccountIcon size="sm" id={id} />
      <Typography>{wallet?.label}</Typography>
    </div>
  )
}
