import { PaymentRails } from '@/domains/Business/constants'
import { CountryCode } from '@/types/country'

import {
  BusinessMXRecipient,
  BusinessUSRecipient,
  CreatedRecipient,
  EntityType,
  IndividualMXRecipient,
  IndividualUSRecipient,
  SharedRecipientFormProps,
} from '../types'

import { MXBusinessForm } from './MXBusinessForm'
import { MXIndividualForm } from './MXIndividualForm'
import { USBusinessForm } from './USBusinessForm'
import { USIndividualForm } from './USIndividualForm'

type Props = {
  entityType: EntityType
  recipient?: CreatedRecipient
  onContinue: (recipient: CreatedRecipient) => void
} & SharedRecipientFormProps

export const RecipientForm = ({
  layout = 'create',
  country,
  entityType,
  isPending,
  recipient,
  recipientId,
  onContinue,
  onDelete,
}: Props) => {
  switch (country) {
    case CountryCode.MX: {
      switch (entityType) {
        case EntityType.INDIVIDUAL: {
          return (
            <MXIndividualForm
              isPending={isPending}
              layout={layout}
              recipient={recipient as IndividualMXRecipient}
              onDelete={onDelete}
              recipientId={recipientId}
              country={CountryCode.MX}
              onContinue={(values) => {
                onContinue({
                  nickname: values.nickname,
                  contactDetails: {
                    internationalPhonePrefix: values.internationalPhonePrefix,
                    localPhoneNumber: values.localPhoneNumber,
                    email: values.email,
                  },
                  recipient: {
                    type: EntityType.INDIVIDUAL,
                    details: {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      secondLastName: values.secondLastName,
                    },
                  },
                  paymentInformation: {
                    rails: PaymentRails.MEX,
                    railsDetails: { clabe: values.clabe },
                  },
                })
              }}
            />
          )
        }
        case EntityType.BUSINESS:
          return (
            <MXBusinessForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as BusinessMXRecipient}
              country={CountryCode.MX}
              recipientId={recipientId}
              onContinue={(values) => {
                onContinue({
                  nickname: values.nickname,
                  recipient: {
                    type: EntityType.BUSINESS,
                    details: {
                      legalName: values.legalName,
                    },
                  },
                  contactDetails: {
                    internationalPhonePrefix: values.internationalPhonePrefix,
                    localPhoneNumber: values.localPhoneNumber,
                    email: values.email,
                  },
                  paymentInformation: {
                    rails: PaymentRails.MEX,
                    railsDetails: { clabe: values.clabe },
                  },
                })
              }}
            />
          )

        default:
          return null
      }
    }

    case CountryCode.US: {
      switch (entityType) {
        case EntityType.INDIVIDUAL:
          return (
            <USIndividualForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as IndividualUSRecipient}
              country={CountryCode.US}
              recipientId={recipientId}
              onContinue={(values) => {
                const USIndividualRecipient: IndividualUSRecipient = {
                  nickname: values.nickname,
                  recipient: {
                    type: EntityType.INDIVIDUAL,
                    details: {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      secondLastName: values.secondLastName,
                    },
                    address: { ...values.address },
                  },
                  contactDetails: {
                    internationalPhonePrefix: values.internationalPhonePrefix,
                    localPhoneNumber: values.localPhoneNumber,
                    email: values.email,
                  },
                  paymentInformation: {
                    rails: PaymentRails.USA,
                    railsDetails: {
                      accountNumber: values.accountNumber,
                      routingNumber: values.routingNumber,
                      paymentMethod: values.paymentMethod,
                    },
                  },
                }

                onContinue(USIndividualRecipient)
              }}
            />
          )
        case EntityType.BUSINESS:
          return (
            <USBusinessForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as BusinessUSRecipient}
              country={CountryCode.US}
              recipientId={recipientId}
              onContinue={(values) => {
                onContinue({
                  nickname: values.nickname,
                  recipient: {
                    type: EntityType.BUSINESS,
                    details: {
                      legalName: values.legalName,
                    },
                    address: { ...values.address },
                  },
                  contactDetails: {
                    internationalPhonePrefix: values.internationalPhonePrefix,
                    localPhoneNumber: values.localPhoneNumber,
                    email: values.email,
                  },
                  paymentInformation: {
                    rails: PaymentRails.USA,
                    railsDetails: {
                      accountNumber: values.accountNumber,
                      routingNumber: values.routingNumber,
                      paymentMethod: values.paymentMethod,
                    },
                  },
                })
              }}
            />
          )

        default:
          return null
      }
    }

    default:
      return null
  }
}
