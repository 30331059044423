import { FormattedMessage, useIntl } from 'react-intl'

import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Widget } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Document } from '@/shared/icons/solid'
import { Avatar, MotionDiv, Skeleton, Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../hooks'
import { ContractorDetails } from '../../types'
import { ContractStateBadge } from '../ContractStateBadge'

type Props = {
  onClick: () => void
  contractor?: ContractorDetails
}

export const ContractsSection = ({ onClick, contractor }: Props) => {
  const intl = useIntl()

  const latestContract = contractor?.contracts[0]

  const frequency = usePaymentFrequency({
    dayType: latestContract?.paymentDetails?.dayType,
    day: latestContract?.paymentDetails?.day,
    frequency: latestContract?.paymentDetails?.frequency,
  })

  return (
    <Widget
      onClick={onClick}
      className="group"
      title={
        <FormattedMessage defaultMessage="Contracts" id="label.contracts" />
      }
    >
      <MotionDiv
        key={latestContract?.agreementType}
        className="md:flex-no-wrap flex w-full flex-wrap items-center justify-between gap-3 md:gap-0"
      >
        {latestContract ? (
          <>
            <div className="flex items-center gap-2">
              <Avatar shape="square" icon={<Document className="size-5" />} />

              <div className="flex flex-col">
                <Typography bold>
                  <FormattedMessage
                    id="contract.type.label"
                    defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
                    values={{ state: latestContract.paymentType }}
                  />
                </Typography>
                <Typography
                  variant="body-small"
                  className="md:whitespace-no-wrap whitespace-break-spaces text-neutral-gray-600"
                >
                  {latestContract.paymentDetails ? (
                    intersperse(
                      [
                        latestContract.contractorRole,
                        formatAmount(latestContract.paymentDetails),
                        frequency,
                      ],
                      ' • ',
                    )
                  ) : (
                    <FormattedMessage
                      id="contractor.paymentDetails.startDate"
                      defaultMessage="Start date on {startDate}"
                      values={{
                        startDate: intl.formatDate(latestContract.startDate, {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        }),
                      }}
                    />
                  )}
                </Typography>
              </div>
            </div>

            <div className="flex items-center">
              <ContractStateBadge state={latestContract.state} />

              <ChevronRight className="size-8 shrink-0 text-neutral-gray-600 group-hover:translate-x-1 group-hover:transition-all" />
            </div>
          </>
        ) : (
          <>
            <Skeleton className="h-[39px] w-36" />
            <Skeleton className="h-[21px] w-36" />
          </>
        )}
      </MotionDiv>
    </Widget>
  )
}
