import { formatAmount } from '@/lib/money'
import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { SingleTransaction } from '../../features/Transactions/types'
import {
  getIsTransactionFailed,
  getTransactionAdditionalDetails,
} from '../../features/Transactions/utils'
import { TransactionIcon } from '../TransactionIcon'

export const TransactionShortListRow = ({
  transaction,
}: {
  transaction: SingleTransaction
}) => {
  const isFailed = getIsTransactionFailed(transaction)
  const additionalDetails = getTransactionAdditionalDetails(transaction)

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <TransactionIcon
          variant={
            additionalDetails?.beneficiaryDolarTag && !isFailed
              ? 'primary'
              : 'neutral'
          }
          transaction={transaction}
        />
        <Typography>{transaction.name}</Typography>
      </div>
      <div className="flex flex-col gap-1">
        <Typography text="right" className={cn('', isFailed && 'line-through')}>
          {formatAmount({
            amount: transaction.baseAmount,
            currency: transaction.baseCurrency,
          })}
        </Typography>
        <Typography
          text="right"
          className={cn('text-neutral-gray-400', isFailed && 'line-through')}
        >
          {formatAmount({
            amount: transaction.localAmount,
            currency: transaction.localCurrency,
          })}
        </Typography>
      </div>
    </div>
  )
}
