import { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { ContractorProfileDropdown } from '@/domains/Contractor/components'
import { cn } from '@/lib/utils'
import { LogoIcon } from '@/shared/components'
import { Skeleton } from '@/shared/ui'

import { getContractorOnboardingState } from '../api'
import {
  ContractorOnboardingState,
  ContractorOnboardingStepState,
} from '../types'

import { ContractorsOnboardingSidebarStep } from './ContractorsOnboardingSidebarStep'

export const ContractorsOnboardingSidebar = () => {
  const params = useParams<{ step?: string }>()
  const [openPopover, setOpenPopover] = useState(false)

  const [onboardingStateQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractorOnboardingState],
        queryFn: getContractorOnboardingState,
        select: (data: AxiosResponse<ContractorOnboardingState>) => data.data,
      },
    ],
  })

  return (
    <nav
      className={cn(
        'fixed z-50 min-h-screen w-12 bg-neutral-gray-1000 px-3 py-6 transition-all duration-300 md:w-60',
      )}
    >
      <div className="hide-scrollbar flex h-screen flex-col overflow-y-auto overflow-x-hidden">
        <ContractorProfileDropdown
          open={openPopover}
          onOpenChange={setOpenPopover}
        />

        <div className="p-6" />

        <div className="flex flex-col gap-3 md:px-2">
          {onboardingStateQuery.isPending ? (
            <>
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
            </>
          ) : (
            onboardingStateQuery.data?.steps.map((step, index) => {
              const previousStepState =
                onboardingStateQuery.data?.steps[index - 1]?.state

              return (
                <ContractorsOnboardingSidebarStep
                  currentNotStartedStep={
                    step.state === ContractorOnboardingStepState.NOT_STARTED &&
                    previousStepState ===
                      ContractorOnboardingStepState.COMPLETED
                  }
                  active={step.name === params.step}
                  key={step.name}
                  step={step}
                />
              )
            })
          )}
        </div>

        <div className="mb-16 mt-auto flex w-full md:pl-2.5">
          <LogoIcon aria-label="DolarApp Business logo" className="size-8" />
        </div>
      </div>
    </nav>
  )
}
