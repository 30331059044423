import { RouteObject } from 'react-router'

import { ContractorRoute } from '@/constants/paths'

import { ExternalContractorDetails } from './ExternalContractorDetails'
import { ExternalContractorTransactions } from './ExternalContractorTransactions'
import { Layout } from './Layout'

export const contractorOverviewRoutes: RouteObject[] = [
  {
    path: ContractorRoute.Dashboard,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ExternalContractorDetails />,
      },
      {
        path: ContractorRoute.Transactions,
        element: <ExternalContractorTransactions />,
      },
    ],
  },
]
