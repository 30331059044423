import { formatCurrency } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { ContractorsTransaction } from '../../../types'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentCurrencyCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>{formatCurrency(payment.amount.currency)}</Typography>
    </div>
  )
}
