import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { CardType } from '@/domains/Business/features/Cards/types'
import { useWallets } from '@/domains/Business/hooks'
import { getImage } from '@/lib/images'
import { formatAmount } from '@/lib/money'
import { Widget } from '@/shared/components'
import { Typography } from '@/shared/ui'

import {
  TeamCardAddressSchema,
  TeamPhysicalCardDetailsSchema,
  TeamVirtualCardDetailsSchema,
} from './AddTeamMemberSteps/schemas'
import { TeamCardAddressReview } from './TeamCardAddressReview'

type Props = {
  cardType?: CardType
  teamCardAddressDetails?: TeamCardAddressSchema
  teamVirtualCardDetails?: TeamVirtualCardDetailsSchema
  teamPhysicalCardDetails?: TeamPhysicalCardDetailsSchema
}

export const TeamCardReviewSection = ({
  cardType,
  teamCardAddressDetails,
  teamVirtualCardDetails,
  teamPhysicalCardDetails,
}: Props) => {
  const walletId = useMemo(() => {
    switch (cardType) {
      case CardType.VIRTUAL:
        return teamVirtualCardDetails?.walletId

      case CardType.PHYSICAL:
        return teamPhysicalCardDetails?.walletId

      default:
        return ''
    }
  }, [cardType, teamPhysicalCardDetails, teamVirtualCardDetails])

  const cardDetails = useMemo(() => {
    switch (cardType) {
      case CardType.VIRTUAL:
        return {
          walletId,
          nickname: teamVirtualCardDetails?.nickname,
          limitAmount: teamVirtualCardDetails?.limitAmount,
          limitType: teamVirtualCardDetails?.limitType,
        }

      case CardType.PHYSICAL:
        return {
          walletId,
          nickname: teamPhysicalCardDetails?.nickname,
          limitAmount: teamPhysicalCardDetails?.limitAmount,
          limitType: teamPhysicalCardDetails?.limitType,
        }

      default:
        return undefined
    }
  }, [
    cardType,
    teamPhysicalCardDetails?.limitAmount,
    teamPhysicalCardDetails?.limitType,
    teamPhysicalCardDetails?.nickname,
    teamVirtualCardDetails?.limitAmount,
    teamVirtualCardDetails?.limitType,
    teamVirtualCardDetails?.nickname,
    walletId,
  ])

  const { wallets } = useWallets({
    enabled: !!walletId,
  })

  const wallet = wallets?.find((wallet) => wallet.id === walletId)

  if (
    !cardDetails?.nickname ||
    !cardDetails.limitAmount ||
    !cardDetails?.limitType
  ) {
    return null
  }

  return (
    <Widget
      title={
        <FormattedMessage
          id="teamMember.create.reviewStep.cardDetails"
          defaultMessage="Card details"
        />
      }
    >
      <div className="flex items-center gap-3">
        <img
          className="h-6 w-8 object-contain"
          src={getImage({
            category: 'cards',
            name: `card-${cardType?.toLowerCase()}`,
          })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col">
          <Typography bold>
            <FormattedMessage
              id="team.reviewScreen.cardType"
              defaultMessage="{cardType, select, VIRTUAL {Virtual card: “{cardLabel}” @ {wallet}} PHYSICAL {Physical card: “{cardLabel}” @ {wallet}} other {}}"
              values={{
                cardType: cardType,
                cardLabel: cardDetails.nickname,
                wallet: wallet?.label,
              }}
            />
          </Typography>
          <div className="flex">
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="team.reviewScreen.card.limitAndAmount"
                defaultMessage="{limitType, select, DAILY {Daily limit • {amount}} WEEKLY {Weekly limit • {amount}} MONTHLY {Monthly limit • {amount}} YEARLY {Yearly limit • {amount}} other {}}"
                values={{
                  limitType: cardDetails.limitType,
                  amount: formatAmount({
                    amount: cardDetails.limitAmount,
                    currency: Currency.USDC,
                  }),
                }}
              />
            </Typography>
          </div>
        </div>
      </div>

      <TeamCardAddressReview teamCardAddressDetails={teamCardAddressDetails} />
    </Widget>
  )
}
