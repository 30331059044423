import { defineMessages } from 'react-intl'

import { Icon } from '@/types/global'

import { DefineMessages } from './messages'

export const BaseRoute = {
  Root: '/',
  SigningIn: '/signing-in',
  LoggingOut: '/logging-out',
}

export const BusinessRoute = {
  // Login
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  ForgotPassword: '/forgot-password',
  AcceptInvite: '/accept-invite',

  // 2FA
  DashboardSetup2fa: '/dashboard/setup-2fa',

  // Onboarding
  Onboarding: '/onboarding',
  OnboardingStart: '/onboarding/start',
  OnboardingCompleted: '/onboarding/completed',
  OnboardingCreateOwner: '/onboarding/create-owner/:ownerId?',
  OnboardingValidateIdentity: '/onboarding/validate-identity/:type',
  OnboardingStep: '/onboarding/:step',

  // Dashboard
  Dashboard: '/dashboard',
  Transactions: '/dashboard/transactions',

  // Recipients
  Recipients: '/dashboard/recipients',
  AddRecipients: '/recipients/add',

  // Team
  Team: '/dashboard/team',
  AddTeamMember: '/add-new-member',

  // Cards
  Cards: '/dashboard/cards',
  CardsTransactions: '/dashboard/cards/:id/transactions',
  CreateCard: '/create-card',

  // Contractors
  Contractors: '/dashboard/contractors',
  ContractorsDetails: '/dashboard/contractors/:id',
  ContractorsCreate: '/create-contractor',
  ContractorsOverview: '/dashboard/contractors/overview',
  ContractorsAll: '/dashboard/contractors/all',
  ContractorsHistoricalPayments: '/dashboard/contractors/historical-payments',
  ContractorsRunPayment: '/contractors/run-payment/:id',
  ContractorsOffsetCyclePayment: '/contractors/new-payment',
  ContractorsHistoricalPaymentTransactions:
    '/dashboard/contractors/historical-payments/:id/transactions',
  ContractorsPayments: '/dashboard/contractors/:id/payments',
  ContractorsManualCSV: '/contractors/upload-csv',

  // Accounts
  Accounts: '/dashboard/accounts',
  SingleAccount: '/dashboard/accounts/:id',
  AccountTransactions: '/dashboard/accounts/:id/transactions',
  CashbackTransactions: '/dashboard/cashback/:id/transactions',
  CreateAccount: '/account/create',

  // Tasks
  Tasks: '/dashboard/tasks',
  ReviewTask: '/review-task/:id',

  // Move Money
  AddBalance: '/add-balance',
  Transfer: '/transfer',
  Send: '/send',

  // Bulk Payments
  BulkPayment: '/bulk-payment',
  BulkPaymentTransactions: '/dashboard/bulk-payment/:id/transactions',

  // Settings
  Settings: '/dashboard/settings',
  CompanySettings: '/dashboard/settings/company',
}

export const ContractorRoute = {
  Root: '/contractor',
  SigningIn: '/contractor/signing-in',
  LoggingOut: '/contractor/logging-out',
  // Sign In
  SignIn: '/contractor/sign-in',
  ForgotPassword: '/contractor/forgot-password',
  AcceptInvite: '/contractor/accept-invite',

  // 2FA
  ContractorSetup2fa: '/contractor/setup-2fa',

  // Onboarding
  Onboarding: '/contractor/onboarding',
  OnboardingStep: '/contractor/onboarding/step/:step',
  OnboardingStart: '/contractor/onboarding/start',

  // Overview
  Dashboard: '/contractor/dashboard',
  Transactions: '/contractor/dashboard/transactions',
}

export type NavigationLink = {
  key: NavKeys
  icon: Icon
  path: string
  enabled: boolean
  count?: number
}

export type ContractorNavigationLink = {
  key: NavKeys
  icon: Icon
  path: string
}

export type NavKeys =
  | 'home'
  | 'accounts'
  | 'recipients'
  | 'cards'
  | 'transactions'
  | 'settings'
  | 'signOut'
  | 'logout'
  | 'support'
  | 'team'
  | 'tasks'
  | 'myProfile'
  | 'transactionsLimit'
  | 'contractors'

export const navMessages: DefineMessages<NavKeys> = defineMessages({
  home: {
    id: 'nav.home',
    defaultMessage: 'Home',
  },
  accounts: {
    id: 'dashboard.accounts.title',
    defaultMessage: 'My accounts',
  },
  recipients: {
    id: 'nav.recipients',
    defaultMessage: 'Recipients',
  },
  cards: {
    id: 'nav.cards',
    defaultMessage: 'Cards',
  },
  transactions: {
    id: 'nav.transactions',
    defaultMessage: 'Transactions',
  },
  settings: {
    id: 'nav.settings',
    defaultMessage: 'Settings',
  },
  signOut: {
    id: 'nav.signOut',
    defaultMessage: 'Sign out',
  },
  logout: {
    id: 'nav.logout',
    defaultMessage: 'Log out',
  },
  support: {
    id: 'nav.chatWithSupport',
    defaultMessage: 'Chat with support',
  },
  team: {
    id: 'nav.team',
    defaultMessage: 'Team',
  },
  myProfile: {
    id: 'nav.myProfile',
    defaultMessage: 'My profile',
  },
  tasks: {
    id: 'nav.tasks',
    defaultMessage: 'Tasks',
  },
  transactionsLimit: {
    id: 'nav.accountLimits',
    defaultMessage: 'Account limits',
  },
  contractors: {
    id: 'nav.contractors',
    defaultMessage: 'Contractors',
  },
})
