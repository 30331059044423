import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Bank, Filter } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  Spinner,
} from '@/shared/ui'

import { useWallets } from '../../hooks'

import { ACCOUNTS_FILTER_NAME } from './data'

export const AccountsFilter = () => {
  const { wallets, isPending } = useWallets({ showAll: true })

  const [searchParams, setSearchParams] = useSearchParams()

  function handleSearchParams(value: string) {
    setSearchParams(
      (params) => {
        const typeParams = params.get(ACCOUNTS_FILTER_NAME)

        if (!typeParams) {
          params.set(ACCOUNTS_FILTER_NAME, value)
          return params
        }

        if (typeParams === value) {
          params.delete(ACCOUNTS_FILTER_NAME)
          return params
        }

        if (!typeParams.includes(value)) {
          const newType = `${typeParams},${value}`
          params.set(ACCOUNTS_FILTER_NAME, newType)
          return params
        }

        const newType = typeParams
          .split(',')
          .filter((type) => type !== value)
          .join(',')

        params.set(ACCOUNTS_FILTER_NAME, newType)

        return params
      },
      {
        preventScrollReset: true,
      },
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <Bank className="size-4" />
              <FormattedMessage id="label.account" defaultMessage="Account" />
            </span>
            {searchParams.get(ACCOUNTS_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent
            alignOffset={0}
            className="max-h-96 w-52 overflow-y-auto"
          >
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(ACCOUNTS_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(ACCOUNTS_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>
            {isPending ? (
              <div className="flex h-10 items-center justify-center">
                <Spinner className="size-4" />
              </div>
            ) : (
              wallets?.map((wallet) => (
                <DropdownMenuCheckboxItem
                  key={wallet.label}
                  onSelect={(event) => event.preventDefault()}
                  checked={searchParams
                    .get(ACCOUNTS_FILTER_NAME)
                    ?.includes(wallet.id)}
                  onCheckedChange={() => handleSearchParams(wallet.id)}
                >
                  {wallet.label}
                </DropdownMenuCheckboxItem>
              ))
            )}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
