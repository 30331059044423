import { Suspense } from 'react'
import { createBrowserRouter } from 'react-router'
import { RouterProvider } from 'react-router/dom'

import { BaseRoute, ContractorRoute } from '@/constants/paths'
import { Loader } from '@/shared/components'

import {
  AuthChecker,
  businessRoutes,
  loginRoutes,
} from './domains/Business/features'
import {
  ContractorAuthChecker,
  contractorRoutes,
  contractorSignInRoutes,
} from './domains/Contractor/features'
import { NotFound } from './NotFound'
import { PageError } from './PageError'

export const AppRouter = () => {
  const routes = createBrowserRouter([
    ...loginRoutes,
    ...contractorSignInRoutes,
    {
      path: BaseRoute.Root,
      element: <AuthChecker />,
      children: [...businessRoutes],
      errorElement: <PageError />,
    },
    {
      path: ContractorRoute.Root,
      element: <ContractorAuthChecker />,
      children: [...contractorRoutes],
      errorElement: <PageError />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={routes} />
    </Suspense>
  )
}
