import { FormattedMessage, useIntl } from 'react-intl'

import { PaymentRails } from '@/domains/Business/constants'
import { useGetBankName } from '@/domains/Business/hooks'
import { getCountryNameByISO3 } from '@/lib/country'
import { Details } from '@/shared/ui'

import { CreatedRecipient } from '../types'
import { getCountryByRails } from '../utils'

type Props = {
  recipient: CreatedRecipient
}

export const RecipientPaymentDetails = ({ recipient }: Props) => {
  const intl = useIntl()

  const getBankReference = () => {
    switch (recipient.paymentInformation.rails) {
      case PaymentRails.MEX:
        return recipient.paymentInformation.railsDetails.clabe
      case PaymentRails.USA:
        return recipient.paymentInformation.railsDetails.routingNumber

      default:
        return ''
    }
  }

  const countryCode = getCountryByRails(recipient.paymentInformation.rails)

  const { name } = useGetBankName({
    country: countryCode,
    bankReference: getBankReference(),
    enabled: true,
  })

  const countryName = getCountryNameByISO3(countryCode, intl.locale)

  switch (recipient.paymentInformation.rails) {
    case PaymentRails.MEX:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
            </Details.Label>

            <Details.Value>
              {recipient.paymentInformation.railsDetails.clabe}
            </Details.Value>
          </Details>
          {name ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value>{name}</Details.Value>
            </Details>
          ) : null}
        </>
      )

    case PaymentRails.USA:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{
                  method:
                    recipient.paymentInformation.railsDetails.paymentMethod,
                }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="ABA / Routing number"
                id="label.routingNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.paymentInformation.railsDetails.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.paymentInformation.railsDetails.accountNumber}
            </Details.Value>
          </Details>
          {name ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value>{name}</Details.Value>
            </Details>
          ) : null}
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Recipient address"
                id="label.recipientAddress"
              />
            </Details.Label>
            <Details.Value className="max-w-72 whitespace-break-spaces text-right">
              {[
                recipient.recipient.address?.street,
                recipient.recipient.address?.city,
                recipient.recipient.address?.state,
                recipient.recipient.address?.postcode,
                countryName,
              ].join(', ')}
            </Details.Value>
          </Details>
        </>
      )

    default:
      return null
  }
}
