import { Sheet, SheetContent, SlideInLeft } from '@/shared/ui'

import { ContractorAgreementType, ContractorDetails } from '../../types'

import { ContractActionsSteps } from './ContractActionsSteps'
import { ContractDocuments } from './ContractDocuments'
import { ContractSidebarHeading } from './ContractSidebarHeading'
import { ContractSidebarInfoWidget } from './ContractSidebarInfoWidget'
import { ContractSidebarPaymentWidget } from './ContractSidebarPaymentWidget'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  contractor?: ContractorDetails
}

export const ContractDetailsSidebar = ({
  isOpen,
  onOpenChange,
  contractor,
}: Props) => {
  const latestContract = contractor?.contracts[0]

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex h-full flex-col">
          <ContractSidebarHeading contract={latestContract} />

          <div className="p-4" />

          {latestContract?.agreementType ===
          ContractorAgreementType.STANDARD ? (
            <>
              <ContractActionsSteps contractor={contractor} />
              <div className="p-4" />
            </>
          ) : null}

          <div className="flex flex-col gap-8">
            <ContractSidebarInfoWidget contract={latestContract} />

            <ContractSidebarPaymentWidget contract={latestContract} />

            <ContractDocuments contract={latestContract} />
          </div>

          <div className="p-4" />
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
