import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { HistoricalPaymentState } from '@/domains/Business/features/Contractors/types'

import { STATUS_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const HistoricalPaymentStateBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(STATUS_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(STATUS_FILTER_NAME)
            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.status',
        defaultMessage: 'Status',
      })}
    >
      {searchParams
        .get(STATUS_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(HistoricalPaymentState).find(
            (o) => o === option,
          )

          return intl.formatMessage(
            {
              id: 'historicalPayment.state.label',
              defaultMessage:
                '{state, select, CREATED {Executing} EXECUTING {Executing} FAILED_TO_EXECUTE {Failed} IN_PROGRESS {In progress} INCOMPLETE {Incomplete} COMPLETED {Completed} other {}}',
            },
            { state: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
