import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { AccountSelect } from '@/domains/Business/components'
import { useWallets } from '@/domains/Business/hooks'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { Wallet } from '../types'

type Props = {
  isOpen: boolean
  isPending: boolean
  onOpenChange: (isOpen: boolean) => void
  onTransferAndDelete: (wallet?: Wallet) => void
  walletIdToDelete?: string
}

export const TransferBalanceDialog = ({
  isOpen,
  isPending,
  onOpenChange,
  onTransferAndDelete,
  walletIdToDelete,
}: Props) => {
  const intl = useIntl()
  const [transferToWalletId, setTransferToWalletId] = useState<string>('')

  const { defaultWalletId, wallets } = useWallets()

  useEffect(() => {
    if (defaultWalletId) {
      setTransferToWalletId(defaultWalletId)
    }
  }, [defaultWalletId])

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Where should we send the funds?"
              id="account.delete.dialog.transfer.title"
            />
          </DialogTitle>

          <DialogDescription>
            <FormattedMessage
              defaultMessage="We need to send the remaining funds before deleting the account. Choose the destination account"
              id="account.delete.dialog.transfer.description"
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <AccountSelect
          showLabel
          variant="outline"
          value={transferToWalletId}
          excludeIds={[walletIdToDelete]}
          onChange={(walletId) => {
            setTransferToWalletId(walletId)
          }}
          label={intl.formatMessage({
            defaultMessage: 'Send funds to',
            id: 'account.delete.dialog.transfer.default.account',
          })}
        />

        <div className="p-4" />

        <Button
          disabled={isPending}
          loading={isPending}
          width="full"
          onClick={() =>
            onTransferAndDelete(
              wallets?.find((wallet) => wallet.id === transferToWalletId),
            )
          }
        >
          <FormattedMessage id="action.continue" defaultMessage="Continue" />
        </Button>
      </DialogContent>
    </Dialog>
  )
}
