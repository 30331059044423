export const queryKeys = {
  // BUSINESS

  // Identity
  getBusinessIdentity: 'getBusinessIdentity',
  getIdentity: 'getIdentity',

  // Account
  getAccount: 'getAccount',
  getAccountDetails: 'getAccountDetails',
  getDefaultAccountDetails: 'getDefaultAccountDetails',
  getAccountStatements: 'getAccountStatements',
  getStatementsCreditCycleInfo: 'getStatementsCreditCycleInfo',

  // Authentication
  getCurrent2FAMethod: 'getCurrent2FAMethod',
  get2FASetupInfo: 'get2FASetupInfo',

  // Bulk Payments
  quoteBulkPayment: 'quoteBulkPayment',

  // Team
  getTeamMembers: 'getTeamMembers',
  getTeamMember: 'getTeamMember',
  getBusinessUser: 'getBusinessUser',
  getTeamMemberPaymentLimit: 'getTeamMemberPaymentLimit',
  getPaymentLimit: 'getPaymentLimit',
  getBusinessPaymentLimits: 'getBusinessPaymentLimits',

  // Tasks
  getAdminTasks: 'getAdminTasks',
  getAdminTask: 'getAdminTask',
  getUserTasks: 'getUserTasks',
  getUserTask: 'getUserTask',
  getTasksCount: 'getTasksCount',
  getWithdrawalTaskDetails: 'getWithdrawalTaskDetails',
  getBulkPaymentTaskDetails: 'getBulkPaymentTaskDetails',

  // Cards
  getAllCards: 'getAllCards',
  getUserCards: 'getUserCards',
  getSingleCard: 'getSingleCard',
  getUnmaskedCardDetails: 'getUnmaskedCardDetails',
  getCardTransactions: 'getCardTransactions',
  getCardDeliveryDetails: 'getCardDeliveryDetails',

  // Transactions
  getTransactions: 'getTransactions',
  getTransaction: 'getTransaction',
  getTransactionCurrencies: 'getTransactionCurrencies',
  getCashbackTransactions: 'getCashbackTransactions',
  getBulkTransaction: 'getBulkTransaction',
  getBulkTransactions: 'getBulkTransactions',
  createBulkPayment: 'createBulkPayment',
  getCSVTransactions: 'getCSVTransactions',
  getCSVFiles: 'getCSVFiles',

  // Recipients
  getRecipients: 'getRecipients',
  getSingleRecipient: 'getSingleRecipient',
  getRecipientBank: 'getRecipientBank',
  getRecentRecipients: 'getRecentRecipients',
  getDolarAppUsers: 'getDolarAppUsers',

  // Contractors
  getContractor: 'getContractor',
  getContractors: 'getContractors',
  calculateProRata: 'calculateProRata',
  getAllContractDocuments: 'getAllContractDocuments',
  getHistoricalPayments: 'getHistoricalPayments',
  getHistoricalPayment: 'getHistoricalPayment',
  getPaymentCycles: 'getPaymentCycles',
  getPaymentCyclesCount: 'getPaymentCyclesCount',
  getOffCycleDetails: 'getOffCycleDetails',
  getContractorPayments: 'getContractorPayments',

  // Onboarding
  getOnboardingState: 'getOnboardingState',
  getOnboardingConfig: 'getOnboardingConfig',
  getOnboardingStepDetails: 'getOnboardingStepDetails',
  getOnboardingCountries: 'getOnboardingCountries',
  startIdentityValidation: 'startIdentityValidation',
  restartIdentityValidation: 'restartIdentityValidation',
  getBeneficialOwner: 'getBeneficialOwner',

  // Miscellaneous
  getFXRates: 'getFXRates',
  getCountryCodes: 'getCountryCodes',
  getCompanyTypes: 'getCompanyTypes',
  getGooglePlacesDetails: 'getGooglePlacesDetails',
  getPriceChartRates: 'getPriceChartRates',
  getBankByReference: 'getBankByReference',

  // CONTRACTOR

  // Details
  getContractorDetails: 'getContractorDetails',
  getCurrentContractor: 'getCurrentContractor',
  getContractorTransactions: 'getContractorTransactions',

  // Documents
  getContractorDocuments: 'getContractorDocuments',

  // Authentication
  getContractor2FAMethod: 'getContractor2FAMethod',
  getContractor2FASetupInfo: 'getContractor2FASetupInfo',

  // Onboarding
  getContractorCompany: 'getContractorCompany',
  getContactorBank: 'getContactorBank',
  getContractorOnboardingState: 'getContractorOnboardingState',
  getContractorPaymentMethods: 'getContractorPaymentMethods',

  // DolarTag
  linkDolarTag: 'linkDolarTag',
  getDolarTagLinkingState: 'getDolarTagLinkingState',
}
