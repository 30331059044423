import { cn } from '@/lib/utils'
import { Bank } from '@/shared/icons/outline'

import { useWallets } from '../hooks'

const COLOR_BY_INDEX = [
  { backgroundColor: '#E9E9E9', color: '#2C2C2E' },
  { backgroundColor: '#FBF0E3', color: '#E4A056' },
  { backgroundColor: '#F0F3F9', color: '#337CE5' },
  { backgroundColor: '#E8F5E9', color: '#1B5E20' },
  { backgroundColor: '#EDE7F6', color: '#4A148C' },
  { backgroundColor: '#FBE9E7', color: '#BF360C' },
  { backgroundColor: '#E3F2FD', color: '#0D47A1' },
  { backgroundColor: '#FFEEFF', color: '#880E4F' },
  { backgroundColor: '#E8F0FE', color: '#1A237E' },
  { backgroundColor: '#F9FBE7', color: '#33691E' },
  { backgroundColor: '#FFCDD2', color: '#B71C1C' },
  { backgroundColor: '#E0F2F1', color: '#004D40' },
  { backgroundColor: '#FFF3E0', color: '#E65100' },
]

type Size = 'sm' | 'md' | 'lg'

export const AccountIcon = ({
  id,
  size = 'md',
}: {
  id?: string
  size?: Size
}) => {
  const { wallets } = useWallets({ showAll: true })

  const walletIndex = wallets?.findIndex((wallet) => wallet.id === id) ?? 0

  const { backgroundColor, color } = COLOR_BY_INDEX[
    walletIndex % COLOR_BY_INDEX.length
  ] ?? { ...COLOR_BY_INDEX[0] }

  return (
    <div
      style={{ backgroundColor }}
      className={cn(
        'flex shrink-0 items-center justify-center rounded-full p-0.5',
        {
          'size-5': size === 'md',
          'size-4': size === 'sm',
          'size-9': size === 'lg',
        },
      )}
    >
      <Bank
        style={{ color }}
        className={cn('shrink-0', {
          'size-3': size === 'sm',
          'size-[14px]': size === 'md',
          'size-5': size === 'lg',
        })}
      />
    </div>
  )
}
