import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router'
import { toast } from 'sonner'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { Ellipsis, Upload, X } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui'

import { dismissPayment } from '../../../api'
import { ContractorPayment } from '../../../types'

import { DismissPaymentDialog } from './DismissPaymentDialog'

type Props = {
  payment: ContractorPayment
}

export const PaymentActionsCell = ({ payment }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const [isDismissDialogOpen, setIsDismissDialogOpen] = useState(false)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: dismissPayment,
  })

  const handleDismiss = async () => {
    try {
      await mutateAsync(payment.id)

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCycles],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getPaymentCyclesCount],
      })

      setIsDismissDialogOpen(false)

      toast.success(
        intl.formatMessage({
          id: 'contractors.dismissPayment.success',
          defaultMessage: 'Payment dismissed successfully',
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <div className="flex items-center justify-end gap-3 md:gap-6">
      <Button asChild>
        <Link
          to={generatePath(BusinessRoute.ContractorsRunPayment, {
            id: payment.id,
          })}
        >
          <FormattedMessage
            id="action.runPayment"
            defaultMessage="Run payment"
          />
        </Link>
      </Button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label="Open dismiss payment dropdown"
            size="inline"
            className="size-8 md:size-10"
            variant="ghost"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem asChild>
            <Button
              asChild
              size="sm"
              variant="dropdown"
              leftIcon={<Upload className="size-4" />}
              width="full"
            >
              <Link to={BusinessRoute.ContractorsManualCSV}>
                <FormattedMessage
                  id="action.uploadManualCSV"
                  defaultMessage="Upload manual CSV"
                />
              </Link>
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Button
              size="sm"
              variant="dropdown"
              leftIcon={<X className="size-5" />}
              onClick={() => setIsDismissDialogOpen(true)}
              width="full"
            >
              <FormattedMessage
                id="action.dismissPayment"
                defaultMessage="Dismiss payment"
              />
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DismissPaymentDialog
        isOpen={isDismissDialogOpen}
        onOpenChange={setIsDismissDialogOpen}
        onDismiss={handleDismiss}
        totalContractors={payment.numberOfPayments}
        isPending={isPending || isSuccess}
      />
    </div>
  )
}
