import { Big } from 'big.js'
import { z } from 'zod'

export const offCyclePaymentFormSchema = z.object({
  contractors: z.array(
    z.object({
      contractorId: z.string(),
      contractId: z.string(),

      amount: z.object({
        currency: z.string(),
        amount: z
          .string()
          .optional()
          .refine((v) => !v || Big(v).gt(0), {
            message: 'validation.balance.minAmount',
          }),
      }),
    }),
  ),
})

export type OffCyclePaymentFormSchema = z.infer<
  typeof offCyclePaymentFormSchema
>
