import { Big } from 'big.js'
import { FormattedMessage } from 'react-intl'

import { Card, Skeleton, Typography } from '@/shared/ui'

import { ContractorOffCycleDetails } from '../../types'

import { ManualCSVContractorRow } from './ManualCSVContractorRow'

type Props = {
  contractors: ContractorOffCycleDetails['fixedRatePayments']
}

export const ManualCSVContractorsList = ({ contractors }: Props) => {
  const totalAmount = contractors
    .reduce((acc, curr) => {
      return acc.add(curr.paymentAmount?.amount ?? 0)
    }, Big(0))
    .toString()

  if (contractors.length === 0) {
    return (
      <Card size="medium">
        <FormattedMessage
          id="contractors.uploadManualCSV.noContractors"
          defaultMessage="No contractors were imported"
        />
      </Card>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-6">
        <div className="flex items-center gap-4">
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="contractors.uploadManualCSV.total"
              defaultMessage="Contractors • {count} total"
              values={{ count: contractors.length }}
            />
          </Typography>
        </div>
        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="contractors.uploadManualCSV.totalAmount"
            defaultMessage="Total • {amount}"
            values={{
              amount: totalAmount,
            }}
          />
        </Typography>
      </div>
      <div className="p-1" />

      <Card>
        {contractors.map((contractor) => (
          <ManualCSVContractorRow
            key={contractor.contractId}
            contractor={contractor}
          />
        ))}
      </Card>
    </div>
  )
}

const ManualCSVContractorsListSkeleton = () => {
  return (
    <>
      <Card>
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col gap-1 py-2">
            <Skeleton className="h-[18px] w-1/3" />
            <Skeleton className="h-[18px] w-1/3" />
          </div>
          <Skeleton className="h-[18px] w-1/3" />
        </div>
        <div className="border-b border-neutral-gray-300" />
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col gap-1 py-2">
            <Skeleton className="h-[18px] w-1/3" />
            <Skeleton className="h-[18px] w-1/3" />
          </div>
          <Skeleton className="h-[18px] w-1/3" />
        </div>
      </Card>
    </>
  )
}

ManualCSVContractorsList.Skeleton = ManualCSVContractorsListSkeleton
