import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'
import { getName } from '../utils'

import { CSVTransactionDetailsRow } from './CSVTransactionDetailsRow'

type Props = {
  transactions: CSVTransaction[]
}

export const BulkRecipientRow = ({ transactions }: Props) => {
  return (
    <div className="flex flex-col gap-3 pt-4">
      {transactions.map((transaction, index) => (
        <div
          className="flex items-center justify-between"
          key={transaction.paymentCurrency + index}
        >
          <div className="flex flex-col">
            <Typography>{getName(transaction)}</Typography>

            <CSVTransactionDetailsRow
              textVariant="body-small"
              transaction={transaction}
            />
          </div>
          <div className="flex flex-col">
            <Typography text="right">
              {formatAmount({
                amount: transaction.paymentAmount,
                currency: transaction.paymentCurrency,
              })}
            </Typography>
            <Typography
              variant="body-small"
              text="right"
              className="text-neutral-gray-600"
            >
              {formatAmount({
                amount: transaction.baseAmount,
                currency: transaction.baseCurrency,
              })}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}
