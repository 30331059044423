import { Fragment, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { useBusinessRole } from '@/domains/Business/hooks'
import { Role } from '@/domains/Business/types/roles'
import { formatAmount, formatCurrency, formatRate } from '@/lib/money'
import { getFullName } from '@/lib/typography'
import { FreeLabel, Widget } from '@/shared/components'
import { InfoCircle } from '@/shared/icons/outline'
import {
  Button,
  Details,
  MotionDiv,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@/shared/ui'

import { BulkPaymentTaskDetails, ExecuteBulkPaymentTask } from '../types'

type Props = {
  task: ExecuteBulkPaymentTask
  taskDetails: BulkPaymentTaskDetails
}

export const BulkPaymentTaskReview = ({ task, taskDetails }: Props) => {
  const { role } = useBusinessRole()

  const description = useMemo(() => {
    if (task) {
      switch (role) {
        case Role.ADMIN:
          return (
            <FormattedMessage
              id="tasks.review.executeBulkPayment.admin.description"
              defaultMessage="{fullName} has made a request to pay {total} recipients in bulk. Review and decide to approve or decline"
              values={{
                fullName: getFullName({
                  firstName: task.createdByFirstName,
                  lastName: task.createdByLastName,
                  secondLastName: task.createdBySecondLastName,
                }),
                total: task.taskDetails.totalNumberOfPayments,
              }}
            />
          )

        case Role.PAYMENT_OPS:
          return (
            <FormattedMessage
              id="tasks.review.paymentOps.description"
              defaultMessage="Approval of this request is pending. Contact your account Admin so they can review it!"
            />
          )

        default:
          return ''
      }
    }

    return ''
  }, [role, task])

  const exchangeRate = useMemo(() => {
    const MXNRate = taskDetails.paymentQuoteDetails.find(
      (details) => details.subtotalAmount.currency === Currency.MXN,
    )

    if (!MXNRate) {
      return null
    }

    return `1 ${formatCurrency(Currency.USDC)} = ${formatRate(MXNRate.fxRate)} ${formatCurrency(MXNRate.subtotalAmount.currency)}`
  }, [taskDetails.paymentQuoteDetails])

  return (
    <MotionDiv>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="tasks.review.executeBulkPayment.title"
          defaultMessage="Confirm {amount} bulk payment"
          values={{
            amount: formatAmount({
              amount: task.taskDetails.totalAmount,
              currency: task.taskDetails.currency,
            }),
          }}
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">{description}</Typography>

      <div className="p-6" />

      <div className="flex flex-col gap-4">
        <Widget
          title={
            <FormattedMessage
              id="tasks.review.totalPayment"
              defaultMessage="Total payment"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.totalRecipients"
                defaultMessage="Total recipients"
              />
            </Details.Label>
            <Details.Value>
              {task.taskDetails.totalNumberOfPayments}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.totalPaymentAmount"
                defaultMessage="Total payment amount"
              />
            </Details.Label>
            <Details.Value>
              {formatAmount({
                amount: task.taskDetails.totalAmount,
                currency: task.taskDetails.currency,
              })}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Your fee"
                id="bulkPayments.confirmScreen.fee"
              />
            </Details.Label>
            <div className="flex items-center gap-1">
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button size="inline" className="size-4" variant="ghost">
                    <InfoCircle className="size-4" />
                  </Button>
                </TooltipTrigger>
                <div className="flex gap-1 text-right">
                  <Typography className="line-through">
                    {formatAmount(taskDetails.feeBeforeDiscount)}
                  </Typography>

                  <FreeLabel />
                </div>

                <TooltipContent className="max-w-[300px]">
                  <Typography>
                    <FormattedMessage
                      id="bulkPayments.fee.tooltip"
                      defaultMessage="All fees are waived when sending to other DolarApp accounts"
                    />
                  </Typography>
                </TooltipContent>
              </Tooltip>
            </div>
          </Details>
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="tasks.review.totalPayment"
              defaultMessage="Total payment"
            />
          }
        >
          {taskDetails.paymentQuoteDetails.map((details) => (
            <Fragment
              key={
                details.subtotalAmount.amount + details.subtotalAmount.currency
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="{currency} recipients"
                    id="bulkPayments.confirmScreen.recipients"
                    values={{
                      currency: details.subtotalAmount.currency,
                    }}
                  />
                </Details.Label>
                <Details.Value>
                  {details.subtotalNumberOfPayments}
                </Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="{currency} payment total"
                    id="bulkPayments.confirmScreen.paymentTotal"
                    values={{
                      currency: details.subtotalAmount.currency,
                    }}
                  />
                </Details.Label>
                <Details.Value>
                  {formatAmount(details.subtotalAmount)}
                </Details.Value>
              </Details>
            </Fragment>
          ))}
          {exchangeRate ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Rate" id="label.rate" />
              </Details.Label>
              <Details.Value>{exchangeRate}</Details.Value>
            </Details>
          ) : null}
          {taskDetails.paymentLabel ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Payment label"
                  id="label.paymentLabel"
                />
              </Details.Label>
              <Details.Value>{taskDetails.paymentLabel}</Details.Value>
            </Details>
          ) : null}
        </Widget>
      </div>
    </MotionDiv>
  )
}
