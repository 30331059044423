import { SEARCH_FILTER_NAME } from '@/domains/Business/components'
import { api } from '@/lib/api'
import { handleDateQueryParams } from '@/lib/date'

import { Contractor } from '../types'

type SearchParams = {
  [SEARCH_FILTER_NAME]?: string
}

export function getContractors(params: SearchParams = {}) {
  const paramsWithDate = handleDateQueryParams(params)

  const searchQuery =
    paramsWithDate.size > 0 ? '?' + paramsWithDate.toString() : ''

  return api.get<Contractor[]>(`/v1/me/contractors/search${searchQuery}`)
}
