import { forwardRef, Ref, type SVGProps } from 'react'

const SvgUpload = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 14 14"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M1.917 9.5a.75.75 0 1 0-1.5 0zm-.75.833h-.75zM13.583 9.5a.75.75 0 0 0-1.5 0zM7.586 1.635A.75.75 0 1 0 6.414.698zM3.08 4.865a.75.75 0 1 0 1.171.937zM7.586.698a.75.75 0 1 0-1.172.937zm2.162 5.104a.75.75 0 1 0 1.171-.937zM7.75 1.167a.75.75 0 1 0-1.5 0zm-1.5 9.166a.75.75 0 1 0 1.5 0zM.417 9.5v.833h1.5V9.5zm0 .833a3.25 3.25 0 0 0 3.25 3.25v-1.5a1.75 1.75 0 0 1-1.75-1.75zm3.25 3.25h6.666v-1.5H3.667zm6.666 0a3.25 3.25 0 0 0 3.25-3.25h-1.5a1.75 1.75 0 0 1-1.75 1.75zm3.25-3.25V9.5h-1.5v.833zM6.414.698 3.081 4.865l1.171.937 3.334-4.167zm0 .937 3.334 4.167 1.171-.937L7.586.698zm-.164-.468v9.166h1.5V1.167z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgUpload)
export default ForwardRef
