import { defineMessage, FormattedMessage, useIntl } from 'react-intl'

import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { OffCyclePaymentCategory } from '../../types'

const CATEGORY_LABELS = {
  [OffCyclePaymentCategory.BONUS]: defineMessage({
    defaultMessage: 'Bonus',
    id: 'contractor.offCyclePayment.review.paymentCategory.bonus',
  }),
  [OffCyclePaymentCategory.COMMISSION]: defineMessage({
    defaultMessage: 'Commission',
    id: 'contractor.offCyclePayment.review.paymentCategory.commission',
  }),
  [OffCyclePaymentCategory.REIMBURSEMENT]: defineMessage({
    defaultMessage: 'Reimbursement',
    id: 'contractor.offCyclePayment.review.paymentCategory.reimbursement',
  }),
  [OffCyclePaymentCategory.EXTRA_HOURS]: defineMessage({
    defaultMessage: 'Extra hours',
    id: 'contractor.offCyclePayment.review.paymentCategory.extraHours',
  }),
  [OffCyclePaymentCategory.ADJUSTMENT]: defineMessage({
    defaultMessage: 'Adjustment',
    id: 'contractor.offCyclePayment.review.paymentCategory.adjustment',
  }),
  [OffCyclePaymentCategory.SETTLEMENT]: defineMessage({
    defaultMessage: 'Settlement',
    id: 'contractor.offCyclePayment.review.paymentCategory.settlement',
  }),
}

const CATEGORIES = Object.values(OffCyclePaymentCategory)

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
}

export const OffCycleCategorySelect = ({
  disabled,
  onChange,
  value,
}: Props) => {
  const intl = useIntl()

  return (
    <Select disabled={disabled} onValueChange={onChange} value={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.paymentCategory"
                defaultMessage="Payment category"
              />
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  {...CATEGORY_LABELS[value as OffCyclePaymentCategory]}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={intl.formatMessage({
              defaultMessage: 'Payment category',
              id: 'label.paymentCategory',
            })}
          />
        )}
      </SelectTrigger>

      <SelectContent>
        {CATEGORIES.map((category) => (
          <SelectItem
            disabled={category === value}
            key={category}
            value={category}
          >
            <Typography>
              <FormattedMessage
                {...CATEGORY_LABELS[category as OffCyclePaymentCategory]}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
