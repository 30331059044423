import { Big } from 'big.js'
import { defineMessage } from 'react-intl'

import { getFullName } from '@/lib/typography'

import {
  ContractorPaymentCycleDetails,
  ContractorPaymentMethodType,
  DisplayableRunPaymentContractor,
  RunPaymentContractor,
} from '../types'

export function getBankAccountLabel(
  paymentMethodType?: ContractorPaymentMethodType,
) {
  switch (paymentMethodType) {
    case ContractorPaymentMethodType.DOLAR_TAG:
      return defineMessage({
        id: 'label.dolarTag',
        defaultMessage: 'DolarTag',
      })

    case ContractorPaymentMethodType.US_BANK_ACCOUNT:
      return defineMessage({
        id: 'label.usBankAccount',
        defaultMessage: 'US Bank Account',
      })

    case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
      return defineMessage({
        id: 'label.mxBankAccount',
        defaultMessage: 'MX Bank Account',
      })

    default:
      return defineMessage({
        id: 'label.bankAccount',
        defaultMessage: 'Bank account',
      })
  }
}

export function getDisplayableContractors(
  payments:
    | ContractorPaymentCycleDetails['fixedRatePayments']
    | ContractorPaymentCycleDetails['payAsYouGoPayments']
    | undefined,
  contractorIds: RunPaymentContractor[],
): DisplayableRunPaymentContractor[] {
  const contractors = contractorIds.map((contractor) => {
    const foundContractor = payments?.find(
      (c) => c.contractorId === contractor.contractorId,
    )

    return {
      fullName: getFullName(foundContractor?.personalDetails),
      paymentMethodType: foundContractor?.paymentMethodType,
      amount: contractor.amount,
    }
  })

  return contractors
}

export function getTotalPaymentAmount(contractors?: RunPaymentContractor[]) {
  if (!contractors) return Big(0)

  return contractors.reduce((acc, contractor) => {
    return acc.add(contractor.amount.amount ?? 0)
  }, Big(0))
}
