import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'

import { getAccount } from '../features/Accounts/api'
import { WalletState } from '../features/Accounts/types'

type Props = {
  enabled?: boolean
  showAll?: boolean
  staleTime?: number
}

export function useWallets({
  enabled = true,
  showAll = false,
  staleTime = 1000 * 60 * 5,
}: Props = {}) {
  const query = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data?.data,
    enabled,
    staleTime,
  })

  return {
    defaultWalletId: query.data?.defaultWalletId,
    totalBalance: query.data?.totalBalance,
    totalBalanceCurrency: query.data?.totalBalanceCurrency,
    accountId: query.data?.id,
    wallets: query.data?.wallets.filter(
      (wallet) => showAll || wallet.state !== WalletState.CLOSED,
    ),
    isPending: query.isPending,
  }
}
