import { Typography } from '@/shared/ui'

import { failedTransactionStatuses } from '../../features/Transactions/constants'
import { SingleTransaction } from '../../features/Transactions/types'
import { getTransactionAdditionalDetails } from '../../features/Transactions/utils'
import { TransactionIcon } from '../TransactionIcon'

type Props = {
  transaction: SingleTransaction
}

export const SingleTransactionCell = ({ transaction }: Props) => {
  const additionalDetails = getTransactionAdditionalDetails(transaction)

  const isFailed = transaction?.transactionStatus
    ? failedTransactionStatuses.includes(transaction?.transactionStatus)
    : false

  return (
    <div className="flex items-center gap-3 pr-2 sm:pr-0">
      <TransactionIcon
        transaction={transaction}
        variant={
          additionalDetails?.beneficiaryDolarTag && !isFailed
            ? 'primary'
            : 'neutral'
        }
      />

      <div className="flex items-center gap-1">
        <Typography className="line-clamp-1">{transaction.name}</Typography>
      </div>
    </div>
  )
}
