import { FormattedMessage } from 'react-intl'

import { nonEmptyString } from '@/lib/typography'
import { Avatar, Card, Details, Typography } from '@/shared/ui'

import { DOLAR_TAG } from '../../constants'
import { EntityType } from '../../features/Recipients/types'
import {
  getEntityType,
  getIconByEntityType,
} from '../../features/Recipients/utils'
import {
  SingleTransaction,
  TransactionType,
} from '../../features/Transactions/types'
import {
  getTransactionAdditionalDetails,
  getTransactionPaymentMethod,
} from '../../features/Transactions/utils'

interface AdditionalDetailsType {
  beneficiaryClabe?: string | null
  payerClabe?: string | null
  paymentMessage?: string | null
  beneficiaryAccountNumber?: string | null
  beneficiaryRoutingNumber?: string | null
  beneficiaryPaymentMethod?: string | null
  payerRoutingNumber?: string | null
  paymentMethod?: string | null
  payerBankName?: string | null
  beneficiaryDolarTag?: string
  beneficiaryEntityType?: EntityType
}

const DolarTagDetails = ({
  additionalDetails,
}: {
  additionalDetails: AdditionalDetailsType | null
}) => {
  const Icon = getIconByEntityType(additionalDetails?.beneficiaryEntityType)

  if (!additionalDetails?.beneficiaryDolarTag) return null

  return (
    <>
      <Details>
        <Details.Label>
          <FormattedMessage
            id="label.paymentMethod"
            defaultMessage="Payment method"
          />
        </Details.Label>
        <Details.Value>{DOLAR_TAG}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage id="label.dolarTag" defaultMessage="DolarTag" />
        </Details.Label>
        <Details.Value>{additionalDetails?.beneficiaryDolarTag}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Type" id="label.type" />
        </Details.Label>
        <div className="flex items-center gap-2">
          <Avatar size="sm" icon={<Icon className="size-3" />} />
          <Typography>
            <FormattedMessage
              defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
              id="recipients.entity"
              values={{
                entity: getEntityType(additionalDetails?.beneficiaryEntityType),
              }}
            />
          </Typography>
        </div>
      </Details>
    </>
  )
}

export const PaymentDetails = ({
  transaction,
}: {
  transaction?: SingleTransaction
}) => {
  const additionalDetails = getTransactionAdditionalDetails(transaction)

  if (!transaction) return null

  const userClabe =
    additionalDetails?.beneficiaryClabe ?? additionalDetails?.payerClabe
  const routingNumber =
    additionalDetails?.beneficiaryRoutingNumber ??
    additionalDetails?.payerRoutingNumber
  const paymentMethod = getTransactionPaymentMethod(transaction)
  const paymentMessage =
    nonEmptyString(transaction?.description) ||
    nonEmptyString(additionalDetails?.paymentMessage)

  const shouldShowPaymentMessage =
    [TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(
      transaction.transactionType,
    ) && paymentMessage

  const showAdditionalDetails =
    paymentMethod ||
    routingNumber ||
    userClabe ||
    additionalDetails?.paymentMessage ||
    additionalDetails?.beneficiaryAccountNumber ||
    additionalDetails?.payerBankName ||
    shouldShowPaymentMessage

  if (!showAdditionalDetails) return null

  return (
    <Card className="flex flex-col gap-4" size="medium">
      {(() => {
        if (!paymentMethod) return null

        if (paymentMethod === DOLAR_TAG) {
          return <DolarTagDetails additionalDetails={additionalDetails} />
        }

        return (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>{paymentMethod}</Details.Value>
          </Details>
        )
      })()}

      {routingNumber && (
        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.routingNumber"
              defaultMessage="ABA / Routing number"
            />
          </Details.Label>
          <Details.Value>{routingNumber}</Details.Value>
        </Details>
      )}

      {additionalDetails?.beneficiaryAccountNumber && (
        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.accountNumber"
              defaultMessage="Account number"
            />
          </Details.Label>
          <Details.Value>
            {additionalDetails.beneficiaryAccountNumber}
          </Details.Value>
        </Details>
      )}

      {additionalDetails?.payerBankName && (
        <Details>
          <Details.Label>
            <FormattedMessage id="label.bank" defaultMessage="Bank" />
          </Details.Label>
          <Details.Value>{additionalDetails.payerBankName}</Details.Value>
        </Details>
      )}

      {userClabe && (
        <Details>
          <Details.Label>
            <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
          </Details.Label>
          <Details.Value>{userClabe}</Details.Value>
        </Details>
      )}

      {shouldShowPaymentMessage && (
        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.paymentReferenceConcepto"
              defaultMessage="Payment reference / Concepto"
            />
          </Details.Label>
          <Details.Value>{paymentMessage}</Details.Value>
        </Details>
      )}
    </Card>
  )
}
