import { FormattedMessage, useIntl } from 'react-intl'

import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { PaymentLimitType } from '../types'

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
  placeholder?: string | React.ReactNode
}

export const LimitTypeSelect = ({
  disabled,
  onChange,
  value,
  placeholder,
}: Props) => {
  const intl = useIntl()

  return (
    <Select disabled={disabled} onValueChange={onChange} value={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              {placeholder ?? (
                <FormattedMessage
                  id="label.limitType"
                  defaultMessage="Limit type"
                />
              )}
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="card.limit.duration"
                  defaultMessage="{limitType, select, DAILY {Daily} WEEKLY {Weekly} MONTHLY {Monthly} YEARLY {Yearly} other {}}"
                  values={{
                    limitType: value,
                  }}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={
              placeholder ??
              intl.formatMessage({
                id: 'label.limitType',
                defaultMessage: 'Limit type',
              })
            }
          >
            {placeholder ??
              intl.formatMessage({
                id: 'label.limitType',
                defaultMessage: 'Limit type',
              })}
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(PaymentLimitType).map((type) => (
          <SelectItem
            disabled={type === value}
            hideChecked
            className="flex h-[54px] flex-col items-start"
            key={type}
            value={type}
          >
            <Typography>
              <FormattedMessage
                id="card.limit.duration"
                defaultMessage="{limitType, select, DAILY {Daily} WEEKLY {Weekly} MONTHLY {Monthly} YEARLY {Yearly} other {}}"
                values={{
                  limitType: type,
                }}
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="card.limit.duration.timeline"
                defaultMessage="{limitType, select, DAILY {Resets at midnight EST} WEEKLY {Resets at midnight EST each Sunday} MONTHLY {Resets at the start of every calendar month} YEARLY {Resets at the start of every calendar year} other {}}"
                values={{
                  limitType: type,
                }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
