import { useState } from 'react'
import { Big } from 'big.js'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import {
  Button,
  Card,
  MotionDiv,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  ContractorPaymentCycleDetails,
  RunPaymentContractor,
} from '../../../types'
import { FixedContractorRow } from '../FixedContractorRow'

type Props = {
  contractors: ContractorPaymentCycleDetails['fixedRatePayments']
  onContinue: (contractors: RunPaymentContractor[]) => void
  fixedIdsAndAmounts: RunPaymentContractor[]
}

export const FixedContractorsStep = ({
  contractors,
  onContinue,
  fixedIdsAndAmounts,
}: Props) => {
  const [selectedContractors, setSelectedContractors] = useState(() => {
    if (fixedIdsAndAmounts.length === 0) return contractors

    return contractors.filter((contractor) =>
      fixedIdsAndAmounts.some(
        (fixed) => fixed.contractId === contractor.contractId,
      ),
    )
  })

  const totalAmount = (
    selectedContractors?.reduce(
      (acc, contractor) =>
        Big(acc).add(Big(contractor.paymentAmount?.amount ?? 0)),
      Big(0),
    ) ?? Big(0)
  ).toNumber()

  const handleContinue = () => {
    if (!selectedContractors) return

    const mappedContractors = selectedContractors.map((contractor) => ({
      contractorId: contractor.contractorId,
      contractId: contractor.contractId,
      amount: {
        amount: contractor.paymentAmount?.amount ?? '0',
        currency: contractor.paymentAmount?.currency ?? Currency.USD,
      },
    }))

    onContinue(mappedContractors)
  }

  const handleRemoveContractor = (contractId: string) => {
    setSelectedContractors((prevContractors) =>
      prevContractors.filter(
        (contractor) => contractor.contractId !== contractId,
      ),
    )
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Pay your fixed-rate contractors"
          id="contractors.runPayment.fixedContractors.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography className="text-center">
        <FormattedMessage
          id="contractors.runPayment.fixedContractors.subtitle"
          defaultMessage="Confirm the fixed-rate contractors you want to pay"
        />
      </Typography>

      <div className="p-6" />

      <div className="flex flex-col">
        <div className="mb-2 flex justify-between px-6">
          <div className="flex items-center gap-4">
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.runPayment.totalContractors"
                defaultMessage="Contractors • {count} total"
                values={{ count: selectedContractors.length }}
              />
            </Typography>
          </div>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="contractors.runPayment.totalAmount"
              defaultMessage="Total • {amount} "
              values={{
                amount: formatAmount({
                  amount: totalAmount,
                  currency: Currency.USDC,
                }),
              }}
            />
          </Typography>
        </div>

        <Card>
          {selectedContractors?.map((contractor) => {
            return (
              <MotionDiv
                className="border-b border-neutral-gray-200 last:border-b-0"
                layout
                key={contractor.contractId}
              >
                <FixedContractorRow
                  isLastContractor={selectedContractors.length === 1}
                  onDismiss={handleRemoveContractor}
                  contractor={contractor}
                />
              </MotionDiv>
            )
          })}
        </Card>
      </div>

      <StickyContainer>
        <Button onClick={handleContinue} width="full">
          <FormattedMessage
            id="action.saveAndContinue"
            defaultMessage="Save & Continue"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
