import { cn } from '@/lib/utils'
import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { useWallets } from '../hooks'

import { WalletItem } from './WalletItem'

type Props = {
  value: string
  onChange: (v: string) => void
  disabled?: boolean
  label: string
  showLabel?: boolean
  hasError?: boolean
  variant?: 'default' | 'outline'
  excludeIds?: (string | undefined)[]
}

export const AccountSelect = ({
  disabled,
  label,
  onChange,
  value,
  hasError = false,
  showLabel = false,
  variant = 'default',
  excludeIds,
}: Props) => {
  const { wallets, isPending } = useWallets()

  const walletOptions = wallets?.filter(
    (wallet) => !excludeIds?.includes(wallet.id),
  )

  const wallet = walletOptions?.find((a) => a.id === value)

  return (
    <Select
      disabled={wallets?.length === 0 || disabled || isPending}
      value={value}
      onValueChange={onChange}
    >
      <SelectTrigger
        className={cn('', {
          'border border-primary-error': hasError,
          'border border-neutral-gray-100': variant === 'outline',
        })}
      >
        {wallet ? (
          <MotionDiv className="flex flex-col items-start">
            {showLabel ? (
              <Typography
                className={cn('', { 'text-primary-error': hasError })}
                variant="body-tiny"
              >
                {label}
              </Typography>
            ) : null}
            <MotionDiv key={wallet.id}>
              <WalletItem wallet={wallet} />
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue placeholder={label} />
        )}
      </SelectTrigger>

      <SelectContent>
        {walletOptions?.map((wallet) => (
          <SelectItem
            disabled={wallet.id === value}
            key={wallet.id}
            value={wallet.id}
            className="flex items-center gap-3 px-2 py-3"
          >
            <WalletItem wallet={wallet} />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
