import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Spinner } from '@/shared/ui'

import { useWallets } from '../../hooks'

import { ACCOUNTS_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const AccountsBadge = () => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const { wallets, isPending } = useWallets({ showAll: true })

  const selectedAccounts = useMemo(
    () =>
      searchParams
        .get(ACCOUNTS_FILTER_NAME)
        ?.split(',')
        .map((value) => {
          const account = wallets?.find((wallet) => wallet.id === value)

          return account?.label
        })
        .join(', '),
    [wallets, searchParams],
  )

  if (!searchParams.get(ACCOUNTS_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(ACCOUNTS_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.accounts',
        defaultMessage: 'Accounts',
      })}
    >
      {isPending ? <Spinner className="size-4" /> : selectedAccounts}
    </FilterBadge>
  )
}
