import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link, useSearchParams } from 'react-router'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useSearchInput } from '@/hooks/useSearchInput'
import { getFullName } from '@/lib/typography'
import { getAnimationKey } from '@/lib/utils'
import { EmptyContractorPayments } from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  DataTable,
  MotionDiv,
  MotionSpan,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { getContractorDetails, getContractorTransactions } from '../../api'
import {
  ActiveFilters,
  ContractorPaymentDestinationBadge,
  ContractorPaymentDestinationFilter,
  ContractorPaymentStateBadge,
  ContractorPaymentStateFilter,
  DateBadge,
  DateFilter,
  FiltersWidget,
  useContractorPaymentColumns,
} from '../../components'
import { ContractorDetails, ContractorsTransaction } from '../../types'

export const ExternalContractorTransactions = () => {
  const columns = useContractorPaymentColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries([...searchParams])

  const [allTransactions, contractorDetails] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractorTransactions, params],
        queryFn: () => getContractorTransactions(params),
        select: (data: AxiosResponse<ContractorsTransaction[]>) => data?.data,
      },

      {
        queryKey: [queryKeys.getContractorDetails],
        queryFn: () => getContractorDetails(),
        select: (data: AxiosResponse<ContractorDetails>) => data?.data,
      },
    ],
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <MotionSpan key={getAnimationKey(contractorDetails.isPending)}>
              {contractorDetails.isPending ? (
                <Skeleton className="h-6 w-36" />
              ) : (
                <BreadcrumbLink asChild>
                  <Button variant="ghost" size="inline" asChild>
                    <Link to={ContractorRoute.Dashboard}>
                      {getFullName(contractorDetails.data?.personalDetails)}
                    </Link>
                  </Button>
                </BreadcrumbLink>
              )}
            </MotionSpan>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <Typography>
                <FormattedMessage
                  id="label.payments"
                  defaultMessage="Payments"
                />
              </Typography>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <ContractorPaymentDestinationFilter />
          <DateFilter />
          <ContractorPaymentStateFilter />
        </FiltersWidget>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <ContractorPaymentDestinationBadge />
        <ContractorPaymentStateBadge />
        <DateBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(allTransactions.isPending, params)}>
        {allTransactions.data?.length === 0 &&
        Object.keys(params).length === 0 ? (
          <EmptyContractorPayments />
        ) : (
          <DataTable
            isLoading={allTransactions.isPending}
            columns={columns}
            data={allTransactions.data ?? []}
            loaderOptions={{ rows: 5 }}
          />
        )}
      </MotionDiv>
    </div>
  )
}
