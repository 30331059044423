import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { cn } from '@/lib/utils'
import { UploadedFile } from '@/shared/components'
import { Reload } from '@/shared/icons/outline'
import { Download } from '@/shared/icons/solid'
import { Button, Card, Label, MotionDiv, Typography } from '@/shared/ui'

function typeValidator(file: File) {
  if (['text/csv'].includes(file.type)) {
    return null
  }

  return {
    code: 'invalid-type',
    message: 'validation.file.wrongFormat',
  }
}

type Props = {
  files: File[]
  name: string
  onDrop: (files: File[]) => void
}

export const ManualCSVFileField = ({ files, onDrop, name }: Props) => {
  const intl = useIntl()

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      multiple: false,
      accept: { 'text/csv': [] },
      validator: typeValidator,
      onDrop: (acceptedFiles) => {
        if (!acceptedFiles.length) {
          return
        }

        onDrop(acceptedFiles)
      },
    })

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ file }) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: `Can't add file {name}. Only CSV files are allowed`,
              id: 'validation.file.onlyCSV',
            },
            { name: file.name },
          ),
        )
      })
    }
  }, [fileRejections, intl])

  const handleSelectClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    open()
  }

  return (
    <Card size="upload">
      <div className="flex flex-col gap-2">
        <Typography bold>
          <FormattedMessage
            id="contractors.uploadManualCSV.title"
            defaultMessage="Upload CSV"
          />
        </Typography>
        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="contractors.uploadManualCSV.description"
            defaultMessage="Upload your CSV file to update the payments for your contractors"
          />
        </Typography>
      </div>

      <div className="p-2" />
      <div className={cn('flex w-full gap-6')} {...getRootProps()}>
        <input {...getInputProps({ id: name, name })} />

        <Label className="sr-only" htmlFor={name}>
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Label>

        <MotionDiv key={files.length} className="w-full">
          {files.length ? (
            <div className="flex w-full flex-col justify-center gap-2">
              {files.map((file, index) => (
                <UploadedFile
                  key={index}
                  file={file.name}
                  onClick={() => {
                    onDrop([])
                  }}
                  isPending={false}
                />
              ))}

              <div className="p-1" />

              <Button
                onClick={handleSelectClick}
                leftIcon={<Reload className="size-4" />}
                type="button"
                variant="secondary"
              >
                <FormattedMessage
                  id="uploadFile.field.replaceFile"
                  defaultMessage="Replace file"
                />
              </Button>
            </div>
          ) : (
            <div
              className={cn(
                'flex h-[84px] w-full cursor-pointer items-center justify-center rounded-lg bg-neutral-gray-100 p-3 transition-all hover:bg-neutral-gray-200',
                {
                  'border border-dashed border-neutral-gray-600':
                    isDragActive || files.length > 0,
                  'bg-neutral-gray-200': isDragActive,
                },
              )}
            >
              <div className="flex items-center gap-2 text-neutral-gray-800">
                <Download className="size-5" />
                <Typography>
                  <FormattedMessage
                    id="uploadFileField.dragOrClick"
                    defaultMessage="Drag and drop or click to upload"
                  />
                </Typography>
              </div>
            </div>
          )}
        </MotionDiv>
      </div>
    </Card>
  )
}
