import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { getOnboardingAddress } from '@/lib/address'
import { getAnimationKey } from '@/lib/utils'
import { CountryName, Widget } from '@/shared/components'
import { Details, MotionDiv } from '@/shared/ui'

import { ContractorDetails } from '../../types'

type Props = {
  contractor?: ContractorDetails
  isPending: boolean
}

export const ContractorPersonalInformation = ({
  contractor,
  isPending,
}: Props) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          defaultMessage="Personal information"
          id="label.personalInformation"
        />
      }
    >
      <MotionDiv
        key={getAnimationKey(isPending)}
        className="flex flex-col gap-6"
      >
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="Email" id="label.email" />
          </Details.Label>
          {contractor ? (
            <Details.Value>{contractor.personalDetails.email}</Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Phone number"
              id="label.phoneNumber"
            />
          </Details.Label>
          {contractor ? (
            <Details.Value>
              {formatPhoneNumberIntl(
                contractor.personalDetails.internationalPhonePrefix +
                  contractor.personalDetails.localPhoneNumber,
              )}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="Birthday" id="label.birthday" />
          </Details.Label>
          {contractor ? (
            <Details.Value>
              {contractor.personalDetails.dateOfBirth
                ? intl.formatDate(
                    new Date(contractor.personalDetails.dateOfBirth),
                    {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      timeZone: 'UTC',
                    },
                  )
                : '-'}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Country of citizenship"
              id="label.countryOfCitizenship"
            />
          </Details.Label>
          {contractor ? (
            <CountryName
              countryCode={contractor.personalDetails.countryOfCitizenship}
            />
          ) : (
            <Details.Skeleton />
          )}
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage id="label.address" defaultMessage="Address" />
          </Details.Label>
          {contractor ? (
            <Details.Value className="whitespace-break-spaces text-right">
              {contractor.personalDetails.residentialAddress
                ? getOnboardingAddress(
                    contractor.personalDetails.residentialAddress,
                  )
                : '-'}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      </MotionDiv>
    </Widget>
  )
}
