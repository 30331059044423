import { Currency } from '@/constants/currency'
import { CountryCode } from '@/types/country'

import {
  Contractor,
  ContractorAgreementType,
  ContractorDetails,
  ContractorFirstCycleMethod,
  ContractorPayment,
  ContractorPaymentCycleDetails,
  ContractorPaymentFrequency,
  ContractorPaymentMethodState,
  ContractorPaymentMethodType,
  ContractorPaymentType,
  ContractorState,
  ContractPaymentDayType,
  ContractState,
} from '../../types'

export const MOCKED_CONTRACTORS: Contractor[] = [
  {
    id: '0a3a1024-5ac0-456b-8653-f61caa8e6400',
    firstName: 'Andrzej',
    lastName: 'Andrzej',
    secondLastName: 'Endrju',
    email: 'endrju@gov.pl',
    contractorRole: 'King of the World',
    countryOfCitizenship: CountryCode.US,
    paymentType: ContractorPaymentType.FIXED_RATE,
    startDate: '2025-09-06',
    lastPaymentDate: null,
    state: ContractorState.ACTIVE,
  },
  {
    id: '3c88add2-5485-414b-a786-1d1a0905f54d',
    firstName: 'Krzysztof',
    lastName: 'Broncel',
    secondLastName: 'Mega',
    email: 'krzysztof+contractor@dolarapp.com',
    contractorRole: 'BE Software Engineer',
    countryOfCitizenship: null,
    paymentType: ContractorPaymentType.PAY_AS_YOU_GO,
    startDate: '2024-11-13',
    lastPaymentDate: null,
    state: ContractorState.WAITING_FOR_CONTRACTOR,
  },
  {
    id: 'a794fc19-2574-4f9b-8a7c-aa942d9eda23',
    firstName: 'Maks',
    lastName: 'Akymenko',
    secondLastName: undefined,
    email: 'maks+contractor@dolarapp.com',
    contractorRole: 'Software Engineer',
    countryOfCitizenship: null,
    paymentType: ContractorPaymentType.FIXED_RATE,
    startDate: '2025-04-22',
    lastPaymentDate: null,
    state: ContractorState.WAITING_FOR_CONTRACTOR,
  },
]

export const MOCKED_CONTRACTOR_DETAILS: ContractorDetails = {
  identityId: 'dc64c142-7cbd-4867-b0f4-ce81dd2f8a5c',
  id: '0a3a1024-5ac0-456b-8653-f61caa8e6400',
  state: ContractorState.ACTIVE,
  personalDetails: {
    firstName: 'Andrzej',
    lastName: 'Andrzej',
    secondLastName: 'Endrju',
    email: 'endrju@gov.pl',
    internationalPhonePrefix: '+48',
    localPhoneNumber: '725725725',
    dateOfBirth: '1989-01-01',
    countryOfCitizenship: CountryCode.US,
    residentialAddress: {
      addressStreetNumber: '1235',
      addressStreet: 'Main St',
      addressDistrict: 'Downtown',
      addressCity: 'Springfield',
      addressState: 'IL',
      addressPostCode: '62701',
      addressCountry: CountryCode.US,
    },
  },
  taxDetails: {
    countryOfTaxResidence: CountryCode.US,
    taxId: '123456780',
    taxIdType: 'SSN',
    taxForm: 'W9',
  },
  paymentMethod: {
    state: ContractorPaymentMethodState.LINKED,
    paymentMethodDetails: {
      id: '123456780',
      type: ContractorPaymentMethodType.DOLAR_TAG,
      paymentMethodInformation: {
        dolarTag: '$tomaszjangrot14',
      },
    },
  },
  contracts: [
    {
      id: '7bb1b6b9-8e8b-4260-b044-86684ebb8685',
      state: ContractState.ACTIVE,
      paymentType: ContractorPaymentType.FIXED_RATE,
      agreementType: ContractorAgreementType.CUSTOM,
      contractorRole: 'King of the World',
      scopeOfWork: 'His main mission is to make the world a better place',
      startDate: '2025-09-06',
      endDate: null,
      createdByBusinessUserId: 'dc64c142-7cbd-4867-b0f4-ce81dd2f8a5c',
      paymentDetails: {
        currency: Currency.USD,
        amount: 100000000,
        frequency: ContractorPaymentFrequency.MONTHLY,
        dayType: ContractPaymentDayType.CUSTOM_DAY_OF_MONTH,
        day: 23,
        firstCycleMethod: ContractorFirstCycleMethod.CUSTOM_AMOUNT,
        firstCycleAmount: 1000000,
      },
    },
  ],
}

export const MOCKED_PAYMENT_CYCLE_DETAILS: ContractorPaymentCycleDetails = {
  id: '2502df2e-b1bf-487e-aec0-20b89dae37bd',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  paymentCycleStart: '2025-01-27',
  paymentCycleEnd: '2025-02-02',
  paymentFrequency: ContractorPaymentFrequency.SEMIMONTHLY,
  paymentDayType: ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
  paymentDay: null,
  fixedRatePayments: [
    {
      contractId: '87cf8a94-aceb-47e7-9fb9-780f1281f134',
      contractorId: '500973eb-5a61-4a40-8dea-5f6d48887915',
      personalDetails: {
        firstName: 'Maks',
        lastName: 'Contractoridze',
        secondLastName: undefined,
      },
      paymentMethodType: ContractorPaymentMethodType.US_BANK_ACCOUNT,
      paymentAmount: {
        amount: '500',
        currency: Currency.USDC,
      },
    },
    {
      contractId: 'fee1a694-43d2-437d-9df1-e82bd2cc43d7',
      contractorId: '327054be-b504-4c99-9015-732d021e3b19',
      personalDetails: {
        firstName: 'Sunny',
        lastName: 'Cantu',
        secondLastName: undefined,
      },
      paymentMethodType: ContractorPaymentMethodType.US_BANK_ACCOUNT,
      paymentAmount: {
        amount: '50000',
        currency: Currency.USDC,
      },
    },
    {
      contractId: '1704d9f8-1169-43f4-92d1-dd243840fb74',
      contractorId: 'd5bff148-0f7e-498d-9d03-b9c2929603e8',
      personalDetails: {
        firstName: 'Will',
        lastName: 'Harrington',
        secondLastName: undefined,
      },
      paymentMethodType: ContractorPaymentMethodType.US_BANK_ACCOUNT,
      paymentAmount: {
        amount: '500',
        currency: Currency.USDC,
      },
    },
  ],
  payAsYouGoPayments: [
    {
      contractId: 'b4c71318-5ccc-4161-a72e-dff773a34af4',
      contractorId: '5acf8cde-04a2-4c03-b311-2ac77db71d1c',
      personalDetails: {
        firstName: 'Bronek',
        lastName: 'Bronkowsky',
        secondLastName: undefined,
      },
      paymentMethodType: ContractorPaymentMethodType.US_BANK_ACCOUNT,
      paymentAmount: null,
    },
    {
      contractId: 'a5005235-dec0-4249-8eda-84d86f34815e',
      contractorId: '10975dfe-ff97-4fff-bdff-5eef37b2ba48',
      personalDetails: {
        firstName: 'Endrju',
        lastName: 'Kowalski',
        secondLastName: undefined,
      },
      paymentMethodType: ContractorPaymentMethodType.DOLAR_TAG,
      paymentAmount: null,
    },
  ],
}

export const MOCKED_CONTRACTOR_PAYMENTS: ContractorPayment[] = [
  {
    id: '3c40b1e8-7100-48ae-a7f6-022f00bf902c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    state: 'CREATED',
    paymentCycleStart: '2025-01-16',
    paymentCycleEnd: '2025-01-31',
    paymentDueDate: '2025-01-31',
    paymentFrequency: ContractorPaymentFrequency.SEMIMONTHLY,
    paymentDayType: ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
    paymentDay: null,
    numberOfPayments: 1,
  },
  {
    id: '51b63624-98d0-48a6-8057-89076b69f4fc',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    state: 'CREATED',
    paymentCycleStart: '2025-01-16',
    paymentCycleEnd: '2025-01-31',
    paymentDueDate: '2025-01-31',
    paymentFrequency: ContractorPaymentFrequency.SEMIMONTHLY,
    paymentDayType: ContractPaymentDayType.LAST_FRIDAY_OF_PERIOD,
    paymentDay: null,
    numberOfPayments: 1,
  },
  {
    id: '2502df2e-b1bf-487e-aec0-20b89dae37bd',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    state: 'CREATED',
    paymentCycleStart: '2025-01-27',
    paymentCycleEnd: '2025-02-02',
    paymentDueDate: '2025-01-31',
    paymentFrequency: ContractorPaymentFrequency.WEEKLY,
    paymentDayType: ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
    paymentDay: null,
    numberOfPayments: 3,
  },
  {
    id: '03bd2497-b419-4e71-a5d8-472b958374ae',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    state: 'CREATED',
    paymentCycleStart: '2025-01-27',
    paymentCycleEnd: '2025-02-09',
    paymentDueDate: '2025-02-05',
    paymentFrequency: ContractorPaymentFrequency.BIWEEKLY,
    paymentDayType: ContractPaymentDayType.CUSTOM_DAY_OF_WEEK,
    paymentDay: 3,
    numberOfPayments: 1,
  },
  {
    id: '0f4dcdac-3dba-4af6-bd9d-c875c5ba935a',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    state: 'CREATED',
    paymentCycleStart: '2025-02-01',
    paymentCycleEnd: '2025-02-28',
    paymentDueDate: '2025-02-15',
    paymentFrequency: ContractorPaymentFrequency.MONTHLY,
    paymentDayType: ContractPaymentDayType.CUSTOM_DAY_OF_MONTH,
    paymentDay: 15,
    numberOfPayments: 2,
  },
]
