import {
  ContractorOnboardingPaymentMethod,
  ContractorPaymentMethodType,
} from '@/domains/Contractor/types'
import { intersperse } from '@/lib/typography'

export function getPaymentMethodDetails(
  method?: ContractorOnboardingPaymentMethod,
) {
  if (!method) return undefined

  const {
    paymentMethodDetails: { type, paymentMethodInformation },
  } = method

  switch (type) {
    case ContractorPaymentMethodType.US_BANK_ACCOUNT: {
      return intersperse(
        [
          paymentMethodInformation.accountNumber,
          paymentMethodInformation.routingNumber,
        ],
        ' • ',
      )
    }

    case ContractorPaymentMethodType.MX_BANK_ACCOUNT: {
      return paymentMethodInformation.clabe
    }

    case ContractorPaymentMethodType.DOLAR_TAG: {
      return paymentMethodInformation.dolarTag
    }
    default:
      return undefined
  }
}
