import {
  ContractorPaymentMethodDetails,
  ContractorPaymentMethodState,
} from '@/domains/Business/features/Contractors/types'
import * as Transactions from '@/domains/Business/features/Transactions/types'
import { CountryCode } from '@/types/country'

// re-export to avoid duplication of types
export * from '@/domains/Business/features/Contractors/types'

export enum ExternalContractorState {
  ACTIVE = 'ACTIVE',
  ACCEPTED_INVITE = 'ACCEPTED_INVITE',
  ONBOARDING = 'ONBOARDING',
}

export type ExternalContractor = {
  businessId: string
  email: string
  firstName: string
  id: string
  lastName: string
  secondLastName: string | null
  state: ExternalContractorState
}

export type ExternalContractorCompany = {
  id: string
  legalName: string
}

export type ContractorSingleTransaction = Transactions.SingleTransaction

type Address = {
  addressStreetNumber: string
  addressStreet: string
  addressDistrict?: string
  addressCity: string
  addressState: string
  addressPostCode: string
  addressCountry: CountryCode
}

export type ExternalContractorPersonalDetails = {
  dateOfBirth: string
  countryOfCitizenship: CountryCode
  residentialAddress: Required<Address>
  countryOfTaxResidence: CountryCode | null
  taxId: string | null
  taxIdType: string | null
}

export type ContractorOnboardingPaymentMethod = {
  state: ContractorPaymentMethodState
  linkedOn: string
  paymentMethodDetails: ContractorPaymentMethodDetails
}
