import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { getOnboardingAddress } from '@/lib/address'
import { CountryName } from '@/shared/components'
import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { LegalRepresentativeStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: LegalRepresentativeStep['stepDetails']
}

export const LegalRepresentativeReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    OFFICIAL_GOVERNMENT_ID: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.officialGovernmentId',
      defaultMessage: 'Official government ID',
    }),
    POWER_OF_ATTORNEY: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.powerOfAttorney',
      defaultMessage: 'Power of attorney',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  const { legalRepresentative } = stepDetails ?? {}

  const { ...fields } = legalRepresentative

  const specificOrderFields = [
    'firstName',
    'lastName',
    'secondLastName',
    'email',
    'internationalPhonePrefix',
    'localPhoneNumber',
    'taxResidenceCountry',
    'taxId',
    'addressStreet',
  ]

  const orderedFields = Object.fromEntries(
    Object.entries(fields as Record<string, string>).sort(
      ([keyA], [keyB]) =>
        specificOrderFields.indexOf(keyA) - specificOrderFields.indexOf(keyB),
    ),
  )

  const mappedKeys: Record<keyof typeof fields, string | undefined> = {
    email: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.email',
      defaultMessage: 'Work email',
    }),
    firstName: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.firstName',
      defaultMessage: 'First name',
    }),
    lastName: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.lastName',
      defaultMessage: 'Last name',
    }),
    secondLastName: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.secondLastName',
      defaultMessage: 'Second last name',
    }),
    internationalPhonePrefix: undefined,
    localPhoneNumber: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.phoneNumber',
      defaultMessage: 'Phone number',
    }),
    taxResidenceCountry: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.taxResidenceCountry',
      defaultMessage: 'Country of tax residence',
    }),
    taxId: intl.formatMessage({
      id: 'onboarding.step.legalRepresentative.taxId',
      defaultMessage: 'Tax identification number',
    }),
    addressStreet: intl.formatMessage({
      id: 'label.address',
      defaultMessage: 'Address',
    }),
    addressStreetNumber: undefined,
    addressDistrict: undefined,
    addressCity: undefined,
    addressState: undefined,
    addressPostCode: undefined,
    addressCountry: undefined,
  }

  const mappedValues: Record<keyof typeof fields, React.ReactNode> =
    useMemo(() => {
      return {
        email: <Typography>{fields.email}</Typography>,
        firstName: <Typography>{fields.firstName}</Typography>,
        lastName: <Typography>{fields.lastName}</Typography>,
        secondLastName: fields.secondLastName ? (
          <Typography>{fields.secondLastName}</Typography>
        ) : undefined,
        internationalPhonePrefix: undefined,
        localPhoneNumber: (
          <Typography>{`${fields.internationalPhonePrefix} ${fields.localPhoneNumber}`}</Typography>
        ),
        taxResidenceCountry: (
          <CountryName countryCode={fields.taxResidenceCountry} />
        ),
        taxId: <Typography>{fields.taxId}</Typography>,
        addressStreet: fields.addressStreet ? (
          <Typography className="whitespace-break-spaces text-right">
            {getOnboardingAddress(fields, intl)}
          </Typography>
        ) : null,
        addressStreetNumber: undefined,
        addressDistrict: undefined,
        addressCity: undefined,
        addressState: undefined,
        addressPostCode: undefined,
        addressCountry: undefined,
      }
    }, [fields, intl])

  const details = Object.entries(orderedFields).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  return (
    <StepDetails
      step={OnboardingStepName.LEGAL_REPRESENTATIVE}
      title={intl.formatMessage({
        id: 'onboarding.step.legalRepresentative',
        defaultMessage: 'Legal representative',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
