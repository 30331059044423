import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { BulkTransactions } from '../BulkPayments/BulkTransactions'

import { Contractors } from './Contractors'
import {
  AllContractors,
  ContractorDetails,
  ContractorsOverview,
  ContractorsTransactions,
  CreateContractor,
  HistoricalPayments,
  OffsetCyclePayment,
  RunPayment,
  UploadManualCSV,
} from './features'

export const contractorsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Contractors,
    element: <Contractors />,
    children: [
      {
        index: true,
        element: <ContractorsOverview />,
      },
      {
        path: BusinessRoute.ContractorsOverview,
        element: <ContractorsOverview />,
      },
      {
        path: BusinessRoute.ContractorsHistoricalPayments,
        element: <HistoricalPayments />,
      },
      {
        path: BusinessRoute.ContractorsAll,
        element: <AllContractors />,
      },
    ],
  },
  {
    path: BusinessRoute.ContractorsDetails,
    element: <ContractorDetails />,
  },
  {
    path: BusinessRoute.ContractorsHistoricalPaymentTransactions,
    element: <BulkTransactions />,
  },
  {
    path: BusinessRoute.ContractorsPayments,
    element: <ContractorsTransactions />,
  },
]

export const contractorActionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.ContractorsCreate,
    element: <CreateContractor />,
  },
  {
    path: BusinessRoute.ContractorsRunPayment,
    element: <RunPayment />,
  },
  {
    path: BusinessRoute.ContractorsOffsetCyclePayment,
    element: <OffsetCyclePayment />,
  },
  {
    path: BusinessRoute.ContractorsManualCSV,
    element: <UploadManualCSV />,
  },
]
