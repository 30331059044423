import { isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { formatDate } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Skeleton, Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../hooks'
import { ContractorsTransaction, HistoricalPaymentType } from '../../types'

import { ContractorPaymentAvatar } from './ContractorPaymentAvatar'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentCard = ({ payment }: Props) => {
  const frequency = usePaymentFrequency({
    dayType:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDayType
        : undefined,
    day:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDay
        : undefined,
    frequency:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentFrequency
        : undefined,
  })

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <ContractorPaymentAvatar payment={payment} />

        {(() => {
          switch (payment.type) {
            case HistoricalPaymentType.PAYMENT_CYCLE:
              return (
                <div className="flex flex-col">
                  <Typography bold>
                    {intersperse(
                      [
                        formatDate(
                          payment.paymentDetails.paymentCycleStart,
                          isSameYear(
                            payment.paymentDetails.paymentCycleStart,
                            payment.paymentDetails.paymentCycleEnd,
                          )
                            ? 'dd MMM.'
                            : 'dd MMM. yyyy',
                        ),
                        formatDate(
                          payment.paymentDetails.paymentCycleEnd,
                          'dd MMM. yyyy',
                        ),
                      ],
                      ' - ',
                    )}
                  </Typography>
                  <Typography
                    variant="body-small"
                    className="text-neutral-gray-600"
                  >
                    {intersperse(
                      [
                        formatDate(payment.paymentDate, 'dd MMM. yyyy'),
                        frequency,
                      ],
                      ' • ',
                    )}
                  </Typography>
                </div>
              )

            case HistoricalPaymentType.OFF_CYCLE:
              return (
                <div className="flex flex-col">
                  <Typography bold>
                    {payment.paymentDetails.paymentLabel}
                  </Typography>
                  <Typography
                    variant="body-small"
                    className="text-neutral-gray-600"
                  >
                    {intersperse(
                      [
                        formatDate(payment.paymentDate, 'dd MMM. yyyy'),
                        <FormattedMessage
                          key="paymentCategory"
                          id="contractor.paymentCategory.label"
                          defaultMessage="{type, select, BONUS {Bonus} COMMISSION {Commission} REIMBURSEMENT {Reimbursement} EXTRA_HOURS {Extra hours} ADJUSTMENT {Adjustment} SETTLEMENT {Settlement} other {}}"
                          values={{ type: payment.paymentCategory }}
                        />,
                      ],
                      ' • ',
                    )}
                  </Typography>
                </div>
              )

            default:
              return null
          }
        })()}
      </div>

      <Typography bold>{formatAmount(payment.amount)}</Typography>
    </div>
  )
}

ContractorPaymentCard.Skeleton = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Skeleton className="size-10 rounded-full" />
        <div className="flex flex-col gap-1">
          <Skeleton className="h-5 w-44" />{' '}
          <Skeleton className="h-4 w-32" />{' '}
        </div>
      </div>

      <div className="flex items-center gap-3">
        <Skeleton className="h-5 w-24" />
      </div>
    </div>
  )
}
