import { cn } from '@/lib/utils'
import { Skeleton, Typography } from '@/shared/ui'

import { Transaction } from '../../features/Transactions/types'
import { useWallets } from '../../hooks'
import { AccountIcon } from '../AccountIcon'

type Props = {
  transaction: Transaction
}

export const WalletCell = ({ transaction }: Props) => {
  const { wallets, isPending } = useWallets({ showAll: true })

  const wallet = wallets?.find((wallet) => wallet.id === transaction.walletId)

  return (
    <div className="flex max-w-36 items-center gap-2">
      {isPending ? (
        <Skeleton className="h-[21px] w-24" />
      ) : (
        <div className="flex max-w-36 items-center gap-2">
          <AccountIcon id={wallet?.id} />
          <Typography title={wallet?.label} className={cn('line-clamp-1')}>
            {wallet?.label}
          </Typography>
        </div>
      )}
    </div>
  )
}
