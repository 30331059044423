import { FormattedMessage } from 'react-intl'

import { DOLAR_TAG, PaymentRails, SPEI } from '@/domains/Business/constants'
import { intersperse } from '@/lib/typography'
import { Typography, TypographyProps } from '@/shared/ui'

import { CSVTransaction } from '../types'

type Props = {
  transaction: CSVTransaction
  textVariant?: TypographyProps['variant']
}

export const CSVTransactionDetailsRow = ({
  transaction,
  textVariant,
}: Props) => {
  return (
    <div className="flex items-center gap-1 text-neutral-gray-600 ">
      {(() => {
        switch (transaction.paymentRails) {
          case PaymentRails.MEX:
            return intersperse(
              [
                { key: 'SPEI', value: SPEI },
                {
                  key: 'accountNumber',
                  value: transaction.paymentDetails.accountNumber,
                },
              ].map(({ key, value }) => (
                <Typography variant={textVariant} key={key}>
                  {value}
                </Typography>
              )),
              '•',
            )

          case PaymentRails.USA:
            return intersperse(
              [
                {
                  key: 'paymentMethod',
                  value: transaction.paymentDetails.paymentMethod,
                },
                {
                  key: 'accountNumber',
                  value: transaction.paymentDetails.accountNumber,
                },
                {
                  key: 'routingNumber',
                  value: transaction.paymentDetails.routingNumber,
                },
              ].map(({ key, value }) => (
                <Typography variant={textVariant} key={key}>
                  {key === 'paymentMethod' ? (
                    <FormattedMessage
                      id="paymentMethod.label"
                      defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                      values={{ method: value }}
                    />
                  ) : (
                    value
                  )}
                </Typography>
              )),
              '•',
            )

          case PaymentRails.DOLAR_APP:
            return intersperse(
              [
                { key: DOLAR_TAG, value: DOLAR_TAG },
                {
                  key: 'dolarTagValue',
                  value: transaction.paymentDetails.dolarTag,
                },
              ].map(({ key, value }) => (
                <Typography variant={textVariant} key={key}>
                  {value}
                </Typography>
              )),
              '•',
            )

          default:
            return ''
        }
      })()}
    </div>
  )
}
