import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { useBusinessRole, useWallets } from '@/domains/Business/hooks'
import { CreditCard, Invoice, PencilEdit } from '@/shared/icons/outline'
import { Ellipsis } from '@/shared/icons/solid'
import { Button } from '@/shared/ui/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/DropdownMenu'

import { Wallet, WalletState } from '../types'

import { EditAccountSidebar } from './EditAccountSidebar'
import { StatementsSidebar } from './StatementsSidebar'

type Props = {
  account?: Wallet
}

export const AccountActions = ({ account }: Props) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const { defaultWalletId } = useWallets()
  const { isAdmin } = useBusinessRole()

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={intl.formatMessage({
              id: 'action.showMoreInformation',
              defaultMessage: 'Show more information',
            })}
            size="inline"
            className="size-10"
            variant="secondary"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem>
            <Button
              leftIcon={<Invoice className="size-5" />}
              onClick={() => {
                setSearchParams((params) => {
                  params.set('statements', 'ACCOUNT')
                  return params
                })
              }}
              size="sm"
              variant="dropdown"
              width="full"
            >
              <FormattedMessage
                id="label.accountStatements"
                defaultMessage="Account statements"
              />
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Button
              leftIcon={<CreditCard className="size-5" />}
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('statements', 'CREDIT')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              className="justify-start px-4 font-normal"
              width="full"
              variant="ghost"
            >
              <FormattedMessage
                id="label.creditStatements"
                defaultMessage="Credit statements"
              />
            </Button>
          </DropdownMenuItem>
          {isAdmin &&
          defaultWalletId !== account?.id &&
          account?.state !== WalletState.CLOSED ? (
            <DropdownMenuItem>
              <Button
                leftIcon={<PencilEdit className="size-5" />}
                onClick={() => {
                  setSearchParams(
                    (params) => {
                      params.set('edit-account', 'true')
                      return params
                    },
                    { preventScrollReset: true },
                  )
                }}
                size="sm"
                variant="dropdown"
                width="full"
              >
                <FormattedMessage id="label.edit" defaultMessage="Edit" />
              </Button>
            </DropdownMenuItem>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>

      <StatementsSidebar
        account={account}
        type={searchParams.get('statements')}
        isOpen={!!searchParams.get('statements')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('statements')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />

      <EditAccountSidebar
        isOpen={!!searchParams.get('edit-account')}
        onOpenChange={() => {
          setSearchParams((params) => {
            params.delete('edit-account')
            return params
          })
        }}
        account={account}
        onDelete={() => navigate(BusinessRoute.Accounts)}
      />
    </>
  )
}
