import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

export const clabeValidation = z.object({
  clabe: z
    .string()
    .min(17, {
      message: 'validation.clabe.invalid',
    })
    .refine((v) => /^\d+$/.test(v), {
      message: 'validation.clabe.invalid',
    }),
})

export const individualValidation = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  firstName: z.string().min(1, {
    message: 'validation.firstName.required',
  }),
  lastName: z.string().min(1, {
    message: 'validation.lastName.required',
  }),
  secondLastName: z.string().optional(),
  email: z
    .string()
    .email({
      message: 'validation.email.invalid',
    })
    .optional()
    .or(z.literal('')),
  internationalPhonePrefix: z
    .string()
    .min(1, {
      message: 'validation.phoneCode.required',
    })
    .optional()
    .or(z.literal('')),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    })
    .optional()
    .or(z.literal('')),
})

export const businessValidation = z.object({
  legalName: z.string().min(1, {
    message: 'validation.legalName.required',
  }),
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  email: z
    .string()
    .email({
      message: 'validation.email.invalid',
    })
    .optional()
    .or(z.literal('')),
  internationalPhonePrefix: z
    .string()
    .min(1, {
      message: 'validation.phoneCode.required',
    })
    .optional()
    .or(z.literal('')),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    })
    .optional()
    .or(z.literal('')),
})

export const paymentValidation = z.object({
  paymentMethod: z
    .string()
    .min(1, { message: 'validation.paymentMethod.required' }),
  accountNumber: z
    .string()
    .min(1, { message: 'validation.accountNumber.required' }),
  routingNumber: z
    .string()
    .min(1, { message: 'validation.routingNumber.required' }),
  address: z.object({
    street: z.string().min(1, { message: 'validation.addressStreet.required' }),
    city: z.string().min(1, { message: 'validation.addressCity.required' }),
    countryCode: z
      .string()
      .min(1, { message: 'validation.addressCountry.required' }),
    state: z.string().min(1, { message: 'validation.addressState.required' }),
    postcode: z
      .string()
      .min(1, { message: 'validation.addressZipCode.required' }),
  }),
})
