import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link, useParams, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ActiveFilters,
  ContractorPaymentDestinationBadge,
  ContractorPaymentDestinationFilter,
  ContractorPaymentStateBadge,
  ContractorPaymentStateFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  TransactionDetailsSidebar,
} from '@/domains/Business/components'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { getFullName } from '@/lib/typography'
import { getAnimationKey } from '@/lib/utils'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  DataTable,
  MotionDiv,
  MotionSpan,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { getTransaction } from '../../Transactions/api'
import { SingleTransaction } from '../../Transactions/types'
import { getContractor, getContractorPayments } from '../api'
import { useContractorPaymentColumns } from '../components'
import { ContractorDetails, ContractorsTransaction } from '../types'

export const ContractorsTransactions = () => {
  const columns = useContractorPaymentColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id: contractorId } = useParams<{ id: string }>()

  const [transactionId] = useSearchParamsValue(['id'])

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const [allTransactions, contractorDetails, singleTransaction] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractorPayments, contractorId, params],
        queryFn: () =>
          getContractorPayments({ contractorId: contractorId ?? '', params }),
        select: (data: AxiosResponse<ContractorsTransaction[]>) => data?.data,
        enabled: !!contractorId,
      },
      {
        queryKey: [queryKeys.getContractor, contractorId],
        queryFn: () => getContractor({ id: contractorId ?? '' }),
        select: (data: AxiosResponse<ContractorDetails>) => data?.data,
        enabled: !!contractorId,
      },
      {
        queryKey: [queryKeys.getTransaction, transactionId],
        queryFn: () => getTransaction({ id: transactionId ?? '' }),
        select: (data: AxiosResponse<SingleTransaction>) => data?.data,
        enabled: !!transactionId,
      },
    ],
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={BusinessRoute.ContractorsAll}>
                  <FormattedMessage
                    id="label.contractors"
                    defaultMessage="Contractors"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <MotionSpan key={getAnimationKey(contractorDetails.isPending)}>
              {contractorDetails.isPending ? (
                <Skeleton className="h-6 w-36" />
              ) : (
                <BreadcrumbLink asChild>
                  <Button variant="ghost" size="inline" asChild>
                    <Link
                      to={generatePath(BusinessRoute.ContractorsDetails, {
                        id: contractorId,
                      })}
                    >
                      {getFullName(contractorDetails.data?.personalDetails)}
                    </Link>
                  </Button>
                </BreadcrumbLink>
              )}
            </MotionSpan>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <Typography>
                <FormattedMessage
                  id="label.payments"
                  defaultMessage="Payments"
                />
              </Typography>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <ContractorPaymentDestinationFilter />
          <DateFilter />
          <ContractorPaymentStateFilter />
        </FiltersWidget>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <ContractorPaymentDestinationBadge />
        <ContractorPaymentStateBadge />
        <DateBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(allTransactions.isPending, params)}>
        {allTransactions.data?.length === 0 &&
        Object.keys(params).length === 0 ? (
          <EmptyTransactionsCard />
        ) : (
          <DataTable
            isLoading={allTransactions.isPending}
            isLoadingMore={false}
            onRowClick={(payment) => {
              if (!payment.transactionId) {
                return
              }

              setSearchParams(
                (params) => {
                  if (payment.transactionId) {
                    params.set('id', payment.transactionId)
                  }
                  return params
                },
                { preventScrollReset: true },
              )
            }}
            columns={columns}
            data={allTransactions.data ?? []}
            loaderOptions={{ rows: 5 }}
          />
        )}
      </MotionDiv>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={!!transactionId}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('id')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </div>
  )
}
