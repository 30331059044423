import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { StatusBadge } from '@/shared/components'
import { Button, Checkbox, Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'
import { getName } from '../utils'

import { CSVTransactionDetailsRow } from './CSVTransactionDetailsRow'

type Props = {
  transaction: CSVTransaction
  selectedTransactions?: string[]
  onSelect?: (ids: string[]) => void
}

export const CSVTransactionRow = ({
  onSelect,
  selectedTransactions,
  transaction,
}: Props) => {
  const handleSelect = () => {
    if (!onSelect) return
    const ids = selectedTransactions?.includes(transaction.id)
      ? selectedTransactions.filter((id) => id !== transaction.id)
      : [...(selectedTransactions ?? []), transaction.id]

    onSelect(ids)
  }

  return (
    <Button
      variant="ghost"
      size="inline"
      className="flex w-full justify-between rounded-none border-b border-neutral-gray-200 transition-transform last:border-b-0 hover:scale-[1.01]"
      onClick={handleSelect}
    >
      <div className="flex items-center gap-4">
        {onSelect ? (
          <Checkbox
            tabIndex={-1}
            checked={selectedTransactions?.includes(transaction.id)}
          />
        ) : null}

        <div className="flex flex-col py-3">
          <div className="flex items-center gap-1">
            <Typography bold>
              {getName(transaction) ?? <span className="inline-flex h-5" />}
            </Typography>
            {!transaction.beneficiaryId && (
              <StatusBadge
                variant="active"
                title={<FormattedMessage id="label.new" defaultMessage="New" />}
              />
            )}
          </div>

          <CSVTransactionDetailsRow transaction={transaction} />
        </div>
      </div>

      <div className="flex flex-col">
        <Typography text="right" bold>
          {formatAmount({
            amount: transaction.paymentAmount,
            currency: transaction.paymentCurrency,
          })}
        </Typography>
        {transaction.paymentCurrency !== Currency.USDC && (
          <Typography text="right" className="text-neutral-gray-600">
            {formatAmount({
              amount: transaction.baseAmount,
              currency: transaction.baseCurrency,
            })}
          </Typography>
        )}
      </div>
    </Button>
  )
}
