import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { ContractorPaymentMethodState } from '../types'

function getBadgeVariant(
  state: ContractorPaymentMethodState,
  variant: 'sidebar' | 'default',
) {
  return {
    [ContractorPaymentMethodState.NOT_LINKED]:
      variant === 'sidebar' ? 'neutral-dark' : 'neutral',
    [ContractorPaymentMethodState.INACTIVE]:
      variant === 'sidebar' ? 'neutral-dark' : 'neutral',
    [ContractorPaymentMethodState.LINKED]: 'active',
  }[state] as StatusVariant
}

type Props = {
  state?: ContractorPaymentMethodState
  variant?: 'sidebar' | 'default'
}

export const ContractorPaymentBadge = ({
  state,
  variant = 'default',
}: Props) => {
  if (!state) {
    return null
  }

  const badgeVariant = getBadgeVariant(state, variant)

  return (
    <StatusBadge
      variant={badgeVariant}
      title={
        <FormattedMessage
          id="contractor.paymentDetails.state.label"
          defaultMessage="{state, select, NOT_LINKED {Not linked} LINKED {Linked} other {}}"
          values={{ state }}
        />
      }
    />
  )
}
