import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { OffCyclePaymentCategory } from '@/domains/Business/features/Contractors/types'

import { CATEGORY_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const HistoricalPaymentCategoryBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(CATEGORY_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(CATEGORY_FILTER_NAME)
            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.category',
        defaultMessage: 'Category',
      })}
    >
      {searchParams
        .get(CATEGORY_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(OffCyclePaymentCategory).find(
            (o) => o === option,
          )

          return intl.formatMessage(
            {
              id: 'contractor.paymentCategory.label',
              defaultMessage:
                '{type, select, BONUS {Bonus} COMMISSION {Commission} REIMBURSEMENT {Reimbursement} EXTRA_HOURS {Extra hours} ADJUSTMENT {Adjustment} SETTLEMENT {Settlement} other {}}',
            },
            { type: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
