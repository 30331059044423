import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { useDebounce } from '@/hooks/useDebounce'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getImage } from '@/lib/images'
import { getAnimationKey } from '@/lib/utils'
import { GoBackButton } from '@/shared/components'
import { Search } from '@/shared/icons/outline'
import { X } from '@/shared/icons/solid'
import {
  AnimatedFormLabel,
  Avatar,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MotionDiv,
  Skeleton,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { getDolarAppUsers } from '../../api'
import { DolarAppSearchRecipient } from '../../types'
import { getIconByEntityType } from '../../utils'

const dolarTagSearchSchema = z.object({
  dolarTag: z.string(),
})

type DolarTagSearchSchema = z.infer<typeof dolarTagSearchSchema>

type Props = {
  dolarAppRecipient?: DolarAppSearchRecipient
  onBack: () => void
  onContinue: (recipient: DolarAppSearchRecipient) => void
}

export const RecipientDolarTagStep = ({
  dolarAppRecipient,
  onBack,
  onContinue,
}: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const Icon = getIconByEntityType(dolarAppRecipient?.owner.type)

  const [dolarTag, setDolarTag] = useState(
    dolarAppRecipient?.alias?.value ?? '',
  )

  const handleDolarTagChange = useDebounce(setDolarTag, 300)

  const [hasTouchedField, setHasTouchedField] = useState(false)

  const form = useForm<DolarTagSearchSchema>({
    mode: 'onChange',
    resolver: zodResolver(dolarTagSearchSchema),
    defaultValues: {
      dolarTag: dolarAppRecipient?.alias?.value ?? '',
    },
  })

  const hasDolarTag =
    !!dolarAppRecipient?.alias &&
    dolarAppRecipient.alias.value === dolarTag &&
    !hasTouchedField

  const enabledSearch = dolarTag.length >= 3

  const dolarAppUsersQuery = useQuery({
    queryKey: [queryKeys.getDolarAppUsers, dolarTag],
    queryFn: () => getDolarAppUsers(dolarTag),
    select: (data) => data.data,
    enabled: !hasDolarTag && enabledSearch,
    staleTime: 0,
  })

  useEffect(() => {
    if (dolarAppUsersQuery.isError) {
      notifyError(dolarAppUsersQuery.error)
    }
  }, [dolarAppUsersQuery.error, dolarAppUsersQuery.isError, notifyError])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Search DolarTag"
            id="recipients.add.dolarTag.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Send digital dollars for free to anyone in DolarApp"
            id="recipients.add.dolarTag.subtitle"
          />
        </Typography>

        <div className="p-6" />
        <Form {...form}>
          <form>
            <FormField
              control={form.control}
              name="dolarTag"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.dolarTag',
                        defaultMessage: 'DolarTag',
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value)

                        setHasTouchedField(true)
                        handleDolarTagChange(e.target.value)
                      }}
                    />
                  </FormControl>

                  {hasDolarTag ? (
                    <Button
                      type="button"
                      variant="ghost"
                      size="inline"
                      className="absolute right-4 top-4  rounded-full bg-neutral-gray-600 p-0.5 hover:bg-neutral-gray-400"
                      aria-label={intl.formatMessage({
                        id: 'action.clearSearch',
                        defaultMessage: 'Clear search',
                      })}
                      onClick={() => {
                        field.onChange('')
                      }}
                    >
                      <X className="size-4 text-white" />
                    </Button>
                  ) : (
                    <span className="absolute right-4 top-3.5 text-neutral-gray-400">
                      <Search />
                    </span>
                  )}
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="DolarTag"
                      id="label.dolarTag"
                    />
                  </AnimatedFormLabel>

                  <AnimatePresence mode="wait">
                    {field.value !== '' && !hasDolarTag && enabledSearch && (
                      <MotionDiv
                        className="mt-2"
                        key={getAnimationKey(dolarAppUsersQuery.isFetching)}
                      >
                        <Card
                          size="upload"
                          className="flex max-h-80 flex-col gap-3 overflow-auto"
                        >
                          {dolarAppUsersQuery.isFetching ? (
                            <div className="flex flex-col gap-3 pl-3">
                              <Skeleton className="h-4 w-1/2" />
                              <Skeleton className="h-4 w-1/2" />
                              <Skeleton className="h-4 w-1/2" />
                            </div>
                          ) : dolarAppUsersQuery.data?.length ? (
                            (dolarAppUsersQuery.data ?? []).map((recipient) => (
                              <Button
                                type="button"
                                variant="ghost"
                                width="full"
                                className="justify-start"
                                size="inline"
                                key={recipient.alias.value}
                                onClick={() => onContinue(recipient)}
                              >
                                <Avatar
                                  variant="primary"
                                  icon={
                                    <Icon className="size-5 text-primary" />
                                  }
                                />
                                <div className="flex flex-col">
                                  <Typography bold>
                                    {recipient.owner.displayName}
                                  </Typography>
                                  <Typography
                                    variant="body-small"
                                    className="text-neutral-gray-600"
                                  >
                                    {recipient.alias.value}
                                  </Typography>
                                </div>
                              </Button>
                            ))
                          ) : (
                            <div className="flex flex-col items-center pb-2">
                              <img
                                src={getImage({ name: 'magnifier' })}
                                alt=""
                                aria-hidden
                                className="size-32"
                              />
                              <div className="flex flex-col items-center gap-2">
                                <Typography bold variant="body-base">
                                  <FormattedMessage
                                    defaultMessage="No recipients found for “{dolarTag}”"
                                    id="recipients.add.dolarTag.noResults"
                                    values={{ dolarTag }}
                                  />
                                </Typography>
                                <Typography className="text-neutral-gray-600">
                                  <FormattedMessage
                                    defaultMessage="Try searching for another name or account number"
                                    id="recipients.add.dolarTag.noResults.subtitle"
                                  />
                                </Typography>
                              </div>
                            </div>
                          )}
                        </Card>
                      </MotionDiv>
                    )}
                  </AnimatePresence>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </SlideInScreen>
    </>
  )
}
