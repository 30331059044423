import { api } from '@/lib/api'

import { HistoricalPaymentDetails } from '../types'

type HistoricalPaymentParams = {
  id: string
}

export const getHistoricalPayment = ({ id }: HistoricalPaymentParams) => {
  return api.get<HistoricalPaymentDetails>(
    `/v1/me/historical-contractor-payments/${id}`,
  )
}
