import { FormattedMessage } from 'react-intl'

import { getImage } from '@/lib/images'
import { Card, Typography } from '@/shared/ui'

export const EmptyContractorPayments = () => {
  return (
    <Card
      className="flex h-auto w-full flex-wrap items-center justify-between gap-3 overflow-hidden md:flex-nowrap md:gap-0"
      size="small"
    >
      <div className="flex flex-wrap items-center gap-8">
        <img
          className="relative -bottom-4 hidden w-24 object-contain md:block"
          src={getImage({
            name: 'empty-transactions',
            category: 'transactions',
          })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="contractors.payments.empty.title"
              defaultMessage="No payments found"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="contractors.payments.empty.title.description"
              defaultMessage="There are no payments yet for this contractor"
            />
          </Typography>
        </div>
      </div>
    </Card>
  )
}
