import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { ContractorPaymentMethodType } from '../../features/Contractors/types'

import { PAYMENT_METHOD_TYPES_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const ContractorPaymentDestinationBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(PAYMENT_METHOD_TYPES_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(PAYMENT_METHOD_TYPES_FILTER_NAME)
            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.destination',
        defaultMessage: 'Destination',
      })}
    >
      {searchParams
        .get(PAYMENT_METHOD_TYPES_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(ContractorPaymentMethodType).find(
            (o) => o === option,
          )

          return intl.formatMessage(
            {
              id: 'contractor.payment.destination.label',
              defaultMessage:
                '{type, select, DOLAR_TAG {DolarTag} US_BANK_ACCOUNT {US Bank Account} MX_BANK_ACCOUNT {MX Bank Account} other {}}',
            },
            { type: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
