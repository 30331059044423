import { FormattedMessage } from 'react-intl'

import { Bank, Dolarapp } from '@/shared/icons/outline'
import { Avatar, Typography } from '@/shared/ui'

import {
  ContractorPaymentMethodType,
  ContractorsTransaction,
} from '../../../types'

type Props = {
  payment: ContractorsTransaction
}

export const DestinationCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center gap-1">
      {(() => {
        switch (payment.paymentMethodType) {
          case ContractorPaymentMethodType.US_BANK_ACCOUNT:
            return (
              <>
                <Avatar size="sm" icon={<Bank className="size-3" />} />

                <Typography>
                  <FormattedMessage
                    id="label.usBankAccount"
                    defaultMessage="US Bank Account"
                  />
                </Typography>
              </>
            )

          case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
            return (
              <>
                <Avatar size="sm" icon={<Bank className="size-3" />} />

                <Typography>
                  <FormattedMessage
                    id="label.mxBankAccount"
                    defaultMessage="MX Bank Account"
                  />
                </Typography>
              </>
            )

          case ContractorPaymentMethodType.DOLAR_TAG:
            return (
              <>
                <Avatar size="sm" icon={<Dolarapp className="size-3" />} />

                <Typography>
                  {payment.paymentDetails.dolarTag ?? '-'}
                </Typography>
              </>
            )
        }
      })()}
    </div>
  )
}
