import { useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { guideUrlByLocale, templateUrlByLocale } from '@/constants/urls'
import { useKeyPress } from '@/hooks/useKeyPress'
import { cn } from '@/lib/utils'
import { useLocale } from '@/providers/LocaleProvider'
import { GoBackButton } from '@/shared/components'
import { CloudDownload } from '@/shared/icons/outline'
import {
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormField,
  FormItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { createBulkPayment, getCSVFiles } from '../../api'
import { UploadCSVFileField } from '../UploadCSVFileField'

const UPLOAD_FORM_ID = 'upload-form'

type Props = {
  onContinue: () => void
}

export const BulkPaymentUploadCSV = ({ onContinue }: Props) => {
  const { locale } = useLocale()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const intl = useIntl()

  const existingBulkPaymentId = searchParams.get('id')

  const bulkPaymentQuery = useQuery({
    queryKey: [queryKeys.createBulkPayment],
    queryFn: createBulkPayment,
    select: (data) => data.data,
    enabled: !existingBulkPaymentId,
    staleTime: 0,
  })

  const csvFilesQuery = useQuery({
    queryKey: [queryKeys.getCSVFiles, existingBulkPaymentId],
    queryFn: () => getCSVFiles({ id: existingBulkPaymentId ?? '' }),
    select: (data) => data.data,
    enabled: !!existingBulkPaymentId,
    staleTime: 0,
  })

  useEffect(() => {
    if (bulkPaymentQuery.data?.id) {
      setSearchParams({ id: bulkPaymentQuery.data.id })
    }
  }, [bulkPaymentQuery.data?.id, setSearchParams])

  const form = useForm({
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<Record<string, File[]>> = async () => {
    onContinue()
  }

  const files = useMemo(() => {
    return csvFilesQuery?.data?.map((file) => file.fileName) ?? []
  }, [csvFilesQuery.data])

  useKeyPress('Enter', onContinue)

  return (
    <>
      <GoBackButton to={location.state?.from ?? BusinessRoute.Recipients} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Upload bulk payment file"
            id="bulkPayment.uploadFile.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="bulkPayment.uploadFile.subtitle"
            defaultMessage="Download our bulk payment template, and upload a completed version"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-wrap items-center justify-between gap-4 md:flex-nowrap md:gap-0">
          <div className="flex flex-col flex-wrap  gap-2">
            <Typography bold>
              <FormattedMessage
                defaultMessage="Download the bulk payments CSV template"
                id="bulkPayment.uploadFile.downloadBulkTemplate"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="bulkPayment.uploadFile.downloadGuide"
                defaultMessage="Our guide helps you fill it in, find it {here}"
                values={{
                  here: (
                    <a
                      href={guideUrlByLocale[locale]}
                      download
                      target="_blank"
                      rel="noreferrer noopener"
                      className={cn(
                        buttonVariants({
                          variant: 'link',
                          size: 'inline',
                        }),
                      )}
                    >
                      <FormattedMessage
                        defaultMessage="here"
                        id="action.lowercase.here"
                      />
                    </a>
                  ),
                }}
              />
            </Typography>
          </div>

          <Button
            size="md"
            leftIcon={<CloudDownload className="size-5" />}
            type="button"
            variant="tertiary"
            asChild
          >
            <a
              href={templateUrlByLocale[locale]}
              download
              target="_blank"
              rel="noreferrer noopener"
            >
              <FormattedMessage
                defaultMessage="Download template"
                id="action.downloadTemplate"
              />
            </a>
          </Button>
        </div>

        <div className="p-4" />

        <Form {...form}>
          <form
            id={UPLOAD_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="csvFiles"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <UploadCSVFileField
                      isLoadingFiles={
                        csvFilesQuery.isLoading || bulkPaymentQuery.isLoading
                      }
                      files={files}
                      bulkPaymentId={existingBulkPaymentId}
                      title={intl.formatMessage({
                        defaultMessage: 'I have a bulk payment file',
                        id: 'bulkPayment.uploadFile.field.title',
                      })}
                      description={intl.formatMessage({
                        defaultMessage:
                          'Upload a completed version of our bulk payment CSV template',
                        id: 'bulkPayment.uploadFile.field.subtitle',
                      })}
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={files.length === 0}
            form={UPLOAD_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
