import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { parseFullPhoneNumber } from '@/lib/phone'
import { Button, Form, StickyContainer } from '@/shared/ui'

import { BusinessUSRecipient, SharedRecipientFormProps } from '../types'

import { BusinessFields } from './BusinessFields'
import { ContactDetailsFields } from './ContactDetailsFields'
import { DeleteRecipientAction } from './DeleteRecipientAction'
import { USPaymentFields } from './USPaymentFields'
import { businessValidation, paymentValidation } from './validations'

const createUSBusinessSchema = businessValidation.and(paymentValidation)

const US_BUSINESS_FORM = 'us-business-form'

export type CreateUSBusinessSchema = z.infer<typeof createUSBusinessSchema>

type Props = {
  onContinue: (values: CreateUSBusinessSchema) => void
  recipient?: BusinessUSRecipient
} & SharedRecipientFormProps

export const USBusinessForm = ({
  country,
  layout,
  onDelete,
  onContinue,
  isPending,
  recipient,
  recipientId,
}: Props) => {
  const form = useForm<CreateUSBusinessSchema>({
    mode: 'onChange',
    resolver: zodResolver(createUSBusinessSchema),
    values: {
      nickname: recipient?.nickname ?? '',
      legalName: recipient?.recipient.details.legalName ?? '',
      email: recipient?.contactDetails?.email ?? '',
      localPhoneNumber:
        recipient?.contactDetails?.internationalPhonePrefix &&
        recipient?.contactDetails?.localPhoneNumber
          ? recipient.contactDetails.internationalPhonePrefix +
            recipient.contactDetails.localPhoneNumber
          : '',
      internationalPhonePrefix:
        recipient?.contactDetails?.internationalPhonePrefix ??
        getCodeByCountry(country),
      paymentMethod:
        recipient?.paymentInformation.railsDetails.paymentMethod ?? '',
      accountNumber:
        recipient?.paymentInformation.railsDetails.accountNumber ?? '',
      routingNumber:
        recipient?.paymentInformation.railsDetails.routingNumber ?? '',

      address: {
        street: recipient?.recipient.address?.street ?? '',
        city: recipient?.recipient.address?.city ?? '',
        state: recipient?.recipient.address?.state ?? '',
        postcode: recipient?.recipient.address?.postcode ?? '',
        countryCode: recipient?.recipient.address?.countryCode ?? '',
      },
    },
  })

  const onSubmit: SubmitHandler<CreateUSBusinessSchema> = (values) => {
    onContinue({
      ...values,
      localPhoneNumber: parseFullPhoneNumber(values),
    })
  }

  return (
    <>
      <Form {...form}>
        <form
          id={US_BUSINESS_FORM}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-8">
            <BusinessFields layout={layout} form={form} />

            <ContactDetailsFields form={form} />

            <USPaymentFields layout={layout} form={form} />
          </div>
        </form>
      </Form>

      <StickyContainer
        className={layout === 'edit' ? '-bottom-16 pb-0' : undefined}
      >
        <Button
          width="full"
          form={US_BUSINESS_FORM}
          disabled={
            !form.formState.isValid ||
            isPending ||
            (layout === 'edit' && !form.formState.isDirty)
          }
          loading={isPending}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          {layout === 'edit' ? (
            <FormattedMessage
              defaultMessage="Save changes"
              id="action.saveChanges"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          )}
        </Button>

        {onDelete ? (
          <DeleteRecipientAction
            onDelete={onDelete}
            recipient={recipient}
            recipientId={recipientId}
          />
        ) : null}
      </StickyContainer>
    </>
  )
}
