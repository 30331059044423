import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { useKeyPress } from '@/hooks/useKeyPress'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  MotionDiv,
  SlideInScreen,
  StickyContainer,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@/shared/ui'

import { getOffCycleDetails } from '../../../api'
import { ManualCSVContractorsList } from '../ManualCSVContractorsList'

type Props = {
  onBack: () => void
  onContinue: () => void
}

export const UploadManualFixedContractors = ({ onBack, onContinue }: Props) => {
  const contractorsQuery = useQuery({
    queryKey: [queryKeys.getOffCycleDetails],
    queryFn: getOffCycleDetails,
    select: (data) => data?.data,
  })

  useKeyPress('Enter', () => onContinue())

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review fixed-rate payments"
            id="contractors.uploadManualCSV.reviewFixedRate.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="contractors.uploadManualCSV.reviewFixedRate.description"
            defaultMessage="Make sure fixed-rate contractors payment amounts are correct"
          />
        </Typography>

        <div className="p-6" />

        <Tabs defaultValue="valid">
          <TabsList variant="outline">
            <TabsTrigger size="fit" variant="outline" value="valid">
              <FormattedMessage
                id="bulkPayments.tabs.valid"
                defaultMessage="Ready to import • {count}"
                values={{
                  count: contractorsQuery.data?.fixedRatePayments.length ?? 0,
                }}
              />
            </TabsTrigger>
            <TabsTrigger disabled size="fit" variant="outline" value="invalid">
              <FormattedMessage
                id="bulkPayments.tabs.invalid"
                defaultMessage="Failed to import • {count}"
                values={{ count: 0 }}
              />
            </TabsTrigger>
          </TabsList>

          <TabsContent value="valid">
            <MotionDiv
              className="pt-6"
              key={contractorsQuery.data?.fixedRatePayments.length ?? 0}
            >
              {contractorsQuery.isPending ? (
                <ManualCSVContractorsList.Skeleton />
              ) : (
                <ManualCSVContractorsList
                  contractors={contractorsQuery.data?.fixedRatePayments ?? []}
                />
              )}
            </MotionDiv>
          </TabsContent>
          <TabsContent value="invalid">
            <MotionDiv
              className="pt-6"
              key={contractorsQuery.data?.fixedRatePayments.length}
            >
              {contractorsQuery.isPending ? (
                <ManualCSVContractorsList.Skeleton />
              ) : (
                <ManualCSVContractorsList
                  contractors={contractorsQuery.data?.fixedRatePayments ?? []}
                />
              )}
            </MotionDiv>
          </TabsContent>
        </Tabs>

        <StickyContainer>
          <Button onClick={() => onContinue()} width="full">
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
