import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { useKeyPress } from '@/hooks/useKeyPress'
import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { GoBackButton } from '@/shared/components'
import { CloudDownload } from '@/shared/icons/outline'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { ManualCSVFileField } from '../ManualCSVFileField'

const UPLOAD_MANUAL_FORM_ID = 'upload-manual-form-id'

const csvSchema = z.object({
  csvFiles: z.array(z.instanceof(File)),
})

type CSVSchema = z.infer<typeof csvSchema>

type Props = {
  onContinue: () => void
}

export const UploadManualCSVUpload = ({ onContinue }: Props) => {
  const intl = useIntl()

  const form = useForm<CSVSchema>({
    mode: 'onChange',
    defaultValues: {
      csvFiles: [],
    },
  })

  const onSubmit: SubmitHandler<Record<string, File[]>> = async () => {
    onContinue()
  }

  useKeyPress('Enter', onContinue)

  const hasAnyDocuments = form.watch('csvFiles').length > 0

  return (
    <>
      <GoBackButton to={BusinessRoute.ContractorsOverview} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Manual CSV upload"
            id="contractors.uploadManualCSV.title"
          />
        </Typography>
        <div className="p-2" />
        <Typography className="text-center">
          {intersperse(
            [
              intersperse(
                [
                  formatDate('2025-04-01', 'MMM dd'),
                  formatDate('2025-04-15', 'MMM dd'),
                ],
                ' - ',
              ),
              intl.formatMessage({
                defaultMessage: 'Fixed-rate and Pay-as-you-go contractors',
                id: 'contractors.uploadManualCSV.subtitle',
              }),
            ],
            ' • ',
          )}
        </Typography>
        <div className="p-6" />
        <div className="flex flex-wrap items-center justify-between gap-4 md:flex-nowrap md:gap-0">
          <div className="flex flex-col flex-wrap  gap-2">
            <Typography bold>
              <FormattedMessage
                defaultMessage="Download pre-filled CSV"
                id="contractors.uploadManualCSV.downloadBulkTemplate"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="contractors.uploadManualCSV.exportInfo"
                defaultMessage="Export contractors information in a CSV file and update any payment or add adjustments for them"
              />
            </Typography>
          </div>

          <Button
            size="md"
            leftIcon={<CloudDownload className="size-5" />}
            type="button"
            variant="tertiary"
          >
            <FormattedMessage
              defaultMessage="Export contractors"
              id="action.exportContractors"
            />
          </Button>
        </div>
        <div className="p-4" />
        <Form {...form}>
          <form
            id={UPLOAD_MANUAL_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="csvFiles"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ManualCSVFileField
                      name={field.name}
                      files={field.value}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
        <StickyContainer>
          <Button
            width="full"
            disabled={!hasAnyDocuments}
            form={UPLOAD_MANUAL_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
