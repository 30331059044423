import { iconByRecipientType, PaymentRails } from '@/domains/Business/constants'
import { getFullName } from '@/lib/typography'
import { CountryCode } from '@/types/country'

import {
  ContractorEntityType,
  CreatedRecipient,
  CreatedRecipientWithId,
  DolarAppEntityType,
  EntityType,
  Recipient,
} from './../types'

export function getRecipientName(recipient?: Recipient) {
  if (!recipient) {
    return undefined
  }

  if (
    recipient.entityType === EntityType.INDIVIDUAL &&
    recipient.firstName &&
    recipient.lastName
  ) {
    return getFullName(recipient)
  }

  if (recipient.email) {
    return recipient.email
  }

  return undefined
}

export function getRecipientTitle(recipient?: Recipient) {
  if (!recipient) {
    return undefined
  }

  switch (recipient.entityType) {
    case EntityType.INDIVIDUAL:
      return getRecipientName(recipient)

    case EntityType.BUSINESS:
      return recipient.legalName
        ? recipient.legalName
        : getRecipientName(recipient)

    default:
      return undefined
  }
}

export function getEntityType(
  entityType?: EntityType | DolarAppEntityType | ContractorEntityType,
) {
  if (entityType === ContractorEntityType.CONTRACTOR) {
    return EntityType.INDIVIDUAL
  }

  return entityType
}

export function getIconByEntityType(
  entityType?: EntityType | DolarAppEntityType | ContractorEntityType,
) {
  if (!entityType || entityType === ContractorEntityType.CONTRACTOR) {
    return iconByRecipientType[EntityType.INDIVIDUAL]
  }

  if (entityType === DolarAppEntityType.RETAIL) {
    return iconByRecipientType[EntityType.INDIVIDUAL]
  }

  return iconByRecipientType[entityType]
}

export function getCountryByRails(rails: PaymentRails) {
  switch (rails) {
    case PaymentRails.MEX:
      return CountryCode.MX

    case PaymentRails.USA:
      return CountryCode.US

    default:
      return CountryCode.MX
  }
}

export function parseRecipient(
  recipient: Recipient,
): CreatedRecipient | undefined {
  switch (recipient.paymentRails) {
    case PaymentRails.MEX: {
      switch (recipient.entityType) {
        case EntityType.INDIVIDUAL:
          return {
            nickname: recipient.nickname,
            recipient: {
              type: recipient.entityType,
              details: {
                firstName: recipient.firstName ?? '',
                lastName: recipient.lastName ?? '',
                secondLastName: recipient.secondLastName ?? '',
              },
            },
            contactDetails: {
              internationalPhonePrefix:
                recipient.internationalPhonePrefix ?? '',
              localPhoneNumber: recipient.localPhoneNumber ?? '',
              email: recipient.email ?? '',
            },
            paymentInformation: {
              rails: recipient.paymentRails,
              railsDetails: {
                clabe: recipient.localInformation.clabe,
              },
            },
          }

        case EntityType.BUSINESS:
          return {
            nickname: recipient.nickname,
            recipient: {
              type: recipient.entityType,
              details: {
                legalName: recipient.legalName ?? '',
              },
            },
            contactDetails: {
              internationalPhonePrefix:
                recipient.internationalPhonePrefix ?? '',
              localPhoneNumber: recipient.localPhoneNumber ?? '',
              email: recipient.email ?? '',
            },
            paymentInformation: {
              rails: recipient.paymentRails,
              railsDetails: {
                clabe: recipient.localInformation.clabe,
              },
            },
          }

        default:
          return undefined
      }
    }

    case PaymentRails.USA: {
      switch (recipient.entityType) {
        case EntityType.INDIVIDUAL:
          return {
            nickname: recipient.nickname,
            recipient: {
              type: recipient.entityType,
              details: {
                firstName: recipient.firstName ?? '',
                lastName: recipient.lastName ?? '',
                secondLastName: recipient.secondLastName ?? '',
              },
              address: {
                street: recipient.addressStreet ?? '',
                city: recipient.addressCity ?? '',
                postcode: recipient.addressZipCode ?? '',
                state: recipient.addressState ?? '',
                countryCode: getCountryByRails(recipient.paymentRails),
              },
            },
            contactDetails: {
              internationalPhonePrefix:
                recipient.internationalPhonePrefix ?? '',
              localPhoneNumber: recipient.localPhoneNumber ?? '',
              email: recipient.email ?? '',
            },
            paymentInformation: {
              rails: recipient.paymentRails,
              railsDetails: {
                accountNumber: recipient.localInformation.accountNumber,
                routingNumber: recipient.localInformation.routingNumber,
                paymentMethod: recipient.localInformation.paymentMethod,
              },
            },
          }

        case EntityType.BUSINESS:
          return {
            nickname: recipient.nickname,
            recipient: {
              type: recipient.entityType,
              details: {
                legalName: recipient.legalName ?? '',
              },
              address: {
                street: recipient.addressStreet ?? '',
                city: recipient.addressCity ?? '',
                postcode: recipient.addressZipCode ?? '',
                state: recipient.addressState ?? '',
                countryCode: getCountryByRails(recipient.paymentRails),
              },
            },
            contactDetails: {
              internationalPhonePrefix:
                recipient.internationalPhonePrefix ?? '',
              localPhoneNumber: recipient.localPhoneNumber ?? '',
              email: recipient.email ?? '',
            },
            paymentInformation: {
              rails: recipient.paymentRails,
              railsDetails: {
                accountNumber: recipient.localInformation.accountNumber,
                routingNumber: recipient.localInformation.routingNumber,
                paymentMethod: recipient.localInformation.paymentMethod,
              },
            },
          }

        default:
          return undefined
      }
    }

    default:
      return undefined
  }
}

export function parseFormRecipient(
  recipient: CreatedRecipientWithId,
): Partial<Recipient> & { id: string } {
  return {
    id: recipient.id,
    nickname: recipient.nickname,
    internationalPhonePrefix:
      recipient.contactDetails?.internationalPhonePrefix ?? null,
    localPhoneNumber: recipient.contactDetails?.localPhoneNumber ?? null,
    email: recipient.contactDetails?.email ?? null,
    addressCity: recipient.recipient?.address?.city ?? null,
    addressCountry: recipient.recipient?.address?.countryCode ?? null,
    addressState: recipient.recipient?.address?.state ?? null,
    addressStreet: recipient.recipient?.address?.street ?? null,
    addressZipCode: recipient.recipient?.address?.postcode ?? null,
  }
}
