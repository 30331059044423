import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import {
  UploadManualCSVReview,
  UploadManualCSVUpload,
  UploadManualFixedContractors,
  UploadManualPayAsYouGoContractors,
} from '../components'

enum Step {
  Upload = 'upload',
  SelectFixedRate = 'selectFixedRate',
  SelectPayAsYouGo = 'selectPayAsYouGo',
  Review = 'review',
}

type State =
  | { type: Step.Upload }
  | { type: Step.SelectFixedRate }
  | { type: Step.SelectPayAsYouGo }
  | { type: Step.Review }

export const UploadManualCSV = () => {
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<State>({ type: Step.Upload })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Upload:
        return 25
      case Step.SelectFixedRate:
        return 50
      case Step.SelectPayAsYouGo:
        return 75
      case Step.Review:
        return 100
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Contractors}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Upload && (
          <UploadManualCSVUpload
            onContinue={() => setActiveStep({ type: Step.SelectFixedRate })}
          />
        )}

        {activeStep.type === Step.SelectFixedRate && (
          <UploadManualFixedContractors
            onBack={() => setActiveStep({ type: Step.Upload })}
            onContinue={() => setActiveStep({ type: Step.SelectPayAsYouGo })}
          />
        )}

        {activeStep.type === Step.SelectPayAsYouGo && (
          <UploadManualPayAsYouGoContractors
            onBack={() => setActiveStep({ type: Step.SelectFixedRate })}
            onContinue={() => setActiveStep({ type: Step.Review })}
          />
        )}

        {activeStep.type === Step.Review && (
          <UploadManualCSVReview
            onBack={() => setActiveStep({ type: Step.SelectPayAsYouGo })}
          />
        )}
      </AnimatePresence>
    </FullScreen>
  )
}
