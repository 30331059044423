import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { parse } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import {
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  DateBadge,
} from '@/domains/Business/components'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getImage } from '@/lib/images'
import { intersperse } from '@/lib/typography'
import { downloadFile, getAnimationKey } from '@/lib/utils'
import { CloudDownload, InfoCircle } from '@/shared/icons/outline'
import { Invoice } from '@/shared/icons/solid'
import {
  Button,
  Card,
  MotionDiv,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { downloadAccountStatements, getAccountStatements } from '../api'
import { AccountStatement, StatementType, Wallet } from '../types'

import { StatementsDateFilter } from './StatementsDateFilter'

type Props = {
  account?: Wallet
  setScreen: (screen: 'credit-cycle' | 'statements') => void
  type: string | null
}

export const StatementsBaseScreen = ({ account, setScreen, type }: Props) => {
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'statements'),
  )

  const defaultType = type ?? StatementType.ACCOUNT

  const statementsQuery = useQuery({
    queryKey: [queryKeys.getAccountStatements, account?.id, params],
    queryFn: () =>
      getAccountStatements({ walletId: account?.id ?? '', params }),
    select: (data) =>
      data.data
        .filter((statement) => statement.type === defaultType)
        .sort((a, b) => b.startDate.localeCompare(a.startDate)),
    enabled: !!account?.id,
  })

  const notifyError = useErrorToast()

  const intl = useIntl()

  const { mutateAsync, isPending, variables } = useMutation({
    mutationFn: downloadAccountStatements,
  })

  const downloadStatement = async (id: string) => {
    try {
      const response = await mutateAsync({
        id,
      })

      const title =
        type === StatementType.CREDIT
          ? intl.formatMessage({
              id: 'account.credit.statement',
              defaultMessage: 'credit-statement.pdf',
            })
          : intl.formatMessage({
              id: 'account.account.statement',
              defaultMessage: 'account-statement.pdf',
            })

      downloadFile(response.data, title)
    } catch (error) {
      notifyError(error)
    }
  }

  const groupedByYear = useMemo(() => {
    return (statementsQuery.data ?? []).reduce(
      (acc, curr) => {
        const year = parse(
          curr.startDate,
          'yyyy-MM-dd',
          new Date(),
        ).getFullYear()

        if (!acc[year]) {
          acc[year] = []
        }

        acc[year].push(curr)

        return acc
      },
      {} as Record<string, AccountStatement[]>,
    )
  }, [statementsQuery.data])

  const sortedByYear = Object.entries(groupedByYear).sort(([aYear], [bYear]) =>
    bYear.localeCompare(aYear),
  )

  const hasActiveFilter =
    !!searchParams.get(DATE_FROM_FILTER_NAME) ||
    !!searchParams.get(DATE_TO_FILTER_NAME)

  return (
    <SlideInLeft className="flex flex-col gap-8">
      <div className="flex justify-between">
        <Typography variant="h3">
          {type === 'CREDIT' ? (
            <FormattedMessage
              id="label.creditStatements"
              defaultMessage="Credit statements"
            />
          ) : (
            <FormattedMessage
              id="label.accountStatements"
              defaultMessage="Account statements"
            />
          )}
        </Typography>

        <div className="flex gap-3">
          <StatementsDateFilter />

          {type === 'CREDIT' && (
            <Button
              onClick={() => setScreen('credit-cycle')}
              variant="tertiary"
              size="icon"
              leftIcon={<InfoCircle className="size-4" />}
              aria-label={intl.formatMessage({
                id: 'accounts.statements.creditCycle.showInfo',
                defaultMessage: 'Show credit cycle information',
              })}
            />
          )}
        </div>
      </div>

      {hasActiveFilter ? (
        <MotionDiv>
          <div className="flex w-full flex-wrap gap-3">
            <DateBadge />
          </div>
        </MotionDiv>
      ) : null}

      <MotionDiv
        key={getAnimationKey(statementsQuery.isPending)}
        className="flex flex-col gap-6"
      >
        {statementsQuery.isPending ? (
          <div className="flex flex-col gap-4">
            <Skeleton className="h-[21px] w-24" />

            <Card size="widget" className="flex items-center justify-between">
              <Skeleton className="h-[33px] w-36" />

              <Skeleton className="size-6" />
            </Card>
          </div>
        ) : sortedByYear.length === 0 ? (
          <Card
            className="flex w-full flex-wrap items-center justify-between gap-3 md:flex-nowrap md:gap-0"
            size="widget"
          >
            <div className="flex flex-wrap items-center gap-8">
              <img
                className="w-12 object-contain"
                src={getImage({ name: 'empty-search' })}
                alt=""
                aria-hidden
              />
              <div className="flex flex-col gap-1">
                <Typography bold>
                  <FormattedMessage
                    id="account.statements.empty"
                    defaultMessage="You don't have any statements yet!"
                  />
                </Typography>
                <Typography className="text-neutral-gray-600">
                  <FormattedMessage
                    id="account.statements.empty.subtitle"
                    defaultMessage="Statements will be generated monthly"
                  />
                </Typography>
              </div>
            </div>
          </Card>
        ) : (
          sortedByYear.map(([year, statements]) => (
            <div className="flex flex-col" key={year}>
              <Typography className="px-2" bold>
                {year}
              </Typography>

              <div className="p-1" />

              <Card size="medium" className="flex flex-col gap-6">
                {statements.map((statement) => (
                  <div
                    className="flex items-center justify-between"
                    key={statement.id}
                  >
                    <div className="flex gap-2">
                      <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
                        <Invoice className="size-5 text-neutral-gray-900" />
                      </div>

                      <div className="flex flex-col">
                        <Typography bold>
                          {intersperse(
                            [
                              statement.type === 'CREDIT' ? (
                                <FormattedMessage
                                  id="label.creditStatement"
                                  defaultMessage="Credit Statement"
                                  key="creditStatement"
                                />
                              ) : (
                                <FormattedMessage
                                  id="label.accountStatement"
                                  defaultMessage="Account Statement"
                                  key="accountStatement"
                                />
                              ),
                              intl.formatDate(statement.startDate, {
                                month: 'long',
                                year: 'numeric',
                                timeZone: 'UTC',
                              }),
                            ],
                            ' | ',
                          )}
                        </Typography>
                        <Typography
                          variant="body-small"
                          className="text-neutral-gray-600"
                        >
                          <FormattedMessage
                            id="statements.period"
                            defaultMessage="{periodFrom} to {periodTo} ({days} days)"
                            values={{
                              periodFrom: intl.formatDate(statement.startDate, {
                                month: 'long',
                                day: 'numeric',
                                timeZone: 'UTC',
                              }),

                              periodTo: intl.formatDate(statement.endDate, {
                                month: 'long',
                                day: 'numeric',
                                timeZone: 'UTC',
                              }),
                              days: intl.formatDate(statement.endDate, {
                                day: 'numeric',
                                timeZone: 'UTC',
                              }),
                            }}
                          />
                        </Typography>
                      </div>
                    </div>

                    <Button
                      size="inline"
                      variant="ghost"
                      loaderPosition="left"
                      className="group text-neutral-gray-600 transition-colors"
                      loading={isPending && variables.id === statement.id}
                      disabled={isPending && variables.id === statement.id}
                      onClick={() => downloadStatement(statement.id)}
                      leftIcon={<CloudDownload className="size-5" />}
                    />
                  </div>
                ))}
              </Card>
            </div>
          ))
        )}
      </MotionDiv>

      <div className="p-6" />
    </SlideInLeft>
  )
}
