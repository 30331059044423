import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { useReset } from '@/hooks/useReset'
import { isAPIError } from '@/lib/error'

import { getCurrentContractor } from '../api'

export const ContractorActivityChecker = () => {
  const reset = useReset()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getCurrentContractor],
    queryFn: getCurrentContractor,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (
      contractorQuery.error &&
      isAPIError(contractorQuery.error) &&
      contractorQuery.error.response?.status === 401
    ) {
      reset()
    }
  }, [contractorQuery.error, reset])

  return <Outlet />
}
