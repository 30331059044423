import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { Widget } from '@/shared/components'
import { Card, MotionDiv } from '@/shared/ui'

import { getContractorDocuments } from '../api'

import { UploadedContractDocument } from './UploadedContractDocument'

type Props = {
  contractId?: string
}

export const ContractorDocuments = ({ contractId }: Props) => {
  const contractDocumentsQuery = useQuery({
    queryKey: [queryKeys.getContractorDocuments, contractId],
    queryFn: () => getContractorDocuments({ id: contractId ?? '' }),
    select: (data) => data.data,
    enabled: !!contractId,
  })

  if (!contractDocumentsQuery.data?.length) return null

  return (
    <MotionDiv>
      <Widget
        variant="form"
        title={
          <FormattedMessage id="label.documents" defaultMessage="Documents" />
        }
      >
        <Card size="upload">
          <div className="flex w-full gap-6">
            <div className="flex w-full flex-col justify-center gap-2">
              {contractDocumentsQuery.data.map((file) => (
                <UploadedContractDocument
                  key={file.id}
                  contractId={contractId}
                  file={file}
                />
              ))}
            </div>
          </div>
        </Card>
      </Widget>
    </MotionDiv>
  )
}
