import { useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getFXRates } from '@/domains/Business/api'
import { AccountSelect } from '@/domains/Business/components'
import { useCheckUserPaymentLimit, useWallets } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatAmount, formatCurrency, formatRate } from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { intersperse } from '@/lib/typography'
import { getAnimationKey, removeEmptyFormFields } from '@/lib/utils'
import {
  FreeLabel,
  GoBackButton,
  OptionalTag,
  Widget,
} from '@/shared/components'
import { InfoCircle } from '@/shared/icons/outline'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AnimatedFormLabel,
  Button,
  Card,
  Checkbox,
  Details,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  MotionDiv,
  MotionSpan,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@/shared/ui'

import {
  getCSVTransactions,
  quoteBulkPayment,
  scheduleBulkPayment,
} from '../../api'
import { CSVTransaction } from '../../types'
import { BulkRecipientRow } from '../BulkRecipientRow'

const REVIEW_BULK_PAYMENT_FORM_ID = 'review-transactions-form-id'

const bulkPaymentReviewSchema = z.object({
  fromBalance: z.string(),
  walletId: z.string().min(1),
  label: z.string().optional().or(z.literal('')),
  shouldNotifyRecipients: z.boolean(),
})

type BulkPaymentReviewSchema = z.infer<typeof bulkPaymentReviewSchema>

type Props = {
  onBack: () => void
  selectedIdx: string[]
}

export const BulkPaymentReview = ({ onBack, selectedIdx }: Props) => {
  const isOverLimit = useCheckUserPaymentLimit()
  const [searchParams] = useSearchParams()
  const notifyError = useErrorToast()
  const navigate = useNavigate()
  const intl = useIntl()

  const { wallets, isPending: walletsPending } = useWallets()

  const form = useForm<BulkPaymentReviewSchema>({
    mode: 'onChange',
    resolver: zodResolver(bulkPaymentReviewSchema),
    values: {
      fromBalance: wallets
        ? Big(wallets[0].balance).toFixed()
        : Big(0).toFixed(),
      walletId: wallets ? wallets[0].id : '',
      label: '',
      shouldNotifyRecipients: true,
    },
  })

  const {
    mutateAsync: schedulePayment,
    isPending: isSchedulePending,
    isSuccess: isScheduleSuccess,
  } = useMutation({
    mutationFn: scheduleBulkPayment,
  })

  const bulkPaymentId = searchParams.get('id')

  const parsedBulkPaymentQuery = useQuery({
    queryKey: [queryKeys.getCSVTransactions, bulkPaymentId],
    queryFn: () => getCSVTransactions({ id: bulkPaymentId ?? '' }),
    select: (data) =>
      data.data.validRows.filter((row) => {
        return selectedIdx.includes(row.id)
      }),
    staleTime: 0,
    enabled: !!bulkPaymentId,
  })

  const walletId = form.watch('walletId')

  const quoteBulkPaymentQuery = useQuery({
    queryKey: [
      queryKeys.quoteBulkPayment,
      bulkPaymentId,
      walletId,
      selectedIdx,
    ],
    queryFn: () =>
      quoteBulkPayment({
        bulkPaymentId: bulkPaymentId ?? '',
        data: {
          walletId,
          selectedPayments: selectedIdx,
        },
      }),
    select: (data) => data.data,
    enabled: !!(
      !!bulkPaymentId &&
      walletId &&
      walletId !== '' &&
      !!selectedIdx
    ),
  })

  const [USD_Transactions, MXN_Transactions, USDC_Transactions] =
    useMemo(() => {
      const USD_Transactions: CSVTransaction[] = []
      const MXN_Transactions: CSVTransaction[] = []
      const USDC_Transactions: CSVTransaction[] = []

      parsedBulkPaymentQuery.data?.forEach((row) => {
        switch (row.paymentCurrency) {
          case Currency.USD:
            USD_Transactions.push(row)
            break
          case Currency.MXN:
            MXN_Transactions.push(row)
            break
          case Currency.USDC:
            USDC_Transactions.push(row)
            break
        }
      })

      return [USD_Transactions, MXN_Transactions, USDC_Transactions]
    }, [parsedBulkPaymentQuery.data])

  const fxRateQuery = useQuery({
    queryKey: [queryKeys.getFXRates, MXN_Transactions.length],
    queryFn: () =>
      getFXRates({
        from: Currency.USDC,
        to: Currency.MXN,
      }),
    select: (data) => data.data,
    refetchInterval: 10 * 1000,
    enabled: MXN_Transactions.length > 0,
  })

  const USD_Total = useMemo(() => {
    return USD_Transactions.reduce((acc, row) => {
      return acc.add(row.baseAmount)
    }, Big(0)).toFixed()
  }, [USD_Transactions])

  const MXN_Total = useMemo(() => {
    return MXN_Transactions.reduce((acc, row) => {
      return acc.add(row.baseAmount)
    }, Big(0)).toFixed()
  }, [MXN_Transactions])

  const USDC_Total = useMemo(() => {
    return USDC_Transactions.reduce((acc, row) => {
      return acc.add(row.baseAmount)
    }, Big(0)).toFixed()
  }, [USDC_Transactions])

  const totalAmount = useMemo(() => {
    return (
      parsedBulkPaymentQuery.data?.reduce((acc, row) => {
        return acc.add(row.baseAmount)
      }, Big(0)) ?? Big(0)
    ).toString()
  }, [parsedBulkPaymentQuery.data])

  const onSubmit = useCallback(
    async (data: BulkPaymentReviewSchema) => {
      removeEmptyFormFields(data)

      const { fromBalance, ...rest } = data

      try {
        if (!bulkPaymentId) {
          toast.error('Bulk payment ID missing')

          return
        }

        await schedulePayment({
          ...rest,
          bulkPaymentId,
          selectedPayments: selectedIdx,
        })

        if (isOverLimit(totalAmount)) {
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getUserTasks],
          })

          toast.success(
            intl.formatMessage({
              id: 'bulkPayment.task.submitted',
              defaultMessage:
                'Bulk payment request submitted and pending approval',
            }),
          )

          navigate(BusinessRoute.Tasks)

          return
        }

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTransactions],
        })

        toast.success(
          intl.formatMessage({
            id: 'bulkPayment.sentSuccessfully',
            defaultMessage: 'Payment sent!',
          }),
        )

        navigate(BusinessRoute.Transactions)
      } catch (error) {
        notifyError(error)
      }
    },
    [
      bulkPaymentId,
      schedulePayment,
      selectedIdx,
      isOverLimit,
      totalAmount,
      intl,
      navigate,
      notifyError,
    ],
  )

  const notEnoughBalance = Big(form.watch('fromBalance')).lt(totalAmount)

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review your payment"
            id="bulkPayment.review.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="bulkPayment.review.subtitle"
            defaultMessage="Choose account, review and confirm your payment"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={REVIEW_BULK_PAYMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    label={
                      notEnoughBalance && !walletsPending
                        ? intl.formatMessage({
                            defaultMessage: 'Insufficient balance',
                            id: 'validation.balance.insufficient',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Paying from',
                            id: 'label.payingFrom',
                          })
                    }
                    showLabel
                    hasError={notEnoughBalance && !walletsPending}
                    onChange={(v) => {
                      field.onChange(v)

                      const selected = wallets?.find(
                        (wallet) => wallet.id === v,
                      )

                      if (selected) {
                        form.setValue(
                          'fromBalance',
                          Big(selected.balance).toFixed(),
                        )

                        form.trigger()
                      }
                    }}
                  />
                )
              }}
            />

            <div className="p-4" />

            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Payment summary"
                  id="label.paymentSummary"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Total recipients"
                    id="label.totalRecipients"
                  />
                </Details.Label>

                <Details.Value>{selectedIdx.length}</Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Total payment amount"
                    id="label.totalPaymentAmount"
                  />
                </Details.Label>

                <Details.Value>
                  {formatAmount({
                    amount: totalAmount,
                    currency: Currency.USDC,
                  })}
                </Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Your fee"
                    id="label.yourFee"
                  />
                </Details.Label>

                <MotionDiv
                  key={getAnimationKey(quoteBulkPaymentQuery.isPending)}
                  className="flex items-center gap-1"
                >
                  {quoteBulkPaymentQuery.isPending ? (
                    <Skeleton className="h-[21px] w-36" />
                  ) : (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          type="button"
                          size="inline"
                          className="size-4"
                          variant="ghost"
                        >
                          <InfoCircle className="size-4" />
                        </Button>
                      </TooltipTrigger>
                      <div>
                        <div className="flex gap-2 text-right">
                          {quoteBulkPaymentQuery.data?.feeBeforeDiscount
                            ?.amount &&
                          quoteBulkPaymentQuery.data?.feeBeforeDiscount
                            ?.amount > 0 ? (
                            <Typography className="line-through">
                              {formatAmount({
                                amount:
                                  quoteBulkPaymentQuery.data?.feeBeforeDiscount
                                    ?.amount ?? 0,
                                currency:
                                  quoteBulkPaymentQuery.data?.feeBeforeDiscount
                                    ?.currency ?? Currency.USDC,
                              })}
                            </Typography>
                          ) : null}
                          <FreeLabel />
                        </div>
                      </div>

                      <TooltipContent className="max-w-[300px]">
                        <Typography>
                          <FormattedMessage
                            id="bulkPayments.fee.tooltip"
                            defaultMessage="All fees are waived when sending to other DolarApp accounts"
                          />
                        </Typography>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </MotionDiv>
              </Details>
            </Widget>

            <div className="p-1" />

            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Payment label (e.g. March Payroll)',
                        id: 'bulkPayments.confirmScreen.paymentLabel',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Payment label (e.g. March Payroll)"
                      id="bulkPayments.confirmScreen.paymentLabel"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <div className="p-2" />

            <FormField
              control={form.control}
              name="shouldNotifyRecipients"
              render={({ field }) => (
                <FormItem className="flex items-center gap-3 px-2">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>

                  <FormLabel>
                    <Typography className="text-neutral-gray-800">
                      <FormattedMessage
                        defaultMessage="Notify recipients via email when this payment is sent"
                        id="bulkPayments.confirmScreen.notifyRecipient"
                      />
                    </Typography>
                  </FormLabel>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <div className="p-4" />

        <Widget
          variant="form"
          title={
            <FormattedMessage
              defaultMessage="Payment details"
              id="label.paymentDetails"
            />
          }
        >
          {USD_Transactions.length > 0 ? (
            <Accordion type="single" collapsible>
              <Card asChild size="upload">
                <AccordionItem value="fixed-rate">
                  <AccordionTrigger>
                    <div className="flex flex-col">
                      <Typography>
                        <FormattedMessage
                          defaultMessage="{currency} transfers"
                          id="bulkPayments.review.currencyTransfers"
                          values={{ currency: Currency.USD }}
                        />
                      </Typography>
                      <Typography
                        variant="body-small"
                        className="text-neutral-gray-600"
                      >
                        <FormattedMessage
                          id="label.recipientsCount"
                          defaultMessage="{count, plural, one {# recipient} other {# recipients}}"
                          values={{
                            count: USD_Transactions.length,
                          }}
                        />
                      </Typography>
                    </div>

                    <div className="ml-auto mr-2">
                      <div className="flex flex-col">
                        <Typography text="right">
                          {formatAmount({
                            amount: USD_Total,
                            currency: formatCurrency(Currency.USDC),
                          })}
                        </Typography>
                        <Typography
                          variant="body-small"
                          text="right"
                          className="text-neutral-gray-600"
                        >
                          {intersperse(
                            [
                              `1 ${formatCurrency(Currency.USDC)}`,
                              `${1} ${formatCurrency(Currency.USD)}`,
                            ],
                            ' = ',
                          )}
                        </Typography>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <BulkRecipientRow transactions={USD_Transactions} />
                  </AccordionContent>
                </AccordionItem>
              </Card>
            </Accordion>
          ) : null}

          {MXN_Transactions.length > 0 ? (
            <Accordion type="single" collapsible>
              <Card asChild size="upload">
                <AccordionItem value="fixed-rate">
                  <AccordionTrigger>
                    <div className="flex flex-col">
                      <Typography>
                        <FormattedMessage
                          defaultMessage="{currency} transfers"
                          id="bulkPayments.review.currencyTransfers"
                          values={{ currency: Currency.MXN }}
                        />
                      </Typography>
                      <Typography
                        variant="body-small"
                        className="text-neutral-gray-600"
                      >
                        <FormattedMessage
                          id="label.recipientsCount"
                          defaultMessage="{count, plural, one {# recipient} other {# recipients}}"
                          values={{
                            count: MXN_Transactions.length,
                          }}
                        />
                      </Typography>
                    </div>

                    <div className="ml-auto mr-2">
                      <div className="flex flex-col">
                        <Typography text="right">
                          {formatAmount({
                            amount: MXN_Total,
                            currency: Currency.USDC,
                          })}
                        </Typography>
                        <MotionSpan
                          key={getAnimationKey(fxRateQuery.isPending)}
                        >
                          {fxRateQuery.data?.bidRate ? (
                            <Typography
                              variant="body-small"
                              text="right"
                              className="text-neutral-gray-600"
                            >
                              {intersperse(
                                [
                                  `1 ${formatCurrency(Currency.USDC)}`,
                                  `${formatRate(fxRateQuery.data?.bidRate)} ${formatCurrency(Currency.MXN)}`,
                                ],
                                ' = ',
                              )}
                            </Typography>
                          ) : (
                            <Skeleton className="h-[12px] w-24" />
                          )}
                        </MotionSpan>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <BulkRecipientRow transactions={MXN_Transactions} />
                  </AccordionContent>
                </AccordionItem>
              </Card>
            </Accordion>
          ) : null}

          {USDC_Transactions.length > 0 ? (
            <Accordion type="single" collapsible>
              <Card asChild size="upload">
                <AccordionItem value="fixed-rate">
                  <AccordionTrigger>
                    <div className="flex flex-col">
                      <Typography>
                        <FormattedMessage
                          defaultMessage="{currency} transfers"
                          id="bulkPayments.review.currencyTransfers"
                          values={{ currency: formatCurrency(Currency.USDC) }}
                        />
                      </Typography>
                      <Typography
                        variant="body-small"
                        className="text-neutral-gray-600"
                      >
                        <FormattedMessage
                          id="label.recipientsCount"
                          defaultMessage="{count, plural, one {# recipient} other {# recipients}}"
                          values={{
                            count: USDC_Transactions.length,
                          }}
                        />
                      </Typography>
                    </div>

                    <div className="ml-auto mr-2">
                      <Typography text="right">
                        {formatAmount({
                          amount: USDC_Total,
                          currency: Currency.USDC,
                        })}
                      </Typography>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <BulkRecipientRow transactions={USDC_Transactions} />
                  </AccordionContent>
                </AccordionItem>
              </Card>
            </Accordion>
          ) : null}
        </Widget>

        <StickyContainer>
          <Button
            width="full"
            form={REVIEW_BULK_PAYMENT_FORM_ID}
            disabled={
              notEnoughBalance || isSchedulePending || isScheduleSuccess
            }
            onClick={form.handleSubmit(onSubmit)}
            loading={isSchedulePending || isScheduleSuccess}
            type="submit"
          >
            {isOverLimit(totalAmount) ? (
              <FormattedMessage
                defaultMessage="Send request for approval"
                id="action.sendRequestForApproval"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Send {amount}"
                id="contractor.runPayment.action.send"
                values={{
                  amount: formatAmount({
                    amount: totalAmount,
                    currency: Currency.USDC,
                  }),
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
