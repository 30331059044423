import { FormattedDate, FormattedMessage } from 'react-intl'
import { useIntercom } from 'react-use-intercom'

import { calculateProgress, formatMoney } from '@/lib/money'
import { Widget } from '@/shared/components'
import {
  Button,
  Progress,
  Sheet,
  SheetContent,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { PaymentLimit } from '../types'

export type ScreenType = 'base' | 'edit'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  limits: PaymentLimit
}

export const AccountLimitsSidebar = ({
  isOpen,
  onOpenChange,
  limits,
}: Props) => {
  const { showNewMessage } = useIntercom()

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex flex-col gap-8">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <Typography variant="h3">
                <FormattedMessage
                  id="accountLimits.title"
                  defaultMessage="Account limits"
                />
              </Typography>

              <Typography className="text-neutral-gray-600">
                <FormattedMessage
                  id="accountLimits.subtitle"
                  defaultMessage="Your limit will be set according to the source of funds that you provide"
                />
              </Typography>
            </div>
          </div>

          <Widget
            className="gap-1"
            title={
              <FormattedMessage
                id="accountLimits.details.depositLimit"
                defaultMessage="USDc deposit limit"
              />
            }
          >
            <div className="flex justify-between">
              <Typography className="font-semibold">
                {formatMoney(limits.periodTransferLimit?.usedAmount ?? 0)}
              </Typography>
              <Typography className="font-semibold">
                {formatMoney(limits.periodTransferLimit?.limitAmount ?? 0)} /{' '}
                <FormattedMessage
                  id="card.table.limit.duration"
                  defaultMessage="{limitType, select, DAILY {Day} WEEKLY {Week} MONTHLY {Month} YEARLY {Year} other {}}"
                  values={{
                    limitType: limits.periodTransferLimit?.limitType,
                  }}
                />
              </Typography>
            </div>
            <Progress
              value={calculateProgress({
                value: limits.periodTransferLimit?.usedAmount ?? 0,
                total: limits.periodTransferLimit?.limitAmount ?? 0,
              })}
            />

            {limits.periodTransferLimit ? (
              <div className="flex justify-end">
                <Typography
                  className="text-neutral-gray-600"
                  variant="body-small"
                  text="right"
                >
                  <FormattedMessage
                    id="card.limit.resetDate"
                    defaultMessage="Limit resets on {resetDate}"
                    values={{
                      resetDate: (
                        <FormattedDate
                          value={
                            new Date(limits.periodTransferLimit.periodResetDate)
                          }
                          year="numeric"
                          month="long"
                          day="2-digit"
                          hour="2-digit"
                          minute="2-digit"
                        />
                      ),
                    }}
                  />
                </Typography>
              </div>
            ) : null}
          </Widget>
        </SlideInLeft>
        <StickyContainer className="pb-0">
          <Button width="full" onClick={() => showNewMessage()}>
            <FormattedMessage
              id="action.increaseYourLimit"
              defaultMessage="Increase your limit"
            />
          </Button>
        </StickyContainer>
      </SheetContent>
    </Sheet>
  )
}
