import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { parseFullPhoneNumber } from '@/lib/phone'
import { Button, Form, StickyContainer } from '@/shared/ui'

import { BusinessMXRecipient, SharedRecipientFormProps } from '../types'

import { BusinessFields } from './BusinessFields'
import { ContactDetailsFields } from './ContactDetailsFields'
import { DeleteRecipientAction } from './DeleteRecipientAction'
import { MXPaymentFields } from './MXPaymentFields'
import { businessValidation, clabeValidation } from './validations'

const createBusinessSchema = businessValidation.and(clabeValidation)

const MX_BUSINESS_FORM_ID = 'mx-business-form'

export type CreateBusinessSchema = z.infer<typeof createBusinessSchema>

type Props = {
  onContinue: (values: CreateBusinessSchema) => void
  recipient?: BusinessMXRecipient
} & SharedRecipientFormProps

export const MXBusinessForm = ({
  layout,
  country,
  onContinue,
  onDelete,
  isPending,
  recipient,
  recipientId,
}: Props) => {
  const form = useForm<CreateBusinessSchema>({
    mode: 'onChange',
    resolver: zodResolver(createBusinessSchema),
    values: {
      nickname: recipient?.nickname ?? '',
      legalName: recipient?.recipient.details.legalName ?? '',
      email: recipient?.contactDetails?.email ?? '',
      localPhoneNumber:
        recipient?.contactDetails?.internationalPhonePrefix &&
        recipient?.contactDetails?.localPhoneNumber
          ? recipient.contactDetails.internationalPhonePrefix +
            recipient.contactDetails.localPhoneNumber
          : '',
      internationalPhonePrefix:
        recipient?.contactDetails?.internationalPhonePrefix ??
        getCodeByCountry(country),

      clabe: recipient?.paymentInformation.railsDetails.clabe ?? '',
    },
  })

  const onSubmit: SubmitHandler<CreateBusinessSchema> = (values) => {
    onContinue({
      ...values,
      localPhoneNumber: parseFullPhoneNumber(values),
    })
  }

  return (
    <>
      <Form {...form}>
        <form
          id={MX_BUSINESS_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-8">
            <BusinessFields layout={layout} form={form} />

            <ContactDetailsFields form={form} />

            <MXPaymentFields layout={layout} form={form} />
          </div>
        </form>
      </Form>

      <StickyContainer
        className={layout === 'edit' ? '-bottom-16 pb-0' : undefined}
      >
        <Button
          width="full"
          form={MX_BUSINESS_FORM_ID}
          disabled={
            !form.formState.isValid ||
            isPending ||
            (layout === 'edit' && !form.formState.isDirty)
          }
          loading={isPending}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          {layout === 'edit' ? (
            <FormattedMessage
              defaultMessage="Save changes"
              id="action.saveChanges"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          )}
        </Button>

        {onDelete ? (
          <DeleteRecipientAction
            onDelete={onDelete}
            recipient={recipient}
            recipientId={recipientId}
          />
        ) : null}
      </StickyContainer>
    </>
  )
}
