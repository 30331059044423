import { Link, Navigate, Outlet } from 'react-router'

import { ContractorRoute } from '@/constants/paths'
import { useAuth } from '@/providers/AuthProvider'
import { LanguageSelector, Logo } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

export const Layout = () => {
  const { accessToken } = useAuth()

  if (accessToken) {
    return <Navigate to={ContractorRoute.SigningIn} replace />
  }

  return (
    <>
      <FullScreen>
        <Link
          className="absolute left-6 top-6 md:left-12 md:top-12"
          to={ContractorRoute.Root}
        >
          <Logo aria-label="DolarApp Business Logo" />
        </Link>

        <Outlet />

        <div className="absolute right-6 top-[22px] md:fixed md:bottom-12 md:left-12 md:right-auto md:top-auto">
          <LanguageSelector />
        </div>
      </FullScreen>
    </>
  )
}
