import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { DisplayableRunPaymentContractor } from '../types'
import { getBankAccountLabel } from '../utils'

type Props = {
  contractors: DisplayableRunPaymentContractor[]
}

export const ContractorsReviewRow = ({ contractors }: Props) => {
  return (
    <div className="flex flex-col gap-3 pt-4">
      {contractors.map((contractor, index) => (
        <div
          className="flex items-center justify-between"
          key={contractor.fullName + index}
        >
          <div className="flex flex-col">
            <Typography>{contractor.fullName}</Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="contractor.runPayment.payTo"
                defaultMessage="Pay to {accountType}"
                values={{
                  accountType: (
                    <FormattedMessage
                      {...getBankAccountLabel(contractor?.paymentMethodType)}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography text="right">
              {formatAmount(contractor.amount)}
            </Typography>
            <Typography text="right" className="text-neutral-gray-600">
              {formatAmount({
                amount: contractor.amount.amount,
                currency: Currency.USDC,
              })}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}
