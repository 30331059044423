import { FormattedMessage, useIntl } from 'react-intl'

import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Skeleton, Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../hooks'
import { Contract } from '../../types'
import { ContractStateBadge } from '../ContractStateBadge'

type Props = {
  contract?: Contract
}

export const ContractSidebarHeading = ({ contract }: Props) => {
  const intl = useIntl()

  const frequency = usePaymentFrequency({
    dayType: contract?.paymentDetails?.dayType,
    day: contract?.paymentDetails?.day,
    frequency: contract?.paymentDetails?.frequency,
  })

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-3">
        {contract?.paymentType ? (
          <Typography variant="h3">
            <FormattedMessage
              id="contractors.contract.type.label"
              defaultMessage="{state, select, FIXED_RATE {Fixed-rate contract} PAY_AS_YOU_GO {Pay-as-you-go contract} other {}}"
              values={{ state: contract.paymentType }}
            />
          </Typography>
        ) : (
          <Skeleton className="mb-0.5 h-[33px] w-36" />
        )}
      </div>
      {contract ? (
        contract?.paymentDetails ? (
          <Typography className="text-neutral-gray-600">
            {intersperse(
              [formatAmount(contract.paymentDetails), frequency],
              ' • ',
            )}
          </Typography>
        ) : (
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="contractor.paymentDetails.startDate"
              defaultMessage="Start date on {startDate}"
              values={{
                startDate: intl.formatDate(contract?.startDate, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: 'UTC',
                }),
              }}
            />
          </Typography>
        )
      ) : (
        <Skeleton className="h-[18px] w-36" />
      )}

      <div className="p-1" />

      <ContractStateBadge variant="sidebar" state={contract?.state} />
    </div>
  )
}
