import { useEffect } from 'react'
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useSearchParams } from 'react-router'

import { PAGE_SIZE } from '@/constants/pagination'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ActiveFilters,
  DateBadge,
  DateFilter,
  FiltersWidget,
  HistoricalPaymentCategoryBadge,
  HistoricalPaymentCategoryFilter,
  HistoricalPaymentCycleBadge,
  HistoricalPaymentCycleFilter,
  HistoricalPaymentStateBadge,
  HistoricalPaymentStateFilter,
} from '@/domains/Business/components'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { getAnimationKey } from '@/lib/utils'
import { Plus } from '@/shared/icons/outline'
import { Button, DataTable, MotionDiv, SearchInput } from '@/shared/ui'

import { getHistoricalPayment, getHistoricalPayments } from '../api'
import {
  EmptyHistoricalPayments,
  HistoricalPaymentDetailsSidebar,
} from '../components'
import { useHistoricalPaymentsColumns } from '../components/HistoricalPayments/useHistoricalPaymentsColumns'

export const HistoricalPayments = () => {
  const intl = useIntl()
  const columns = useHistoricalPaymentsColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const [searchParams, setSearchParams] = useSearchParams()
  const [historicalPaymentId] = useSearchParamsValue(['id'])

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const {
    data,
    isPending,
    isFetching,
    isFetchingNextPage,
    isFetched,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getHistoricalPayments, params],
    queryFn: ({ pageParam }) =>
      getHistoricalPayments({
        ...params,
        page: String(pageParam),
        limit: String(PAGE_SIZE),
      }),
    select: (data) => data.pages.flatMap((page) => page.data),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.data?.length < PAGE_SIZE) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const historicalPaymentQuery = useQuery({
    queryKey: [queryKeys.getHistoricalPayment, historicalPaymentId],
    queryFn: () => getHistoricalPayment({ id: historicalPaymentId ?? '' }),
    select: (data) => data?.data,
    enabled: !!historicalPaymentId,
  })

  const { ref, inView } = useInView({
    threshold: 0.1,
    rootMargin: '100px',
  })

  useEffect(() => {
    if (inView && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetching, isFetchingNextPage])

  return (
    <MotionDiv>
      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <HistoricalPaymentStateFilter />
          <HistoricalPaymentCategoryFilter />
          <HistoricalPaymentCycleFilter />
          <DateFilter />
        </FiltersWidget>

        <Button
          leftIcon={<Plus className="size-5" />}
          asChild
          variant="tertiary"
        >
          <Link to={BusinessRoute.ContractorsOffsetCyclePayment}>
            <FormattedMessage
              id="action.newPayment"
              defaultMessage="New payment"
            />
          </Link>
        </Button>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <HistoricalPaymentStateBadge />
        <HistoricalPaymentCategoryBadge />
        <HistoricalPaymentCycleBadge />
        <DateBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(isPending, params)}>
        {data?.length === 0 && Object.keys(params).length === 0 ? (
          <EmptyHistoricalPayments />
        ) : (
          <>
            <DataTable
              emptyState={{
                title: intl.formatMessage({
                  id: 'contractors.search.notFound',
                  defaultMessage: 'No contractors found',
                }),
                description: intl.formatMessage({
                  id: 'contractors.search.notFound.description',
                  defaultMessage:
                    'There were no contractors that matched your search criteria',
                }),
              }}
              loaderOptions={{ rows: 1 }}
              isLoading={isPending || (!isFetchingNextPage && isFetching)}
              isLoadingMore={isFetchingNextPage}
              columns={columns}
              data={data ?? []}
              onRowClick={(payment) => {
                setSearchParams(
                  (params) => {
                    params.set('id', payment.id)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
            />

            {isFetched && <div ref={ref} />}
          </>
        )}
      </MotionDiv>

      <HistoricalPaymentDetailsSidebar
        isOpen={!!historicalPaymentId}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('id')
              return params
            },
            { preventScrollReset: true },
          )
        }}
        historicalPayment={historicalPaymentQuery.data}
      />
    </MotionDiv>
  )
}
