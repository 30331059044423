import { Currency } from '@/constants/currency'
import { TYPE_FILTER_NAME } from '@/domains/Business/components'
import { DOLAR_TAG, SPEI } from '@/domains/Business/constants'
import { handleDateQueryParams } from '@/lib/date'
import { parseAdditionalDetails } from '@/lib/utils'

import { CardCategory } from '../../Cards/types'
import { EntityType } from '../../Recipients/types'
import { failedTransactionStatuses } from '../constants'
import {
  SingleTransaction,
  TransactionSearchParams,
  TransactionType,
} from '../types'

export function getTransactionPaymentMethod(
  transaction?: SingleTransaction,
): string | null {
  const additionalDetails = getTransactionAdditionalDetails(transaction)

  if (additionalDetails?.beneficiaryDolarTag) {
    return DOLAR_TAG
  }

  const userClabe =
    additionalDetails?.beneficiaryClabe ?? additionalDetails?.payerClabe

  const paymentMethod =
    additionalDetails?.beneficiaryPaymentMethod ??
    additionalDetails?.paymentMethod

  const MXDefaultPaymentMethod = userClabe ? SPEI : null

  return paymentMethod ?? MXDefaultPaymentMethod
}

export function handleTransactionQueryParams(
  params: TransactionSearchParams = {},
) {
  const searchParams = handleDateQueryParams(params)

  if (
    params[TYPE_FILTER_NAME]?.includes(TransactionType.INTERNAL_SWAP_DEPOSIT)
  ) {
    const existingStatuses = params[TYPE_FILTER_NAME].split(',')

    existingStatuses.push(TransactionType.INTERNAL_SWAP_WITHDRAW)

    searchParams.set(TYPE_FILTER_NAME, existingStatuses.join(','))
  }

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return searchQuery
}

export function getTransactionAdditionalDetails(
  transaction?: SingleTransaction,
) {
  return parseAdditionalDetails<{
    beneficiaryAccountNumber?: string | null
    beneficiaryClabe?: string | null
    beneficiaryDolarTag?: string
    beneficiaryEntityType?: EntityType
    beneficiaryId?: string
    beneficiaryPaymentMethod?: string | null
    beneficiaryRoutingNumber?: string | null
    cardId?: string
    cardLastFourDigits?: string
    cardType?: string
    cashbackEndDate?: string
    cashbackRate?: number
    cashbackStartDate?: string
    eligibleCardSpendAmount?: number
    eligibleCardSpendCurrency?: Currency
    claveRastreo?: string
    completedByFullName?: string
    imad?: string
    merchantCategory?: CardCategory
    merchantLogoUrl?: string
    omad?: string
    payerBankName?: string | null
    payerClabe?: string | null
    payerRoutingNumber?: string | null
    paymentMessage?: string | null
    paymentMethod?: string | null
    taskOwnerFullName?: string
    traceNumber?: string
  }>(transaction?.additionalDetails)
}

export function getIsTransactionFailed(transaction?: SingleTransaction) {
  if (!transaction) return false

  return failedTransactionStatuses.includes(transaction.transactionStatus)
}
