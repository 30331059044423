import { FormattedMessage } from 'react-intl'

import { RecipientAccountAvatar } from '@/domains/Business/components'
import { PaymentRails, SPEI } from '@/domains/Business/constants'
import { maskValue } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const AccountCell = ({ recipient }: Props) => {
  switch (recipient.paymentRails) {
    case PaymentRails.MEX:
      return (
        <div className="flex flex-col justify-center">
          <div className="flex items-center gap-1">
            <RecipientAccountAvatar paymentRails={recipient.paymentRails} />

            <Typography>{SPEI}</Typography>
          </div>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {maskValue(recipient.localInformation.clabe, 3)}
          </Typography>
        </div>
      )

    case PaymentRails.USA:
      return (
        <div className="flex flex-col justify-center">
          <div className="flex items-center gap-1">
            <RecipientAccountAvatar paymentRails={recipient.paymentRails} />

            <Typography>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Typography>
          </div>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {maskValue(recipient.localInformation.accountNumber, 3)}
          </Typography>
        </div>
      )

    case PaymentRails.DOLAR_APP:
      return (
        <div className="flex flex-col justify-center">
          <div className="flex items-center gap-1">
            <RecipientAccountAvatar paymentRails={recipient.paymentRails} />

            <Typography>
              <FormattedMessage defaultMessage="DolarTag" id="label.dolarTag" />
            </Typography>
          </div>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {recipient.localInformation.dolarTag}
          </Typography>
        </div>
      )

    default:
      return null
  }
}
