import { Map, Marker } from '@vis.gl/react-google-maps'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { Currency } from '@/constants/currency'
import { PHYSICAL_CARD_FEE } from '@/domains/Business/constants'
import { useCoordinatesFromAddress } from '@/hooks/useCoordinatesFromAddress'
import { getCountryNameByISO2Code } from '@/lib/country'
import { formatAmount } from '@/lib/money'
import { FreeLabel } from '@/shared/components'
import { Details, Skeleton, Typography } from '@/shared/ui'
import { ISO2CountryCode } from '@/types/country'

import { TeamCardAddressSchema } from './AddTeamMemberSteps/schemas'

type Props = {
  teamCardAddressDetails?: TeamCardAddressSchema
}

export const TeamCardAddressReview = ({ teamCardAddressDetails }: Props) => {
  const intl = useIntl()

  const fullAddress = [
    teamCardAddressDetails?.addressStreet,
    teamCardAddressDetails?.addressStreetNumber,
    teamCardAddressDetails?.addressApartment,
    teamCardAddressDetails?.addressDistrict,
    teamCardAddressDetails?.addressCity,
    teamCardAddressDetails?.addressState,
    teamCardAddressDetails?.addressPostCode,
    getCountryNameByISO2Code(
      teamCardAddressDetails?.addressCountry ?? ISO2CountryCode.MX,
      intl.locale,
    ),
  ].join(', ')

  const coordinates = useCoordinatesFromAddress(fullAddress)

  if (!teamCardAddressDetails) {
    return null
  }

  return (
    <div className="flex w-full flex-col gap-6">
      {coordinates ? (
        <Map
          className="h-40 w-full [&>div]:rounded-xl"
          defaultZoom={16}
          defaultCenter={coordinates}
          gestureHandling="greedy"
          disableDefaultUI
        >
          <Marker position={coordinates} />
        </Map>
      ) : (
        <Skeleton className="h-40 w-full" />
      )}

      <Details align="start">
        <Details.Label>
          <FormattedMessage
            defaultMessage="Delivery address"
            id="label.deliveryAddress"
          />
        </Details.Label>

        <Details.Value className="max-w-80 whitespace-break-spaces text-right">
          {fullAddress}
        </Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Contact phone number"
            id="label.contactPhoneNumber"
          />
        </Details.Label>

        <Details.Value>
          {formatPhoneNumberIntl(teamCardAddressDetails.localPhoneNumber)}
        </Details.Value>
      </Details>

      {teamCardAddressDetails?.addressReferences ? (
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Address references"
              id="label.addressReferences"
            />
          </Details.Label>

          <Details.Value>
            {teamCardAddressDetails?.addressReferences}
          </Details.Value>
        </Details>
      ) : null}

      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Delivery cost"
            id="label.deliveryFee"
          />
        </Details.Label>

        <div className="flex gap-1 text-right">
          <Typography className="line-through">
            {formatAmount({
              amount: PHYSICAL_CARD_FEE,
              currency: Currency.USDC,
            })}
          </Typography>
          <FreeLabel />
        </div>
      </Details>
    </div>
  )
}
