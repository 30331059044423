import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { getFullName } from '@/lib/typography'
import { MotionDiv, Skeleton, Typography } from '@/shared/ui'

import { getContractorDetails, getContractorTransactions } from '../../api'
import {
  ContractorPaymentDetails,
  ContractorPaymentDetailsSidebar,
  ContractorPayments,
  ContractorPersonalInformation,
  ContractorTaxInformation,
  ContractsSection,
} from '../../components'

import { ExternalContractorDetailsSidebar } from './components'

export const ExternalContractorDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getContractorDetails],
    queryFn: getContractorDetails,
    select: (data) => data?.data,
  })

  const contractorPaymentsQuery = useQuery({
    queryKey: [queryKeys.getContractorTransactions],
    queryFn: () => getContractorTransactions(),
    select: (data) => data.data,
  })

  const latestContract = useMemo(() => {
    if (contractorQuery.data) {
      return contractorQuery.data.contracts[0]
    }

    return null
  }, [contractorQuery.data])

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            {contractorQuery.isPending ? (
              <Skeleton className="h-[33px] w-36" />
            ) : (
              <Typography variant="h3">
                {getFullName(contractorQuery.data?.personalDetails)}
              </Typography>
            )}
            {contractorQuery.isPending ? (
              <Skeleton className="h-[21px] w-36" />
            ) : (
              <Typography className="text-neutral-gray-600">
                {latestContract?.contractorRole}
              </Typography>
            )}
          </div>
        </div>

        <div className="p-4" />

        <MotionDiv className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="flex flex-col gap-8">
            <ContractorPersonalInformation
              contractor={contractorQuery.data}
              isPending={contractorQuery.isPending}
            />

            <ContractorTaxInformation
              contractor={contractorQuery.data}
              isPending={contractorQuery.isPending}
            />
          </div>

          <div className="col-span-2 flex flex-col gap-8">
            <ContractsSection
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('contract', latestContract?.id ?? '')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPaymentDetails
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('payment-details', 'true')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPayments
              payments={contractorPaymentsQuery.data}
              isPending={contractorPaymentsQuery.isPending}
            />
          </div>
        </MotionDiv>
      </div>

      <ExternalContractorDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('contract')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('contract')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />

      <ContractorPaymentDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('payment-details')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('payment-details')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </>
  )
}
