import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Big } from 'big.js'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { DeleteDialog } from '@/shared/components'
import { Button } from '@/shared/ui'
import { ApiError } from '@/types/global'

import { transferMoney } from '../../MoveMoney/api'
import { deleteAccount } from '../api'
import { Wallet } from '../types'

import { TransferBalanceDialog } from './TransferBalanceDialog'

type Props = {
  account?: Wallet
  onDelete: () => void
  isPendingOutside: boolean
}

export const DeleteAccountAction = ({
  onDelete,
  account,
  isPendingOutside,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const [isDeleting, setIsDeleting] = useState(false)
  const [accountHasBalance, setAccountHasBalance] = useState(false)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteAccount,
  })

  const {
    mutateAsync: transferFunds,
    isPending: isTransferPending,
    isSuccess: isTransferSuccess,
  } = useMutation({
    mutationFn: transferMoney,
  })

  const onDeleteCard = async () => {
    if (!account?.id) {
      toast.error('Account ID not found')

      return
    }

    try {
      await mutateAsync(account.id)

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAccount],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAccountDetails, account.id],
      })

      setIsDeleting(false)

      toast.success(
        intl.formatMessage({
          id: 'account.delete.success',
          defaultMessage: 'The account was deleted successfully',
        }),
      )

      onDelete()
    } catch (error) {
      const apiError = error as ApiError

      if (apiError.response?.data?.error === 'NOT_EMPTY_WALLET_ERROR') {
        setAccountHasBalance(true)

        return
      }

      notifyError(error)
    }
  }

  const onTransferAndDelete = async (walletToTransferTo?: Wallet) => {
    if (!account?.id || !walletToTransferTo) {
      toast.error('no account or wallet to transfer to')

      return
    }

    try {
      await transferFunds({
        fromWallet: account.id,
        toWallet: walletToTransferTo.id,
        amount: Big(account.balance).toString(),
        currency: account.currency,
        requestId: crypto.randomUUID(),
      })

      await mutateAsync(account.id)

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAccount],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAccountDetails, account.id],
      })

      setIsDeleting(false)
      setAccountHasBalance(false)

      toast.success(
        intl.formatMessage({
          id: 'account.delete.success',
          defaultMessage: 'The account was deleted successfully',
        }),
      )

      onDelete()
    } catch (error) {
      const apiError = error as ApiError

      switch (apiError.response?.data?.error) {
        case 'NOT_EMPTY_WALLET_ERROR':
          setAccountHasBalance(true)

          break

        case 'WALLET_NEGATIVE_BALANCE_ERROR':
          toast.error(
            intl.formatMessage({
              id: 'account.delete.error.negativeBalance',
              defaultMessage:
                'Accounts with negative balance cannot be deleted',
            }),
          )
          break

        default:
          notifyError(error)
      }
    }
  }

  return (
    <>
      <Button
        width="full"
        disabled={isPendingOutside || isPending}
        loading={isPending}
        onClick={() => setIsDeleting(true)}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage
          defaultMessage="Delete account"
          id="action.deleteAccount"
        />
      </Button>

      <DeleteDialog
        title={
          <FormattedMessage
            defaultMessage="Delete {account} account?"
            id="account.delete.dialog.title"
            values={{ account: account?.label }}
          />
        }
        description={
          <FormattedMessage
            defaultMessage="You are about to permanently delete the {account} account. This will terminate all cards related to it. Are you sure you want to continue?"
            id="account.delete.dialog.description.deleteAccount"
            values={{ account: account?.label }}
          />
        }
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending}
        onDelete={onDeleteCard}
      />

      <TransferBalanceDialog
        walletIdToDelete={account?.id}
        isOpen={accountHasBalance}
        isPending={isTransferPending || isTransferSuccess}
        onTransferAndDelete={onTransferAndDelete}
        onOpenChange={() => {
          setAccountHasBalance(false)
          setIsDeleting(false)
        }}
      />
    </>
  )
}
