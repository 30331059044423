import { contractorApi } from '@/lib/api'
import { handleDateQueryParams } from '@/lib/date'

import { ContractorsTransaction } from '../types'

export const getContractorTransactions = (
  params: Record<string, string> = {},
) => {
  const searchQuery = handleDateQueryParams(params)

  const search =
    searchQuery.toString().length > 0 ? `?${searchQuery.toString()}` : ''

  return contractorApi.get<ContractorsTransaction[]>(
    `/v1/my/contractor-payments/search${search}`,
  )
}
