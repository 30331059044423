import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { ContractorsTransaction } from '../../../types'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentDateCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>{formatDate(payment.paymentDate, 'dd MMM. yyyy')}</Typography>
    </div>
  )
}
