import { cn } from '@/lib/utils'
import {
  ArrowLeftRight,
  CircleCancel,
  Dolarapp,
  InfoCircle,
} from '@/shared/icons/outline'
import { Avatar, AvatarSize, AvatarVariant } from '@/shared/ui'

import { getIconByEntityType } from '../features/Recipients/utils'
import {
  SingleTransaction,
  TransactionType,
} from '../features/Transactions/types'
import {
  getIsTransactionFailed,
  getTransactionAdditionalDetails,
} from '../features/Transactions/utils'

import { CardTransactionIcon } from './CardTransactionIcon'

const IconComponent = ({ transaction, size, variant }: Props) => {
  const isFailed = getIsTransactionFailed(transaction)
  const additionalDetails = getTransactionAdditionalDetails(transaction)

  const iconProps = {
    className: cn('text-neutral-gray-900 size-5', {
      'size-8': size === 'xl',
      'text-primary': variant === 'primary' && !isFailed,
    }),
  }

  if (isFailed) {
    return (
      <>
        <CircleCancel {...iconProps} />
        <InfoCircle
          className={cn(
            'absolute -bottom-1 -right-1 size-3 text-primary-error',
            {
              'size-5': size === 'xl',
            },
          )}
        />
      </>
    )
  }

  switch (transaction?.transactionType) {
    case TransactionType.INTERNAL_SWAP_DEPOSIT:
    case TransactionType.INTERNAL_SWAP_WITHDRAW:
      return <ArrowLeftRight {...iconProps} />
    case TransactionType.REWARD:
    case TransactionType.FEE:
    case TransactionType.CASHBACK:
      return <Dolarapp {...iconProps} />

    case TransactionType.CARD_PAYMENT: {
      return <CardTransactionIcon transaction={transaction} size={size} />
    }

    default: {
      const Icon = getIconByEntityType(additionalDetails?.beneficiaryEntityType)

      return <Icon {...iconProps} />
    }
  }
}

type Props = {
  variant?: AvatarVariant
  size?: AvatarSize
  transaction?: SingleTransaction
}

export const TransactionIcon = ({ variant, size, transaction }: Props) => {
  return (
    <Avatar
      variant={variant}
      size={size}
      icon={
        <IconComponent
          variant={variant}
          transaction={transaction}
          size={size}
        />
      }
    />
  )
}
