import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { Card } from '../../features/Cards/types'

import { CardCell } from './CardCell'
import { CardholderCell } from './CardholderCell'
import { CardLimitCell } from './CardLimitCell'
import { CardStateCell } from './CardStateCell'
import { CardWalletCell } from './CardWalletCell'

export const useCardsColumns = (): ColumnDef<Card>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Card>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <CardCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.card',
              defaultMessage: 'Card',
            })}
          />
        ),
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => <CardStateCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 120,
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Card>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <CardCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.card',
              defaultMessage: 'Card',
            })}
          />
        ),
      },
      {
        accessorKey: 'printedUserName',
        cell: ({ row }) => <CardholderCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.cardholder',
              defaultMessage: 'Cardholder',
            })}
          />
        ),
        size: 180,
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => <CardStateCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 100,
      },
      {
        accessorKey: 'walletId',
        cell: ({ row }) => <CardWalletCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
        size: 180,
      },
      {
        accessorKey: 'cardLimit.usedAmount',
        cell: ({ row }) => <CardLimitCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.cardLimit',
              defaultMessage: 'USDc Limit',
            })}
          />
        ),
        size: 250,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
