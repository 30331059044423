import { useQuery } from '@tanstack/react-query'
import { isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  TransactionsShortList,
  WalletLabel,
} from '@/domains/Business/components'
import { formatDate } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { getAnimationKey } from '@/lib/utils'
import { FreeLabel } from '@/shared/components'
import { Document } from '@/shared/icons/outline'
import {
  Avatar,
  Card,
  Details,
  MotionDiv,
  Sheet,
  SheetContent,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { getBulkTransactions } from '../../../BulkPayments/api'
import {
  HistoricalPaymentDetails,
  HistoricalPaymentState,
  HistoricalPaymentType,
} from '../../types'

import { HistoricalPaymentCycleDetails } from './HistoricalPaymentCycleDetails'
import { HistoricalPaymentStateCell } from './HistoricalPaymentsTable'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  historicalPayment?: HistoricalPaymentDetails
}

export const HistoricalPaymentDetailsSidebar = ({
  isOpen,
  onOpenChange,
  historicalPayment,
}: Props) => {
  const bulkPaymentsQuery = useQuery({
    queryKey: [queryKeys.getBulkTransactions, historicalPayment?.bulkPaymentId],
    queryFn: () =>
      getBulkTransactions(historicalPayment?.bulkPaymentId ?? '', {}),
    select: (data) => data?.data,
    enabled: !!historicalPayment?.bulkPaymentId,
  })

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex flex-col gap-6">
          <>
            <Typography className="text-neutral-gray-400">
              <FormattedMessage
                id="label.contractors"
                defaultMessage="Contractors"
              />
            </Typography>

            {historicalPayment ? (
              <MotionDiv className="flex items-center gap-3">
                <Avatar
                  variant="dark-neutral"
                  size="xl"
                  icon={<Document className="size-8" />}
                />
                <div className="flex flex-col">
                  <Typography variant="h3">
                    {formatAmount({
                      amount: historicalPayment.totalAmount.amount,
                      currency: historicalPayment.totalAmount.currency,
                    })}
                  </Typography>
                  {(() => {
                    switch (historicalPayment.type) {
                      case HistoricalPaymentType.PAYMENT_CYCLE:
                        return (
                          <Typography className="text-neutral-gray-600">
                            {intersperse(
                              [
                                formatDate(
                                  historicalPayment.paymentDetails
                                    .paymentCycleStart,
                                  isSameYear(
                                    historicalPayment.paymentDetails
                                      .paymentCycleStart,
                                    historicalPayment.paymentDetails
                                      .paymentCycleEnd,
                                  )
                                    ? 'dd MMM.'
                                    : 'dd MMM. yyyy',
                                ),
                                formatDate(
                                  historicalPayment.paymentDetails
                                    .paymentCycleEnd,
                                  'dd MMM. yyyy',
                                ),
                              ],
                              ' - ',
                            )}
                          </Typography>
                        )

                      case HistoricalPaymentType.OFF_CYCLE:
                        return (
                          <Typography className="text-neutral-gray-600">
                            {intersperse(
                              [
                                <FormattedMessage
                                  key="paymentFrequency"
                                  id="contractor.paymentType.label"
                                  defaultMessage="{type, select, OFF_CYCLE {Off-cycle} PAYMENT_CYCLE {Payment cycle} other {}}"
                                  values={{ type: historicalPayment.type }}
                                />,
                                <FormattedMessage
                                  key="paymentCategory"
                                  id="contractor.paymentCategory.label"
                                  defaultMessage="{type, select, BONUS {Bonus} COMMISSION {Commission} REIMBURSEMENT {Reimbursement} EXTRA_HOURS {Extra hours} ADJUSTMENT {Adjustment} SETTLEMENT {Settlement} other {}}"
                                  values={{
                                    type: historicalPayment.paymentCategory,
                                  }}
                                />,
                              ],
                              ' • ',
                            )}
                          </Typography>
                        )
                    }
                  })()}
                </div>
              </MotionDiv>
            ) : (
              <div className="flex flex-col gap-1">
                <Skeleton className="h-[33px] w-36" />
                <Skeleton className="h-[18px] w-36" />
              </div>
            )}

            <MotionDiv key={historicalPayment?.id}>
              {historicalPayment ? (
                <Card className="flex flex-col gap-4" size="upload">
                  <HistoricalPaymentCycleDetails
                    historicalPayment={historicalPayment}
                  />
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.contractors"
                        defaultMessage="Contractors"
                      />
                    </Details.Label>
                    <Details.Value>
                      {historicalPayment.numberOfPayments}
                    </Details.Value>
                  </Details>

                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.account"
                        defaultMessage="Account"
                      />
                    </Details.Label>
                    <WalletLabel id={historicalPayment.walletId} />
                  </Details>

                  {(() => {
                    switch (historicalPayment.type) {
                      case HistoricalPaymentType.PAYMENT_CYCLE:
                        return (
                          <Details>
                            <Details.Label>
                              <FormattedMessage
                                id="label.paymentDueDate"
                                defaultMessage="Payment due date"
                              />
                            </Details.Label>
                            <Details.Value>
                              {formatDate(
                                historicalPayment.paymentDetails
                                  .paymentCycleEnd,
                                'dd MMM. yyyy',
                              )}
                            </Details.Value>
                          </Details>
                        )

                      case HistoricalPaymentType.OFF_CYCLE:
                        return null

                      default:
                        return null
                    }
                  })()}

                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.paidOn"
                        defaultMessage="Paid on"
                      />
                    </Details.Label>

                    <Details.Value>
                      {formatDate(
                        historicalPayment.paidOn,
                        'dd MMM. yyyy HH:mm:ss',
                      )}
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.status"
                        defaultMessage="Status"
                      />
                    </Details.Label>

                    <HistoricalPaymentStateCell payment={historicalPayment} />
                  </Details>
                </Card>
              ) : (
                <Card className="flex flex-col gap-4" size="medium">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Details key={index}>
                      <Details.Skeleton />
                      <Details.Skeleton />
                    </Details>
                  ))}
                </Card>
              )}
            </MotionDiv>

            {historicalPayment ? (
              <MotionDiv>
                <Card className="flex flex-col gap-4" size="medium">
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.amountPaid"
                        defaultMessage="Amount paid"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatAmount({
                        amount: historicalPayment.totalAmount.amount,
                        currency: historicalPayment.totalAmount.currency,
                      })}
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage id="label.fee" defaultMessage="Fee" />
                    </Details.Label>
                    <FreeLabel />
                  </Details>
                </Card>
              </MotionDiv>
            ) : (
              <Card className="flex flex-col gap-4" size="medium">
                <Details>
                  <Details.Skeleton />
                  <Details.Skeleton />
                </Details>
                <Details>
                  <Details.Skeleton />
                  <Details.Skeleton />
                </Details>
              </Card>
            )}

            {historicalPayment &&
              historicalPayment.state !== HistoricalPaymentState.EXECUTING && (
                <MotionDiv key={getAnimationKey(bulkPaymentsQuery.isPending)}>
                  <TransactionsShortList
                    isPending={bulkPaymentsQuery.isPending}
                    transactions={bulkPaymentsQuery.data ?? []}
                    path={generatePath(
                      BusinessRoute.ContractorsHistoricalPaymentTransactions,
                      {
                        id: historicalPayment?.bulkPaymentId ?? '',
                      },
                    )}
                  />
                </MotionDiv>
              )}
          </>
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
