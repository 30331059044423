import { differenceInHours, isSameYear } from 'date-fns'
import { useIntl } from 'react-intl'

import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { StatusBadge, StatusVariant } from '@/shared/components'
import { Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../../hooks'
import { ContractorPayment } from '../../../types'

enum PaymentDueDate {
  MORE_THAN_2_DAYS = 'MORE_THAN_2_DAYS',
  LESS_OR_EQUAL_2_DAYS = 'LESS_OR_EQUAL_2_DAYS',
  OVERDUE = 'OVERDUE',
}

const badgeVariant: Record<PaymentDueDate, StatusVariant> = {
  [PaymentDueDate.MORE_THAN_2_DAYS]: 'neutral',
  [PaymentDueDate.LESS_OR_EQUAL_2_DAYS]: 'warning',
  [PaymentDueDate.OVERDUE]: 'failed',
}

function getPaymentDueDate(payment: ContractorPayment) {
  const today = new Date()

  const due =
    Math.ceil(differenceInHours(payment.paymentDueDate, today) / 24) | 0

  if (due > 2) {
    return {
      state: PaymentDueDate.MORE_THAN_2_DAYS,
      due,
    }
  }

  if (due < 0) {
    return {
      state: PaymentDueDate.OVERDUE,
      due,
    }
  }

  return {
    state: PaymentDueDate.LESS_OR_EQUAL_2_DAYS,
    due,
  }
}

type Props = {
  payment: ContractorPayment
}

export const PaymentCycleCell = ({ payment }: Props) => {
  const intl = useIntl()
  const { due, state } = getPaymentDueDate(payment)

  const frequency = usePaymentFrequency({
    dayType: payment.paymentDayType,
    day: payment.paymentDay,
    frequency: payment.paymentFrequency,
  })

  const titleByDue: Record<PaymentDueDate, string> = {
    [PaymentDueDate.MORE_THAN_2_DAYS]: intl.formatMessage(
      {
        id: 'contractor.paymentDueDate.moreThan2Days',
        defaultMessage:
          'Due in {count, plural, =0 {} one {# day} other {# days}}',
      },
      { count: due },
    ),
    [PaymentDueDate.LESS_OR_EQUAL_2_DAYS]: intl.formatMessage(
      {
        id: 'contractor.paymentDueDate.lessThan2Days',
        defaultMessage:
          '{count, plural, =0 {Due today} one {Due in # day} other {Due in # days}}',
      },
      { count: due },
    ),
    [PaymentDueDate.OVERDUE]: intl.formatMessage(
      {
        id: 'contractor.paymentDueDate.overdue',
        defaultMessage:
          'Overdue {count, plural, =0 {} one {# day} other {# days}}',
      },
      { count: Math.abs(due) },
    ),
  }

  const variant = badgeVariant[state]
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col items-center gap-2 md:flex-row">
        <Typography bold>
          {intersperse(
            [
              formatDate(
                payment.paymentCycleStart,
                isSameYear(payment.paymentCycleStart, payment.paymentCycleEnd)
                  ? 'dd MMM.'
                  : 'dd MMM. yyyy',
              ),
              formatDate(payment.paymentCycleEnd, 'dd MMM. yyyy'),
            ],
            ' - ',
          )}
        </Typography>
        <StatusBadge
          className="hidden md:flex"
          variant={variant}
          title={titleByDue[state]}
        />
      </div>
      <Typography
        variant="body-small"
        className="hidden text-neutral-gray-600 md:block"
      >
        {frequency}
      </Typography>
    </div>
  )
}
