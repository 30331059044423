import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link, matchRoutes, useLocation, useParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  BulkTransaction,
  SingleTransaction,
} from '@/domains/Business/features/Transactions/types'
import { useSearchInput } from '@/hooks/useSearchInput'
import { getAnimationKey } from '@/lib/utils'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  MotionDiv,
  MotionSpan,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  StateBadge,
  StateFilter,
  TransactionDetailsSidebar,
  TransactionsTable,
  useTransactionColumns,
} from '../../components'
import { useTransactionUtils } from '../../hooks'
import { getBulkTransaction, getTransaction } from '../Transactions/api'

import { getBulkTransactions } from './api'

function getBreadcrumbNavigation(path: string) {
  const match = matchRoutes(
    [
      { path: BusinessRoute.ContractorsHistoricalPaymentTransactions },
      { path: BusinessRoute.BulkPaymentTransactions },
    ],
    path,
  )

  switch (match?.[0]?.route.path) {
    case BusinessRoute.ContractorsHistoricalPaymentTransactions:
      return (
        <>
          <BreadcrumbLink asChild>
            <Button variant="ghost" size="inline" asChild>
              <Link to={BusinessRoute.ContractorsOverview}>
                <FormattedMessage
                  id="label.contractors"
                  defaultMessage="Contractors"
                />
              </Link>
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbLink asChild>
            <Button variant="ghost" size="inline" asChild>
              <Link to={BusinessRoute.ContractorsHistoricalPayments}>
                <FormattedMessage
                  id="label.historicalPayments"
                  defaultMessage="Historical payments"
                />
              </Link>
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />
        </>
      )
    default:
      return (
        <>
          <BreadcrumbLink asChild>
            <Button variant="ghost" size="inline" asChild>
              <Link to={BusinessRoute.Transactions}>
                <FormattedMessage
                  id="label.transactions"
                  defaultMessage="Transactions"
                />
              </Link>
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />
        </>
      )
  }
}

export const BulkTransactions = () => {
  const columns = useTransactionColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const { id: bulkPaymentId } = useParams<{ id: string }>()
  const location = useLocation()

  const {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen,
    transactionIdFromQuery,
  } = useTransactionUtils()

  const [allTransactions, singleTransaction, bulkTransaction] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getBulkTransactions, bulkPaymentId, params],
        queryFn: () =>
          getBulkTransactions(bulkPaymentId ?? '', {
            ...params,
          }),
        select: (data: AxiosResponse<SingleTransaction[]>) => data?.data,
        enabled: !!bulkPaymentId,
      },
      {
        queryKey: [queryKeys.getTransaction, transactionIdFromQuery],
        queryFn: () => getTransaction({ id: transactionIdFromQuery ?? '' }),
        select: (data: AxiosResponse<SingleTransaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
      {
        queryKey: [queryKeys.getBulkTransaction, bulkPaymentId],
        queryFn: () => getBulkTransaction({ id: bulkPaymentId ?? '' }),
        select: (data: AxiosResponse<BulkTransaction>) => data?.data,
        enabled: !!bulkPaymentId,
      },
    ],
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            {getBreadcrumbNavigation(location.pathname)}

            <BreadcrumbItem>
              <MotionSpan key={getAnimationKey(bulkTransaction.isPending)}>
                {bulkTransaction.isPending ? (
                  <Skeleton className="h-6 w-36" />
                ) : (
                  <Typography className="line-clamp-1">
                    {bulkTransaction.data?.bulkTransactionLabel ? (
                      bulkTransaction.data?.bulkTransactionLabel
                    ) : (
                      <FormattedMessage
                        id="label.bulkPayment"
                        defaultMessage="Bulk payment"
                      />
                    )}
                  </Typography>
                )}
              </MotionSpan>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <AccountsFilter />
          <DateFilter />
          <StateFilter />
          <CurrencyFilter />
        </FiltersWidget>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <AccountsBadge />
        <DateBadge />
        <StateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(allTransactions.isPending, params)}>
        {allTransactions.data?.length === 0 &&
        Object.keys(params).length === 0 ? (
          <EmptyTransactionsCard />
        ) : (
          <TransactionsTable
            isLoading={allTransactions.isPending}
            isLoadingMore={false}
            onRowClick={openSidebar}
            columns={columns}
            data={allTransactions.data ?? []}
            loaderOptions={{ rows: 5 }}
          />
        )}
      </MotionDiv>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
