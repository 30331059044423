import {
  ContractSidebarHeading,
  ContractSidebarInfoWidget,
  ContractSidebarPaymentWidget,
} from '@/domains/Contractor/components'
import { ContractorDetails } from '@/domains/Contractor/types'
import { Sheet, SheetContent, SlideInLeft } from '@/shared/ui'

import { ContractorDocuments } from './ContractorDocuments'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  contractor?: ContractorDetails
}

export const ExternalContractorDetailsSidebar = ({
  isOpen,
  onOpenChange,
  contractor,
}: Props) => {
  const latestContract = contractor?.contracts[0]

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex h-full flex-col">
          <ContractSidebarHeading contract={latestContract} />

          <div className="p-4" />

          <div className="flex flex-col gap-8">
            <ContractSidebarInfoWidget contract={latestContract} />

            <ContractSidebarPaymentWidget contract={latestContract} />

            <ContractorDocuments contractId={latestContract?.id} />
          </div>

          <div className="p-4" />
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
