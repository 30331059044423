import { Currency } from '@/constants/currency'
import { PaymentMethod, PaymentRails } from '@/domains/Business/constants'

import { EntityType } from '../../../Recipients/types'
import { BulkPaymentQuote, TransactionRows } from '../../types'

export const MOCKED_TRANSACTIONS_ROWS: TransactionRows = {
  id: '94c2ff72-2b45-435e-8553-951634fc7908',
  validRows: [
    {
      id: 'd5529be8-382e-460d-aa6f-4b4b2f779182',
      state: 'CREATED',
      file: 'test',
      failureReasons: [],
      paymentCurrency: Currency.MXN,
      paymentAmount: 2000.3,
      baseCurrency: Currency.USDC,
      baseAmount: 119.2002860378,
      beneficiaryId: null,
      paymentRails: PaymentRails.MEX,
      beneficiaryType: EntityType.INDIVIDUAL,
      paymentDetails: {
        email: 'mail@gmail.com',
        lastName: 'Fernandez',
        firstName: 'Sebastien',
        addressCity: 'Ciudad de México',
        phoneNumber: '4311819726',
        phonePrefix: '+52',
        addressState: 'CDMX',
        accountNumber: '032180000118359719',
        addressStreet: 'Calle de Durango 200',
        addressZipCode: '6700',
      },
    },
    {
      id: '39e0c3a1-6736-4ad1-8309-55d41438653b',
      state: 'CREATED',
      file: 'test',
      failureReasons: [],
      paymentCurrency: Currency.USD,
      paymentAmount: 30.32,
      baseCurrency: Currency.USDC,
      baseAmount: 30.32,
      beneficiaryId: null,
      paymentRails: PaymentRails.USA,
      beneficiaryType: EntityType.INDIVIDUAL,
      paymentDetails: {
        email: 'mail@hotmail.com',
        lastName: 'Smith',
        firstName: 'John',
        addressCity: 'Los Angeles',
        phoneNumber: '6779493434',
        phonePrefix: '+52',
        addressState: 'CA',
        accountNumber: '76506794',
        addressStreet: '6925 Hollywood Boulevard',
        paymentMethod: PaymentMethod.ACH,
        routingNumber: '267820379',
        addressZipCode: '90028',
      },
    },
    {
      id: '7f17f180-8e19-41fe-88d8-04c62ba08023',
      state: 'CREATED',
      file: 'test',
      failureReasons: [],
      paymentCurrency: Currency.MXN,
      paymentAmount: 400,
      baseCurrency: Currency.USDC,
      baseAmount: 22.364817353,
      beneficiaryId: null,
      paymentRails: PaymentRails.MEX,
      beneficiaryType: EntityType.BUSINESS,
      paymentDetails: {
        email: 'mail@consultorias.com',
        legalName: 'Consultorias SA de CV',
        addressCity: 'Ciudad de Mexico',
        phoneNumber: '4311819721',
        phonePrefix: '+52',
        addressState: 'CDMX',
        accountNumber: '002010077777777771',
        addressStreet: 'Av. Pdte. Masaryk 29',
        addressZipCode: '11560',
        paymentMessage: 'SERVICIOS ABRIL',
      },
    },
    {
      id: '231c754d-1a5c-4c20-8312-d25e8634eee0',
      state: 'CREATED',
      file: 'test',
      failureReasons: [],
      paymentCurrency: Currency.USD,
      paymentAmount: 40,
      baseCurrency: Currency.USDC,
      baseAmount: 238.364817353,
      beneficiaryId: null,
      paymentRails: PaymentRails.MEX,
      beneficiaryType: EntityType.BUSINESS,
      paymentDetails: {
        email: 'mail@consultorias.com',
        legalName: 'Alala',
        addressCity: 'Ciudad de Mexico',
        phoneNumber: '6267277997',
        phonePrefix: '+52',
        addressState: 'CDMX',
        accountNumber: '002010077777777771',
        addressStreet: 'Av. Pdte. Masaryk 29',
        addressZipCode: '11560',
        paymentMessage: 'SERVICIOS ABRIL',
      },
    },
  ],
  invalidRows: [
    {
      id: '06bc13d2-c303-4988-bccf-d9b3a0e69219',
      state: 'INVALID',
      file: 'test',
      failureReasons: ['INVALID_ROUTING_NUMBER'],
      paymentCurrency: Currency.USD,
      paymentAmount: 40,
      baseCurrency: Currency.USDC,
      baseAmount: 4000,
      beneficiaryId: null,
      paymentRails: PaymentRails.USA,
      beneficiaryType: EntityType.BUSINESS,
      paymentDetails: {
        email: 'mail@yahoo.com',
        legalName: 'Waterworks LLC',
        addressCity: 'New York',
        phoneNumber: '6267277997',
        phonePrefix: '+52',
        addressState: 'NY',
        accountNumber: '74688250',
        addressStreet: '200 Park Avenue',
        paymentMethod: PaymentMethod.WIRE,
        routingNumber: '43958261',
        addressZipCode: '10166',
        paymentMessage: 'PAYROLLMARCH',
      },
    },

    {
      id: '312d2007-24c1-451d-808a-ce433f2926eb',
      state: 'INVALID',
      file: 'test',
      failureReasons: ['INVALID_ROUTING_NUMBER'],
      paymentCurrency: Currency.USD,
      paymentAmount: 40,
      baseCurrency: Currency.USDC,
      baseAmount: 4000,
      beneficiaryId: null,
      paymentRails: PaymentRails.USA,
      beneficiaryType: EntityType.BUSINESS,
      paymentDetails: {
        legalName: 'Waterworks LLC',
        addressCity: 'New York',
        addressState: 'NY',
        accountNumber: '12345678',
        addressStreet: '200 Park Avenue',
        paymentMethod: PaymentMethod.WIRE,
        routingNumber: '21000021',
        addressZipCode: '10166',
        paymentMessage: 'PAYROLLMARCH',
      },
    },
    {
      id: '7d75b26f-7e10-4ce1-bdcf-e0f035d7f08d',
      state: 'INVALID',
      file: 'test',
      failureReasons: ['NEGATIVE_AMOUNT', 'INVALID_ROUTING_NUMBER'],
      paymentCurrency: Currency.USD,
      paymentAmount: -30,
      baseCurrency: Currency.USDC,
      baseAmount: -30,
      beneficiaryId: null,
      paymentRails: PaymentRails.USA,
      beneficiaryType: EntityType.INDIVIDUAL,
      paymentDetails: {
        email: 'mail@hotmail.com',
        lastName: 'Smith',
        firstName: 'John',
        addressCity: 'Los Angeles',
        phoneNumber: '6779493434',
        phonePrefix: '+52',
        addressState: 'CA',
        accountNumber: '123456789',
        addressStreet: '6925 Hollywood Boulevard',
        paymentMethod: PaymentMethod.ACH,
        routingNumber: '21000021',
        addressZipCode: '90028',
      },
    },
  ],
}

export const MOCKED_BULK_PAYMENT_QUOTE: BulkPaymentQuote = {
  id: '94c2ff72-2b45-435e-8553-951634fc7908',
  totalNumberOfPayments: 4,
  totalAmount: {
    amount: 907.2854350417,
    currency: Currency.USDC,
  },
  feeBeforeDiscount: {
    amount: 3,
    currency: Currency.USDC,
  },
  fee: {
    amount: 0,
    currency: Currency.USDC,
  },
  paymentQuoteDetails: [
    {
      subtotalNumberOfPayments: 1,
      subtotalAmount: {
        amount: 311.32,
        currency: Currency.USD,
      },
      fxRate: 1,
    },
    {
      subtotalNumberOfPayments: 3,
      subtotalAmount: {
        amount: 10000.3,
        currency: Currency.MXN,
      },
      fxRate: 16.78,
    },
  ],
}
