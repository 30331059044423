import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { HistoricalPayment, HistoricalPaymentState } from '../../../types'

function getBadgeVariant(state: HistoricalPaymentState) {
  return {
    [HistoricalPaymentState.FAILED_TO_EXECUTE]: 'failed',
    [HistoricalPaymentState.CREATED]: 'neutral',
    [HistoricalPaymentState.EXECUTING]: 'neutral',
    [HistoricalPaymentState.IN_PROGRESS]: 'neutral',
    [HistoricalPaymentState.COMPLETED]: 'active',
    [HistoricalPaymentState.INCOMPLETE]: 'warning',
  }[state] as StatusVariant
}

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaymentStateCell = ({ payment }: Props) => {
  const badgeVariant = getBadgeVariant(payment.state)

  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariant}
        title={
          <FormattedMessage
            id="historicalPayment.state.label"
            defaultMessage="{state, select, CREATED {Executing} EXECUTING {Executing} FAILED_TO_EXECUTE {Failed} IN_PROGRESS {In progress} INCOMPLETE {Incomplete} COMPLETED {Completed} other {}}"
            values={{ state: payment.state }}
          />
        }
      />
    </div>
  )
}
