import { cn } from '@/lib/utils'
import { Clock, InfoCircle, User } from '@/shared/icons/outline'
import { Avatar } from '@/shared/ui'

import { ContractorsTransaction, ContractorTransactionState } from '../../types'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentAvatar = ({ payment }: Props) => {
  const isFailed = payment.state === ContractorTransactionState.FAILED
  const isPending = payment.state === ContractorTransactionState.PENDING

  return (
    <Avatar
      icon={
        <>
          <User className="size-5" />
          {isFailed && (
            <InfoCircle
              className={cn(
                'absolute -bottom-1 -right-1 size-3 text-primary-error',
              )}
            />
          )}
          {isPending && (
            <Clock
              className={cn(
                'absolute -bottom-1 -right-1 size-3 text-neutral-gray-400',
              )}
            />
          )}
        </>
      }
    />
  )
}
