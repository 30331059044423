import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import {
  ContractorsTransaction,
  ContractorTransactionState,
} from '../../../types'

function getBadgeVariant(state: ContractorTransactionState) {
  return {
    [ContractorTransactionState.EXECUTING]: 'neutral',
    [ContractorTransactionState.IN_PROGRESS]: 'neutral',
    [ContractorTransactionState.FAILED]: 'failed',
    [ContractorTransactionState.PENDING]: 'neutral',
    [ContractorTransactionState.COMPLETED]: 'active',
  }[state] as StatusVariant
}

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentStateCell = ({ payment }: Props) => {
  const badgeVariant = getBadgeVariant(payment.state)

  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariant}
        title={
          <FormattedMessage
            id="contractor.payment.state.label"
            defaultMessage="{state, select, EXECUTING {Executing} IN_PROGRESS {In progress} FAILED {Failed} PENDING {Pending} COMPLETED {Completed} other {}}"
            values={{ state: payment.state }}
          />
        }
      />
    </div>
  )
}
