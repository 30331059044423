import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Permission } from '@/domains/Business/types'
import { formatCurrency, formatMoney } from '@/lib/money'
import { Exchange, Plus } from '@/shared/icons/outline'
import { Navigation } from '@/shared/icons/solid'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Card,
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Skeleton,
  Typography,
} from '@/shared/ui'

import {
  CountryBankDetails,
  MoveMoneyWidget,
  WalletItem,
  WithPermissions,
} from '../../components'
import { useWallets } from '../../hooks'

import { getAccountDetails } from './api'
import { AccountActions } from './components/AccountActions'
import { AccountTransactionsList } from './components/AccountTransactionsList'
import { AccountType, WalletState } from './types'

export const AccountDetails = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const { wallets } = useWallets({ showAll: true })

  const accountDetailsQuery = useQuery({
    queryKey: [queryKeys.getAccountDetails, params.id],
    queryFn: () => getAccountDetails({ id: params.id ?? '' }),
    select: (data) =>
      data?.data.bankAccounts.sort((a) =>
        a.type === AccountType.ACH ? -1 : 1,
      ),
    enabled: !!params.id,
  })

  const wallet = wallets?.find((wallet) => wallet.id === params.id)

  return (
    <div className="flex w-full flex-col">
      <div className="md:flex-no-wrap flex w-full flex-wrap items-center justify-between gap-6 md:gap-0">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={BusinessRoute.Accounts}>
                  <FormattedMessage
                    id="dashboard.accounts.title"
                    defaultMessage="My accounts"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {wallet?.label ? (
                <Typography>{wallet?.label}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <MotionDiv>
        <div className="p-4" />

        <Card
          size="large"
          className="flex flex-wrap items-center justify-center gap-5 md:justify-between md:gap-0"
        >
          {wallet && wallets?.length ? (
            <div className="flex flex-col gap-3">
              <Select
                onValueChange={(id) => {
                  if (id === 'new-account-action') {
                    navigate(BusinessRoute.CreateAccount, {
                      state: { from: location },
                    })
                    return
                  }

                  navigate(generatePath(BusinessRoute.SingleAccount, { id }))
                }}
              >
                <SelectTrigger className="h-auto w-fit p-0">
                  <WalletItem
                    accountIconVariant="md"
                    balance={false}
                    boldText={false}
                    wallet={wallet}
                  />
                </SelectTrigger>
                <SelectContent>
                  <WithPermissions permissions={[Permission.TRANSFERS]}>
                    <SelectItem
                      value="new-account-action"
                      className="flex items-center gap-3 px-2 py-3"
                    >
                      <div className="flex items-center gap-1">
                        <Plus className="size-5 text-primary" />
                        <Typography className="font-semibold text-primary">
                          <FormattedMessage
                            id="action.newAccount"
                            defaultMessage="New account"
                          />
                        </Typography>
                      </div>
                    </SelectItem>
                  </WithPermissions>
                  {wallets
                    .filter((w) => wallet.id !== w.id)
                    .map((wallet) => (
                      <SelectItem
                        key={wallet.id}
                        value={wallet.id}
                        className="flex items-center gap-3 px-2 py-3"
                      >
                        <WalletItem
                          balance={false}
                          boldText={false}
                          wallet={wallet}
                        />
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <Typography variant="h3">
                {formatMoney(wallet.balance)} {formatCurrency(wallet.currency)}
              </Typography>
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <Skeleton className="h-[24px] w-36" />
              <Skeleton className="h-[33px] w-24" />
            </div>
          )}

          <div className="flex flex-wrap justify-center gap-3 md:justify-end">
            {wallet?.state !== WalletState.CLOSED && (
              <>
                <WithPermissions permissions={[Permission.TRANSFERS]}>
                  <Button
                    leftIcon={<Exchange className="size-5" />}
                    size="md"
                    variant="secondary"
                    asChild
                  >
                    <Link
                      to={`${BusinessRoute.Transfer}?from=${wallet?.id}`}
                      state={{ from: location }}
                    >
                      <FormattedMessage
                        id="action.transfer"
                        defaultMessage="Move"
                      />
                    </Link>
                  </Button>
                </WithPermissions>
                <WithPermissions permissions={[Permission.TRANSFERS]}>
                  <Button
                    leftIcon={<Navigation />}
                    size="md"
                    variant="secondary"
                    asChild
                  >
                    <Link
                      to={`${BusinessRoute.Send}?from=${wallet?.id}`}
                      state={{ from: location }}
                    >
                      <FormattedMessage
                        id="action.send"
                        defaultMessage="Send"
                      />
                    </Link>
                  </Button>
                </WithPermissions>
              </>
            )}

            <AccountActions account={wallet} />
          </div>
        </Card>

        <div className="p-4" />

        <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
          {accountDetailsQuery.data ? (
            <CountryBankDetails
              accountBanks={accountDetailsQuery.data}
              walletId={params.id}
            />
          ) : (
            <>
              <CountryBankDetails.Skeleton rows={6} />
              <CountryBankDetails.Skeleton />
            </>
          )}
        </div>

        <div className="p-5" />

        <AccountTransactionsList />
      </MotionDiv>
    </div>
  )
}
