import { PaymentMethod, PaymentRails } from '@/domains/Business/constants'
import { CountryCode } from '@/types/country'

export enum DolarAppEntityType {
  RETAIL = 'RETAIL',
  BUSINESS = 'BUSINESS',
}

type BaseRecipient = {
  nickname: string
  bankName: string | null
  addressStreet: string | null
  addressCity: string | null
  addressState: string | null
  addressZipCode: string | null
  addressCountry: string | null
  businessId: string
  createdAt: string
  email: string | null
  entityType: EntityType
  firstName: string | null
  paymentRails: PaymentRails
  id: string
  internationalPhonePrefix: string | null
  lastName: string | null
  legalName: string | null
  localPhoneNumber: string | null
  secondLastName: string | null
  state: 'ACTIVE'
  creationDetails?: {
    requestedBy?: string
    approvedBy?: string
    updatedAt?: string
  }
}

export type DolarAppSearchRecipient = {
  owner: { type: DolarAppEntityType; displayName: string }
  alias: { value: string }
}

type MXLocalInformation = {
  clabe: string
}

type USLocalInformation = {
  accountNumber: string
  routingNumber: string
  paymentMethod: PaymentMethod
}

type DolarAppLocalInformation = {
  dolarTag: string
}

type MXRecipient = BaseRecipient & {
  country: CountryCode.MX
  paymentRails: PaymentRails.MEX
  localInformation: MXLocalInformation
}

export type DolarAppRecipient = BaseRecipient & {
  country: CountryCode.MX
  paymentRails: PaymentRails.DOLAR_APP
  localInformation: DolarAppLocalInformation
}

type USRecipient = BaseRecipient & {
  country: CountryCode.US
  paymentRails: PaymentRails.USA
  localInformation: USLocalInformation
}

export type Recipient = MXRecipient | USRecipient | DolarAppRecipient

export enum EntityType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum ContractorEntityType {
  CONTRACTOR = 'CONTRACTOR',
}

type SharedCreateRecipientFields = {
  nickname: string
  contactDetails?: {
    internationalPhonePrefix?: string
    localPhoneNumber?: string
    email?: string
  }
}

type IndividualFields = SharedCreateRecipientFields & {
  recipient: {
    type: EntityType.INDIVIDUAL
    address?: Address
    details: { firstName: string; lastName: string; secondLastName?: string }
  }
}

type BusinessFields = SharedCreateRecipientFields & {
  nickname: string
  recipient: {
    type: EntityType.BUSINESS
    address?: Address
    details: { legalName: string }
  }
}

type PaymentMXInformation = {
  paymentInformation: {
    rails: PaymentRails.MEX
    railsDetails: { clabe: string }
  }
}

type PaymentUSInformation = {
  paymentInformation: {
    rails: PaymentRails.USA
    railsDetails: {
      accountNumber: string
      routingNumber: string
      paymentMethod: string
    }
  }
}

type Address = {
  street: string
  city: string
  postcode: string
  state: string
  countryCode: string
}

export type IndividualMXRecipient = IndividualFields & PaymentMXInformation
export type BusinessMXRecipient = BusinessFields & PaymentMXInformation

export type IndividualUSRecipient = IndividualFields & PaymentUSInformation
export type BusinessUSRecipient = BusinessFields & PaymentUSInformation

export type CreatedDolarAppRecipient = {
  nickname: string
  paymentInformation: {
    rails: PaymentRails.DOLAR_APP
    railsDetails: {
      dolarTag: string
    }
  }
}

export type CreatedRecipient =
  | IndividualMXRecipient
  | BusinessMXRecipient
  | IndividualUSRecipient
  | BusinessUSRecipient

export type CreatedRecipientWithId = Partial<CreatedRecipient> & {
  id: string
}

export type SharedRecipientFormProps = {
  country: CountryCode
  layout?: 'edit' | 'create'
  isPending?: boolean
  recipientId?: string
  onDelete?: () => void
}
