import { getFullName } from '@/lib/typography'

import { EntityType } from '../../Recipients/types'
import { RecipientPaymentDetails } from '../types'

export function getName(recipient: RecipientPaymentDetails) {
  switch (recipient.beneficiaryType) {
    case EntityType.INDIVIDUAL:
      return getFullName(recipient.paymentDetails)
    case EntityType.BUSINESS:
      return recipient.paymentDetails.legalName

    default:
      return null
  }
}
