import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import {
  Button,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getContractorCompany, getCurrentContractor } from '../../api'
import { ExternalContractor, ExternalContractorCompany } from '../../types'

import { getContractorOnboardingState, startContractorOnboarding } from './api'
import { ContractorStepsInfo } from './components/ContractorStepsInfo'
import { ContractorOnboardingState } from './types'

export const ContractorOnboardingStart = () => {
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: startContractorOnboarding,
  })

  const [onboardingStateQuery, userContractorQuery, contractorCompanyQuery] =
    useQueries({
      queries: [
        {
          queryKey: [queryKeys.getContractorOnboardingState],
          queryFn: getContractorOnboardingState,
          select: (data: AxiosResponse<ContractorOnboardingState>) => data.data,
        },
        {
          queryKey: [queryKeys.getCurrentContractor],
          queryFn: getCurrentContractor,
          select: (data: AxiosResponse<ExternalContractor>) => data.data,
        },
        {
          queryKey: [queryKeys.getContractorCompany],
          queryFn: getContractorCompany,
          select: (data: AxiosResponse<ExternalContractorCompany>) => data.data,
        },
      ],
    })

  const onStartOnboarding = async () => {
    try {
      const response = await mutateAsync()

      const [firstStep] = response.data.steps

      navigate(
        generatePath(ContractorRoute.OnboardingStep, {
          step: firstStep.name,
        }),
      )

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractorOnboardingState],
      })

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getCurrentContractor],
      })
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <SlideInScreen>
      {userContractorQuery.data?.firstName ? (
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.welcome.title"
            defaultMessage="Hey {name}! Welcome onboard"
            values={{
              name: userContractorQuery.data?.firstName,
            }}
          />
        </Typography>
      ) : (
        <Skeleton className="mx-auto h-[33px] w-48" />
      )}

      <div className="p-2" />

      {contractorCompanyQuery.data?.legalName ? (
        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.welcome.subtitle"
            defaultMessage="{company} uses DolarApp to manage contracts, invoices and payments. To get started, we need you to complete the following steps"
            values={{
              company: contractorCompanyQuery.data?.legalName,
            }}
          />
        </Typography>
      ) : (
        <Skeleton className="mx-auto h-[42px] w-full" />
      )}

      <div className="p-6" />

      <ContractorStepsInfo steps={onboardingStateQuery.data?.steps} />

      <StickyContainer>
        <Button
          loading={isPending}
          disabled={isPending}
          width="full"
          onClick={onStartOnboarding}
        >
          <FormattedMessage
            id="action.getStarted"
            defaultMessage="Get started"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
