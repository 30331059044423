import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getCountryNameByISO3 } from '@/lib/country'
import { cn, getAnimationKey } from '@/lib/utils'
import { Check, ChevronDown } from '@/shared/icons/outline'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  MotionDiv,
  MotionSpan,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from '@/shared/ui'

import { getOnboardingCountries } from '../api'

type Value = {
  valueAsCode: string
  valueAsName: string
}

type Props = {
  onSelect: (value: Value) => void
  placeholder?: string
  disabled?: boolean
  value?: string
}

export const CountryTaxResidenceCombobox = ({
  onSelect,
  placeholder,
  disabled,
  value,
}: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const countryCodesQuery = useQuery({
    queryKey: [queryKeys.getOnboardingCountries],
    queryFn: getOnboardingCountries,
    select: (data) =>
      data.data.filter((country) => !!country.availableForBusinessMembers),
  })

  const selected = useMemo(
    () => countryCodesQuery.data?.find((country) => country.isoCode === value),
    [countryCodesQuery.data, value],
  )

  return (
    <FormItem className="flex flex-col">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              width="full"
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-[54px] justify-between rounded-lg px-4 font-normal hover:bg-white',
              )}
            >
              {selected ? (
                <MotionDiv
                  key={getAnimationKey(countryCodesQuery.isPending)}
                  className="flex flex-col items-start"
                >
                  <Typography
                    className={cn(
                      '-mb-0.5 text-neutral-gray-600',
                      disabled && 'text-neutral-gray-400',
                    )}
                    variant="body-tiny"
                  >
                    {placeholder}
                  </Typography>
                  <MotionSpan
                    key={selected.isoCode}
                    className="flex items-center gap-1"
                  >
                    <span className="mt-0.5">{selected.flag}</span>
                    <Typography
                      className={cn(disabled && 'text-neutral-gray-600')}
                    >
                      {getCountryNameByISO3(
                        selected.isoCode,
                        intl.locale,
                        selected.nameToShow,
                      )}
                    </Typography>
                  </MotionSpan>
                </MotionDiv>
              ) : (
                <Typography className="text-neutral-gray-600">
                  {placeholder}
                </Typography>
              )}

              <ChevronDown className="shrink-0 text-neutral-gray-600" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-full p-0 md:w-[600px]">
          <Command
            filter={(value, search) => {
              const searchValue = search.toLowerCase().trim()

              const [code, country] = value.split('-')

              if (
                country.includes(searchValue.trim().toLowerCase()) ||
                code.includes(searchValue.trim().toLowerCase())
              ) {
                return 1
              }

              return 0
            }}
          >
            <CommandList>
              <CommandInput
                placeholder={intl.formatMessage({
                  id: 'placeholder.searchCountry',
                  defaultMessage: 'Search country',
                })}
              />
              <CommandEmpty>
                <FormattedMessage
                  id="placeholder.search.noCountryFound"
                  defaultMessage="No country found"
                />
              </CommandEmpty>
              <CommandGroup>
                {countryCodesQuery.data?.map((country) => (
                  <CommandItem
                    value={`${country.isoCode}-${country.name}`}
                    key={country.isoCode}
                    disabled={country.isoCode === value}
                    onSelect={() => {
                      onSelect({
                        valueAsCode: country.isoCode,
                        valueAsName: country.name,
                      })
                      setOpen((open) => !open)
                    }}
                  >
                    <Typography>
                      {country.flag} {country.nameToShow}
                    </Typography>

                    <Check
                      className={cn(
                        'ml-2 h-4 w-4',
                        country.isoCode === value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
