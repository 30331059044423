import { forwardRef, Ref, type SVGProps } from 'react'

const SvgReload = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 14 14"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M.787 7.001a.5.5 0 0 0 1-.001zM2.241 3.76l.42.27zM4.779 1.61l-.197-.46v.001zm3.268-.333.101-.49h-.001zm2.895 1.598-.36.347zm.94 2.596a.5.5 0 0 0 .961-.276zm-.003-.264a.5.5 0 1 0 .967.252zm1.56-2.025a.5.5 0 0 0-.969-.252zm-1.21 2.633a.5.5 0 1 0 .267-.964zm-1.94-1.574a.5.5 0 0 0-.267.964zM13.453 7a.5.5 0 1 0-1 .001zm-1.453 3.241-.42-.27zM9.462 12.39l.197.46.001-.001zm-3.267.333-.102.49h.002zM3.3 11.125l.36-.347zM2.36 8.53a.5.5 0 0 0-.961.276zm.003.264a.5.5 0 0 0-.968-.252zm-1.56 2.025a.5.5 0 0 0 .968.252zm1.21-2.633a.5.5 0 0 0-.267.964zm1.94 1.574a.5.5 0 0 0 .267-.964zM1.787 7a5.47 5.47 0 0 1 .874-2.97l-.84-.541A6.47 6.47 0 0 0 .787 7zm.874-2.97a5.2 5.2 0 0 1 2.316-1.96l-.395-.918A6.2 6.2 0 0 0 1.82 3.489zm2.315-1.96a5 5 0 0 1 2.97-.302l.2-.98a6 6 0 0 0-3.564.364zm2.969-.302a5.1 5.1 0 0 1 2.637 1.455l.72-.695A6.1 6.1 0 0 0 8.148.788zm2.637 1.455a5.4 5.4 0 0 1 1.3 2.249l.961-.276a6.4 6.4 0 0 0-1.542-2.668zm2.264 2.237.592-2.277-.968-.252-.591 2.277zm-.35-.608-2.208-.61-.266.964 2.207.61zM12.454 7a5.46 5.46 0 0 1-.873 2.97l.84.541A6.46 6.46 0 0 0 13.454 7zm-.873 2.97a5.2 5.2 0 0 1-2.316 1.96l.395.918a6.2 6.2 0 0 0 2.761-2.337zm-2.315 1.96a5 5 0 0 1-2.97.302l-.201.98a6 6 0 0 0 3.564-.364zm-2.97.302a5.1 5.1 0 0 1-2.636-1.455l-.72.694a6.1 6.1 0 0 0 3.153 1.74zM3.66 10.778a5.4 5.4 0 0 1-1.3-2.249l-.961.276a6.4 6.4 0 0 0 1.541 2.668zM1.395 8.541l-.591 2.277.967.252.592-2.277zm.351.608 2.207.61.267-.964-2.208-.61z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgReload)
export default ForwardRef
