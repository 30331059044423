import { FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router'

import { BusinessRoute, ContractorRoute } from '@/constants/paths'
import { getAnimationKey } from '@/lib/utils'
import { EmptyContractorPayments, Widget } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, MotionDiv, Skeleton } from '@/shared/ui'

import { ContractorsTransaction } from '../../types'

import { ContractorPaymentCard } from './ContractorPaymentCard'

type Props = {
  payments?: ContractorsTransaction[]
  isPending: boolean
  contractorId?: string
}

export const ContractorPayments = ({
  payments,
  isPending,
  contractorId,
}: Props) => {
  return (
    <Widget
      variant="form"
      title={<FormattedMessage id="label.payments" defaultMessage="Payments" />}
    >
      <MotionDiv key={getAnimationKey(isPending)}>
        {isPending ? (
          <Card size="upload" className="flex flex-col gap-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <ContractorPaymentCard.Skeleton key={index} />
            ))}

            <Skeleton className="h-10 w-32" />
          </Card>
        ) : !payments?.length ? (
          <EmptyContractorPayments />
        ) : (
          <Card size="upload" className="flex flex-col gap-6">
            {payments.slice(0, 4).map((payment, index) => (
              <ContractorPaymentCard key={index} payment={payment} />
            ))}

            <Button
              rightIcon={<ChevronRight className="size-5" />}
              variant="secondary"
              asChild
            >
              <Link
                to={
                  contractorId
                    ? generatePath(BusinessRoute.ContractorsPayments, {
                        id: contractorId,
                      })
                    : ContractorRoute.Transactions
                }
              >
                <FormattedMessage
                  id="actions.seeAll"
                  defaultMessage="See all"
                />
              </Link>
            </Button>
          </Card>
        )}
      </MotionDiv>
    </Widget>
  )
}
