import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { useKeyPress } from '@/hooks/useKeyPress'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  MotionDiv,
  SlideInScreen,
  StickyContainer,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@/shared/ui'

import { getCSVTransactions } from '../../api'
import { CSVFailedTransactionsList } from '../CSVFailedTransactionsList'
import { CSVTransactionsList } from '../CSVTransactionsList'

type Props = {
  onBack: () => void
  onContinue: (idx: string[]) => void
  selectedIds: string[]
}

export const BulkPaymentSelectTransactions = ({
  onBack,
  onContinue,
  selectedIds,
}: Props) => {
  const [bulkPaymentId] = useSearchParamsValue(['id'])

  const csvTransactionsQuery = useQuery({
    queryKey: [queryKeys.getCSVTransactions, bulkPaymentId],
    queryFn: () => getCSVTransactions({ id: bulkPaymentId ?? '' }),
    select: (data) => data.data,
    staleTime: 0,
    enabled: !!bulkPaymentId,
  })

  const [selectedIdx, setSelectedIdx] = useState<string[]>(selectedIds)

  useKeyPress('Enter', () => onContinue(selectedIdx))

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review and import payment details"
            id="bulkPayment.selectTransactions.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="bulkPayment.selectTransactions.subtitle"
            defaultMessage="Make sure recipient details and payment amounts are correct"
          />
        </Typography>

        <div className="p-6" />

        <Tabs defaultValue="valid">
          <TabsList variant="outline">
            <TabsTrigger
              disabled={csvTransactionsQuery.data?.validRows.length === 0}
              size="fit"
              variant="outline"
              value="valid"
            >
              <FormattedMessage
                id="bulkPayments.tabs.valid"
                defaultMessage="Ready to import • {count}"
                values={{
                  count: csvTransactionsQuery.data?.validRows.length ?? 0,
                }}
              />
            </TabsTrigger>
            <TabsTrigger
              disabled={csvTransactionsQuery.data?.invalidRows.length === 0}
              size="fit"
              variant="outline"
              value="invalid"
            >
              <FormattedMessage
                id="bulkPayments.tabs.invalid"
                defaultMessage="Failed to import • {count}"
                values={{
                  count: csvTransactionsQuery.data?.invalidRows.length ?? 0,
                }}
              />
            </TabsTrigger>
          </TabsList>

          <TabsContent value="valid">
            <MotionDiv
              className="pt-6"
              key={csvTransactionsQuery.data?.validRows.length}
            >
              {csvTransactionsQuery.isPending ? (
                <CSVTransactionsList.Skeleton />
              ) : (
                <CSVTransactionsList
                  selectedTransactions={selectedIdx}
                  transactions={csvTransactionsQuery.data?.validRows ?? []}
                  onSelect={(selected) => setSelectedIdx(selected)}
                />
              )}
            </MotionDiv>
          </TabsContent>
          <TabsContent value="invalid">
            <MotionDiv
              className="pt-6"
              key={csvTransactionsQuery.data?.invalidRows.length}
            >
              {csvTransactionsQuery.isPending ? (
                <CSVTransactionsList.Skeleton />
              ) : (
                <CSVFailedTransactionsList
                  transactions={csvTransactionsQuery.data?.invalidRows ?? []}
                />
              )}
            </MotionDiv>
          </TabsContent>
        </Tabs>

        <StickyContainer>
          <Button
            disabled={selectedIdx.length < 1}
            onClick={() => onContinue(selectedIdx)}
            width="full"
          >
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
