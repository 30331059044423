import { FormattedMessage } from 'react-intl'

import { formatAmount } from '@/lib/money'
import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { usePaymentFrequency } from '../../hooks'
import { Contract } from '../../types'

type Props = {
  contract?: Contract
}

export const ContractSidebarPaymentWidget = ({ contract }: Props) => {
  const frequency = usePaymentFrequency({
    dayType: contract?.paymentDetails?.dayType,
    day: contract?.paymentDetails?.day,
    frequency: contract?.paymentDetails?.frequency,
  })

  if (!contract?.paymentDetails) return null

  return (
    <Widget
      title={
        <FormattedMessage
          id="label.paymentDetails"
          defaultMessage="Payment details"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Payment amount"
            id="label.paymentAmount"
          />
        </Details.Label>

        <Details.Value>{formatAmount(contract.paymentDetails)}</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Payment frequency"
            id="label.paymentFrequency"
          />
        </Details.Label>
        <Details.Value>{frequency}</Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="First-month payment"
            id="label.firstMonthPayment"
          />
        </Details.Label>

        <Details.Value>
          <FormattedMessage
            id="contractor.firstCyclePayment.label"
            defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
            values={{
              firstCyclePayment: contract.paymentDetails.firstCycleMethod,
            }}
          />
        </Details.Value>
      </Details>
      {contract.paymentDetails.firstCycleAmount ? (
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Custom first cycle payment amount"
              id="label.customFirstCyclePaymentAmount"
            />
          </Details.Label>

          <Details.Value>
            {formatAmount({
              amount: contract.paymentDetails.firstCycleAmount,
              currency: contract.paymentDetails.currency,
            })}
          </Details.Value>
        </Details>
      ) : null}
    </Widget>
  )
}
