import { Big } from 'big.js'
import { z } from 'zod'

export const payAsYouGoContractorFormSchema = z.object({
  contractors: z.array(
    z.object({
      contractorId: z.string(),
      contractId: z.string(),
      amount: z.object({
        amount: z
          .string()
          .optional()
          .refine((v) => !v || Big(v).gt(0), {
            message: 'validation.balance.minAmount',
          }),
        currency: z.string(),
      }),
    }),
  ),
})

export type PayAsYouGoContractorFormSchema = z.infer<
  typeof payAsYouGoContractorFormSchema
>
