import { defineMessages } from 'react-intl'

import { DefineMessages } from '@/constants/messages'

import { FailureReasons } from '../types'

export const csvParseErrorMessages: DefineMessages<FailureReasons> =
  defineMessages({
    FIRST_OR_LAST_NAME_MISSING: {
      defaultMessage: 'First or last name missing',
      id: 'csvParseError.firstNameOrLastNameMissing',
    },
    TOO_SHORT_NAME: {
      defaultMessage: 'Name is too short',
      id: 'csvParseError.tooShortName',
    },
    UNSUPPORTED_RECIPIENT_TYPE: {
      defaultMessage: 'Unsupported recipient type',
      id: 'csvParseError.unsupportedRecipientType',
    },
    EMPTY_RECIPIENT_TYPE: {
      defaultMessage: 'Empty recipient type',
      id: 'csvParseError.emptyRecipientType',
    },
    EMPTY_NAME: {
      defaultMessage: 'Empty name',
      id: 'csvParseError.emptyName',
    },
    UNSUPPORTED_COUNTRY: {
      defaultMessage: 'Unsupported country',
      id: 'csvParseError.unsupportedCountry',
    },
    EMPTY_COUNTRY: {
      defaultMessage: 'Empty country',
      id: 'csvParseError.emptyCountry',
    },
    EMPTY_CURRENCY: {
      defaultMessage: 'Empty currency',
      id: 'csvParseError.emptyCurrency',
    },
    UNSUPPORTED_CURRENCY: {
      defaultMessage: 'Unsupported currency',
      id: 'csvParseError.unsupportedCurrency',
    },
    EMPTY_AMOUNT: {
      defaultMessage: 'Empty amount',
      id: 'csvParseError.emptyAmount',
    },
    INVALID_AMOUNT_FORMAT: {
      defaultMessage: 'Invalid amount format',
      id: 'csvParseError.invalidAmountFormat',
    },
    EMPTY_ACCOUNT_NUMBER: {
      defaultMessage: 'Empty account number',
      id: 'csvParseError.emptyAccountNumber',
    },
    INVALID_ACCOUNT_NUMBER_FORMAT: {
      defaultMessage: 'Invalid account number format',
      id: 'csvParseError.invalidAccountNumberFormat',
    },
    INVALID_ROUTING_NUMBER_FORMAT: {
      defaultMessage: 'Invalid ABA / routing number format',
      id: 'csvParseError.invalidRoutingNumberFormat',
    },
    UNSUPPORTED_PAYMENT_METHOD: {
      defaultMessage: 'Unsupported payment method',
      id: 'csvParseError.unsupportedPaymentMethod',
    },
    INVALID_EMAIL: {
      defaultMessage: 'Invalid email',
      id: 'csvParseError.invalidEmail',
    },
    INVALID_PHONE_NUMBER: {
      defaultMessage: 'Invalid phone number',
      id: 'csvParseError.invalidPhoneNumber',
    },
    COUNTRY_DOES_NOT_MATCH_CURRENCY: {
      defaultMessage: 'Country does not match currency',
      id: 'csvParseError.countryDoesNotMatchCurrency',
    },
    LEGAL_NAME_MISSING: {
      defaultMessage: 'Legal name missing',
      id: 'csvParseError.legalNameMissing',
    },
    NEGATIVE_AMOUNT: {
      defaultMessage: 'Negative amount',
      id: 'csvParseError.negativeAmount',
    },
    TOO_LOW_AMOUNT: {
      defaultMessage: 'Amount is too low',
      id: 'csvParseError.tooLowAmount',
    },
    MISSING_ROUTING_NUMBER: {
      defaultMessage: 'Missing ABA / routing number',
      id: 'csvParseError.missingRoutingNumber',
    },
    INVALID_ROUTING_NUMBER: {
      defaultMessage: 'Invalid ABA / routing number',
      id: 'csvParseError.invalidRoutingNumber',
    },
    REDUNDANT_ROUTING_NUMBER: {
      defaultMessage: 'Redundant ABA / routing number',
      id: 'csvParseError.redundantRoutingNumber',
    },
    INVALID_CLABE: {
      defaultMessage: 'Invalid CLABE',
      id: 'csvParseError.invalidClabe',
    },
    MISSING_US_PAYMENT_METHOD: {
      defaultMessage: 'Missing US payment method',
      id: 'csvParseError.missingUsPaymentMethod',
    },
    REDUNDANT_PAYMENT_METHOD: {
      defaultMessage: 'Redundant payment method',
      id: 'csvParseError.redundantPaymentMethod',
    },
    MISSING_ADDRESS: {
      defaultMessage: 'Missing address',
      id: 'csvParseError.missingAddress',
    },
    INVALID_DOLAR_TAG: {
      defaultMessage: 'Invalid DolarTag',
      id: 'csvParseError.invalidDolarTag',
    },
    RAILS_DO_NOT_MATCH_CURRENCY: {
      defaultMessage: 'Currency not supported for DolarTag',
      id: 'csvParseError.currencyNotSupportedForDolarTag',
    },
  })
