import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'

import { PAGE_SIZE } from '@/constants/pagination'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  EmptyTransactionsCard,
  TransactionDetailsSidebar,
  TransactionsTable,
  useTransactionColumns,
} from '@/domains/Business/components'
import { Transaction } from '@/domains/Business/features/Transactions/types'
import { useTransactionUtils } from '@/domains/Business/hooks'
import { getAnimationKey } from '@/lib/utils'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, MotionDiv } from '@/shared/ui'

import { getTransactionByType, getTransactions } from '../../Transactions/api'

export const TransactionsWidget = () => {
  const columns = useTransactionColumns()
  const {
    closeSidebar,
    openSidebar,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const { data, isPending, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.getTransactions],
    queryFn: ({ pageParam }) =>
      getTransactions({
        page: String(pageParam),
        limit: String(PAGE_SIZE),
      }),
    select: (data) => data.pages.flatMap((page) => page.data),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.data?.length < PAGE_SIZE) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const singleTransaction = useQuery({
    queryKey: [
      queryKeys.getTransaction,
      transactionIdFromQuery,
      transactionTypeFromQuery,
    ],
    queryFn: () =>
      getTransactionByType({
        type: transactionTypeFromQuery,
        id: transactionIdFromQuery,
      }),
    select: (data: AxiosResponse<Transaction>) => data?.data,
    enabled: !!transactionIdFromQuery,
  })

  return (
    <>
      <MotionDiv key={getAnimationKey(isPending)}>
        {data?.length === 0 ? (
          <EmptyTransactionsCard />
        ) : (
          <>
            <TransactionsTable
              isLoading={isPending || (!isFetchingNextPage && isFetching)}
              isLoadingMore={isFetchingNextPage}
              data={data?.slice(0, 4) ?? []}
              columns={columns}
              onRowClick={openSidebar}
              loaderOptions={{ rows: 4 }}
            />

            <Card className="relative -top-5 -mb-5" size="large">
              <Button
                rightIcon={<ChevronRight className="size-5" />}
                size="md"
                variant="secondary"
                asChild
              >
                <Link to={BusinessRoute.Transactions}>
                  <FormattedMessage
                    id="action.seeAll"
                    defaultMessage="See all"
                  />
                </Link>
              </Button>
            </Card>
          </>
        )}
      </MotionDiv>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </>
  )
}
