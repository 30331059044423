import { api } from '@/lib/api'
import { handleDateQueryParams } from '@/lib/date'

import { HistoricalPayment } from '../types'

export const getHistoricalPayments = (params: Record<string, string> = {}) => {
  const searchParams = handleDateQueryParams(params)

  const search =
    searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''

  return api.get<HistoricalPayment[]>(
    `/v1/me/historical-contractor-payments/search${search}`,
  )
}
