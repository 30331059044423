import { FormattedMessage } from 'react-intl'

import { Details } from '@/shared/ui'

import { usePaymentFrequency } from '../../hooks'
import { HistoricalPaymentDetails, HistoricalPaymentType } from '../../types'

type Props = {
  historicalPayment: HistoricalPaymentDetails
}

export const HistoricalPaymentCycleDetails = ({ historicalPayment }: Props) => {
  const frequency = usePaymentFrequency({
    day:
      historicalPayment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? historicalPayment.paymentDetails.paymentDay
        : undefined,
    dayType:
      historicalPayment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? historicalPayment.paymentDetails.paymentDayType
        : undefined,
    frequency:
      historicalPayment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? historicalPayment.paymentDetails.paymentFrequency
        : undefined,
  })

  switch (historicalPayment.type) {
    case HistoricalPaymentType.PAYMENT_CYCLE:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentCycle"
                defaultMessage="Payment cycle"
              />
            </Details.Label>
            <Details.Value>{frequency}</Details.Value>
          </Details>
        </>
      )

    case HistoricalPaymentType.OFF_CYCLE:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.paymentType"
              defaultMessage="Payment type"
            />
          </Details.Label>
          <Details.Value>
            <FormattedMessage
              id="contractor.paymentType.label"
              defaultMessage="{type, select, OFF_CYCLE {Off-cycle} PAYMENT_CYCLE {Payment cycle} other {}}"
              values={{ type: historicalPayment.type }}
            />
          </Details.Value>
        </Details>
      )

    default:
      return null
  }
}
