import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import { MOCKED_DOLAR_APP_USERS, MOCKED_RECIPIENTS } from './recipients.mocks'

export const recipientsHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/beneficiaries/search`, async () => {
    return HttpResponse.json(MOCKED_RECIPIENTS)
  }),
  http.post(`${BASE_API_DEV}/v2/me/beneficiaries`, async () => {
    return HttpResponse.json({})
  }),

  http.get(`${BASE_API_DEV}/v1/me/beneficiaries/recent`, async () => {
    return HttpResponse.json([])
  }),

  http.get(`${BASE_API_DEV}/v1/me/beneficiaries/:id`, async ({ params }) => {
    const { id } = params

    const RECIPIENT =
      MOCKED_RECIPIENTS.find((r) => r.id === id) ?? MOCKED_RECIPIENTS[0]

    return HttpResponse.json(RECIPIENT)
  }),

  http.get(
    `${BASE_API_DEV}/v1/me/bank-info/:code/references/:reference`,
    async () => {
      return HttpResponse.json({
        name: 'Regent bank',
      })
    },
  ),

  http.patch(`${BASE_API_DEV}/v1/me/beneficiaries/:id`, async () => {
    return HttpResponse.json({})
  }),

  http.delete(`${BASE_API_DEV}/v1/me/beneficiaries/:id`, async () => {
    return HttpResponse.json({})
  }),

  http.get(
    `${BASE_API_DEV}/v1/me/accounts/search-by-alias/:alias`,
    async () => {
      return HttpResponse.json(MOCKED_DOLAR_APP_USERS)
    },
  ),
]

export * from './recipients.mocks'
