import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { ContractorsTransaction } from '../../../types'

import { ContractorPaymentAmountCell } from './ContractorPaymentAmountCell'
import { ContractorPaymentCurrencyCell } from './ContractorPaymentCurrencyCell'
import { ContractorPaymentCycleCell } from './ContractorPaymentCycleCell'
import { ContractorPaymentDateCell } from './ContractorPaymentDateCell'
import { ContractorPaymentStateCell } from './ContractorPaymentStateCell'
import { DestinationCell } from './DestinationCell'

export const useContractorPaymentColumns =
  (): ColumnDef<ContractorsTransaction>[] => {
    const isMobile = useMediaQuery(isSmallScreen)
    const intl = useIntl()

    const mobileColumns: ColumnDef<ContractorsTransaction>[] = useMemo(
      () => [
        {
          accessorKey: 'type',
          cell: ({ row }) => (
            <ContractorPaymentCycleCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentCycle',
                defaultMessage: 'Payment cycle',
              })}
            />
          ),
        },
        {
          id: 'amount',
          accessorFn: (row) => row.amount.amount,
          cell: ({ row }) => (
            <ContractorPaymentAmountCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.amount',
                defaultMessage: 'Amount',
              })}
            />
          ),
        },
        {
          accessorKey: 'paymentDate',
          cell: ({ row }) => (
            <ContractorPaymentDateCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentDate',
                defaultMessage: 'Payment date',
              })}
            />
          ),
        },
      ],
      [intl],
    )

    const desktopColumns: ColumnDef<ContractorsTransaction>[] = useMemo(
      () => [
        {
          accessorKey: 'type',
          cell: ({ row }) => (
            <ContractorPaymentCycleCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentCycle',
                defaultMessage: 'Payment cycle',
              })}
            />
          ),
        },
        {
          id: 'amount',
          accessorFn: (row) => row.amount.amount,
          cell: ({ row }) => (
            <ContractorPaymentAmountCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.amount',
                defaultMessage: 'Amount',
              })}
            />
          ),
          size: 120,
        },
        {
          id: 'currency',
          accessorFn: (row) => row.amount.currency,
          cell: ({ row }) => (
            <ContractorPaymentCurrencyCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.currency',
                defaultMessage: 'Currency',
              })}
            />
          ),
          size: 100,
        },
        {
          accessorKey: 'paymentMethodType',
          cell: ({ row }) => <DestinationCell payment={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.destination',
                defaultMessage: 'Destination',
              })}
            />
          ),
          size: 160,
        },
        {
          accessorKey: 'state',
          cell: ({ row }) => (
            <ContractorPaymentStateCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.status',
                defaultMessage: 'Status',
              })}
            />
          ),
          size: 120,
        },
        {
          accessorKey: 'paymentDate',
          cell: ({ row }) => (
            <ContractorPaymentDateCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentDate',
                defaultMessage: 'Payment date',
              })}
            />
          ),
          size: 140,
        },
      ],
      [intl],
    )

    return isMobile ? mobileColumns : desktopColumns
  }
