import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { AccountIcon, CountryBankDetails } from '@/domains/Business/components'
import { getAccountDetails } from '@/domains/Business/features/Accounts/api'
import { AccountType } from '@/domains/Business/features/Accounts/types'
import { useWallets } from '@/domains/Business/hooks'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { GoBackButton } from '@/shared/components'
import { Skeleton, SlideInScreen, Typography } from '@/shared/ui'

type Props = {
  account: string
  onBack: () => void
}

export const AddBalanceDetails = ({ account, onBack }: Props) => {
  const location = useLocation()
  const [to] = useSearchParamsValue(['to'])

  const { wallets, isPending: walletsPending } = useWallets()

  const wallet = wallets?.find((wallet) => wallet.id === account)

  const detailsQuery = useQuery({
    queryKey: [queryKeys.getAccountDetails, account],
    queryFn: () => getAccountDetails({ id: account }),
    select: (data) =>
      data?.data.bankAccounts.sort((a) =>
        a.type === AccountType.ACH ? -1 : 1,
      ),
  })

  return (
    <>
      {wallets?.length === 1 || to ? (
        <GoBackButton to={location.state?.from ?? BusinessRoute.Dashboard} />
      ) : (
        <GoBackButton onClick={onBack} />
      )}

      <SlideInScreen>
        <div className="flex items-center justify-center gap-2">
          {walletsPending ? (
            <Skeleton className="h-[33px] w-48" />
          ) : (
            <>
              <AccountIcon id={wallet?.id ?? ''} />

              <Typography variant="h3">{wallet?.label}</Typography>
            </>
          )}
        </div>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Receive an MXN or USD transfer to your account details below"
            id="addBalance.receiveTransfer"
          />
        </Typography>
        <div className="p-4" />

        {detailsQuery.data ? (
          <CountryBankDetails
            accountBanks={detailsQuery.data}
            walletId={account}
          />
        ) : (
          <>
            <CountryBankDetails.Skeleton rows={6} />
            <div className="p-3" />
            <CountryBankDetails.Skeleton />
          </>
        )}
      </SlideInScreen>
    </>
  )
}
