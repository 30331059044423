import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { RecipientAccountAvatar } from '@/domains/Business/components'
import { DOLAR_TAG, PaymentRails } from '@/domains/Business/constants'
import { InputLikeCard, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { DolarAppRecipient } from '../types'
import { getRecipientTitle } from '../utils'

import { DeleteRecipientAction } from './DeleteRecipientAction'

const createDolarAppIndividualRecipientSchema = z.object({
  dolarTag: z.string().min(1, {
    message: 'validation.dolarTag.required',
  }),
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  displayName: z.string().min(1),
})

const DOLAR_APP_RECIPIENT_FORM_ID = 'dolar-app-recipient-form'

export type CreateDolarAppIndividualRecipientSchema = z.infer<
  typeof createDolarAppIndividualRecipientSchema
>

type Props = {
  onContinue: (values: CreateDolarAppIndividualRecipientSchema) => void
  recipient?: DolarAppRecipient
  onDelete?: () => void
  isPending?: boolean
  recipientId: string
}

export const DolarAppRecipientForm = ({
  onContinue,
  onDelete,
  isPending,
  recipient,
  recipientId,
}: Props) => {
  const intl = useIntl()

  const form = useForm<CreateDolarAppIndividualRecipientSchema>({
    mode: 'onChange',
    resolver: zodResolver(createDolarAppIndividualRecipientSchema),
    values: {
      nickname: recipient?.nickname ?? '',
      displayName: getRecipientTitle(recipient) ?? '',
      dolarTag: recipient?.localInformation.dolarTag ?? '',
    },
  })

  const onSubmit: SubmitHandler<CreateDolarAppIndividualRecipientSchema> = (
    values,
  ) => {
    onContinue(values)
  }

  return (
    <>
      <Form {...form}>
        <form
          id={DOLAR_APP_RECIPIENT_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-8">
            <Widget
              title={
                <FormattedMessage
                  id="recipient.recipientDetails"
                  defaultMessage="Recipient details"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Recipient name',
                          id: 'label.recipientName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Recipient name"
                        id="label.recipientName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="nickname"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.recipientNickname',
                          defaultMessage: 'Recipient nickname',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.recipientNickname"
                        defaultMessage="Recipient nickname"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </Widget>

            <Widget
              title={
                <FormattedMessage
                  id="recipient.paymentDetails"
                  defaultMessage="Payment details"
                />
              }
              variant="form"
            >
              <InputLikeCard
                disabled
                placeholder={intl.formatMessage({
                  id: 'label.paymentMethod',
                  defaultMessage: 'Payment method',
                })}
              >
                <div className="flex items-center gap-1">
                  <RecipientAccountAvatar
                    paymentRails={PaymentRails.DOLAR_APP}
                  />
                  <Typography>{DOLAR_TAG}</Typography>
                </div>
              </InputLikeCard>

              <FormField
                control={form.control}
                name="dolarTag"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input disabled placeholder={DOLAR_TAG} {...field} />
                    </FormControl>
                    <AnimatedFormLabel>{DOLAR_TAG}</AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </Widget>
          </div>
        </form>
      </Form>

      <StickyContainer className="-bottom-16 pb-0">
        <Button
          width="full"
          form={DOLAR_APP_RECIPIENT_FORM_ID}
          disabled={
            !form.formState.isValid || isPending || !form.formState.isDirty
          }
          loading={isPending}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save changes"
            id="action.saveChanges"
          />
        </Button>

        {onDelete ? (
          <DeleteRecipientAction
            onDelete={onDelete}
            recipient={recipient}
            recipientId={recipientId}
          />
        ) : null}
      </StickyContainer>
    </>
  )
}
