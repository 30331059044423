import { http, HttpResponse } from 'msw'

import { Currency } from '@/constants/currency'
import { BASE_API_DEV } from '@/constants/urls'
import {
  ACCOUNTS_FILTER_NAME,
  CARDS_ID_FILTER_NAME,
  CURRENCY_FILTER_NAME,
  STATUS_FILTER_NAME,
  TYPE_FILTER_NAME,
} from '@/domains/Business/components'

import { DisplayableType } from '../../types'
import { getTransactionAdditionalDetails } from '../../utils'

import { MOCKED_TRANSACTIONS } from './transactions.mocks'

export const transactionsHandlers = [
  http.get(`${BASE_API_DEV}/v3/me/transactions/search`, async ({ request }) => {
    const url = new URL(request.url)

    const account = url.searchParams.get(ACCOUNTS_FILTER_NAME)
    const type = url.searchParams.get(TYPE_FILTER_NAME)
    const status = url.searchParams.get(STATUS_FILTER_NAME)
    const currency = url.searchParams.get(CURRENCY_FILTER_NAME)
    const cardId = url.searchParams.get(CARDS_ID_FILTER_NAME)

    const hasAnyFilters = account || type || status || currency

    const mockedTransactions = MOCKED_TRANSACTIONS.filter(
      (transaction) =>
        transaction.displayableType === DisplayableType.SINGLE &&
        (!account || transaction.walletId === account) &&
        (!type || transaction.transactionType === type) &&
        (!status || transaction.transactionStatus === status) &&
        (!currency || transaction.localCurrency === currency),
    )

    if (cardId) {
      return HttpResponse.json(
        mockedTransactions.filter(
          (transaction) =>
            transaction.displayableType === DisplayableType.SINGLE &&
            !!getTransactionAdditionalDetails(transaction)?.cardId,
        ),
      )
    }

    return HttpResponse.json(
      hasAnyFilters ? mockedTransactions : MOCKED_TRANSACTIONS,
    )
  }),

  http.get(`${BASE_API_DEV}/v2/me/transactions/:id`, async ({ params }) => {
    const { id } = params

    const transaction =
      MOCKED_TRANSACTIONS.find(
        (transaction) =>
          transaction.displayableType === DisplayableType.SINGLE &&
          transaction.id === id,
      ) ?? MOCKED_TRANSACTIONS[0]

    return HttpResponse.json(transaction)
  }),

  http.get(
    `${BASE_API_DEV}/v2/me/transactions/group/:bulkId`,
    async ({ params }) => {
      const { bulkId } = params

      const transaction =
        MOCKED_TRANSACTIONS.find(
          (transaction) =>
            transaction.displayableType === DisplayableType.BULK &&
            transaction.groupId === bulkId,
        ) ?? MOCKED_TRANSACTIONS[0]

      return HttpResponse.json(transaction)
    },
  ),
  http.get(
    `${BASE_API_DEV}/v2/me/transactions/group/:bulkId/search`,
    async ({ request }) => {
      const url = new URL(request.url)

      const account = url.searchParams.get(ACCOUNTS_FILTER_NAME)
      const type = url.searchParams.get(TYPE_FILTER_NAME)
      const status = url.searchParams.get(STATUS_FILTER_NAME)
      const currency = url.searchParams.get(CURRENCY_FILTER_NAME)

      const mockedTransactions = MOCKED_TRANSACTIONS.filter(
        (transaction) =>
          transaction.displayableType === DisplayableType.SINGLE &&
          (!account || transaction.walletId === account) &&
          (!type || transaction.transactionType === type) &&
          (!status || transaction.transactionStatus === status) &&
          (!currency || transaction.localCurrency === currency),
      )

      return HttpResponse.json(mockedTransactions)
    },
  ),

  http.get(`${BASE_API_DEV}/v1/me/transaction-currencies`, async () => {
    return HttpResponse.json([
      Currency.EUR,
      Currency.USD,
      Currency.MXN,
      Currency.GBP,
    ])
  }),
]

export * from './transactions.mocks'
