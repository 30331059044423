import { FormattedMessage, useIntl } from 'react-intl'

import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { Contract } from '../../types'

type Props = {
  contract?: Contract
}

export const ContractSidebarInfoWidget = ({ contract }: Props) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="label.contractDetails"
          defaultMessage="Contract details"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Contract type"
            id="label.contractType"
          />
        </Details.Label>
        <Details.Value>
          <FormattedMessage
            id="contract.type.label"
            defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
            values={{ state: contract?.paymentType }}
          />
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Role" id="label.role" />
        </Details.Label>
        <Details.Value>{contract?.contractorRole}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Start date" id="label.startDate" />
        </Details.Label>
        <Details.Value>
          {intl.formatDate(contract?.startDate, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'UTC',
          })}
        </Details.Value>
      </Details>
    </Widget>
  )
}
