import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { Link, useParams, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { MoveMoneyWidget } from '@/domains/Business/components'
import { getFullName } from '@/lib/typography'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  MotionDiv,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { getContractor, getContractorPayments } from '../api'
import {
  ContractDetailsSidebar,
  ContractorPaymentDetails,
  ContractorPaymentDetailsSidebar,
  ContractorPayments,
  ContractorPersonalInformation,
  ContractorTaxInformation,
  ContractorToDos,
  ContractsSection,
} from '../components'

export const ContractorDetails = () => {
  const { id: contractorId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getContractor, contractorId],
    queryFn: () => getContractor({ id: contractorId ?? '' }),
    select: (data) => data?.data,
    enabled: !!contractorId,
  })

  const contractorPaymentsQuery = useQuery({
    queryKey: [queryKeys.getContractorPayments, contractorId],
    queryFn: () => getContractorPayments({ contractorId: contractorId ?? '' }),
    select: (data) => data.data,
    enabled: !!contractorId,
  })

  const latestContract = useMemo(() => {
    if (contractorQuery.data) {
      return contractorQuery.data.contracts[0]
    }

    return null
  }, [contractorQuery.data])

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex w-full flex-wrap justify-between gap-6">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbLink asChild>
                <Button variant="ghost" size="inline" asChild>
                  <Link to={BusinessRoute.ContractorsAll}>
                    <FormattedMessage
                      id="dashboard.contractors.title"
                      defaultMessage="Contractors"
                    />
                  </Link>
                </Button>
              </BreadcrumbLink>

              <BreadcrumbSeparator />

              <BreadcrumbItem>
                {contractorQuery.isPending ? (
                  <Skeleton className="h-[21px] w-36" />
                ) : (
                  <Typography>
                    {getFullName(contractorQuery.data?.personalDetails)}
                  </Typography>
                )}
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <MoveMoneyWidget />
        </div>

        <div className="p-4" />

        <MotionDiv
          key={getFullName(contractorQuery.data?.personalDetails)}
          className="flex justify-between"
        >
          <div className="flex flex-col gap-2">
            {contractorQuery.isPending ? (
              <Skeleton className="h-[33px] w-36" />
            ) : (
              <Typography variant="h3">
                {getFullName(contractorQuery.data?.personalDetails)}
              </Typography>
            )}
            {contractorQuery.isPending ? (
              <Skeleton className="h-[21px] w-36" />
            ) : (
              <Typography className="text-neutral-gray-600">
                {latestContract?.contractorRole}
              </Typography>
            )}
          </div>
        </MotionDiv>

        <div className="p-4" />

        <MotionDiv className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="flex flex-col gap-8">
            <ContractorPersonalInformation
              contractor={contractorQuery.data}
              isPending={contractorQuery.isPending}
            />

            <ContractorTaxInformation
              contractor={contractorQuery.data}
              isPending={contractorQuery.isPending}
            />
          </div>
          <div className="col-span-2 flex flex-col gap-8">
            <ContractorToDos contractor={contractorQuery.data} />

            <ContractsSection
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('contract', latestContract?.id ?? '')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPaymentDetails
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('payment-details', 'true')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPayments
              contractorId={contractorQuery.data?.id}
              payments={contractorPaymentsQuery.data}
              isPending={contractorPaymentsQuery.isPending}
            />
          </div>
        </MotionDiv>
      </div>

      <ContractDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('contract')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('contract')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />

      <ContractorPaymentDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('payment-details')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('payment-details')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </>
  )
}
