import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, Link, useNavigate, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ActiveFilters,
  ContractTypeBadge,
  ContractTypeFilter,
  DateBadge,
  DateFilter,
  FiltersWidget,
  START_DATE_FROM_FILTER_NAME,
  START_DATE_TO_FILTER_NAME,
} from '@/domains/Business/components'
import { useSearchInput } from '@/hooks/useSearchInput'
import { getAnimationKey } from '@/lib/utils'
import { Plus } from '@/shared/icons/outline'
import { Button, DataTable, MotionDiv, SearchInput } from '@/shared/ui'

import { getContractors } from '../api'
import { EmptyContractors, useContractorsColumns } from '../components'
import { Contractor } from '../types'

export const AllContractors = () => {
  const intl = useIntl()
  const columns = useContractorsColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries([...searchParams])

  const [contractorsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractors, params],
        queryFn: () => getContractors(params),
        select: (data: AxiosResponse<Contractor[]>) => data?.data,
      },
    ],
  })

  return (
    <MotionDiv>
      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <ContractTypeFilter />
          <DateFilter
            label={intl.formatMessage({
              id: 'label.startDate',
              defaultMessage: 'Start date',
            })}
            fromFilterName={START_DATE_FROM_FILTER_NAME}
            toFilterName={START_DATE_TO_FILTER_NAME}
          />
        </FiltersWidget>

        <Button
          leftIcon={<Plus className="size-5" />}
          asChild
          variant="tertiary"
        >
          <Link to={BusinessRoute.ContractorsCreate}>
            <FormattedMessage
              id="action.newContractor"
              defaultMessage="New contractor"
            />
          </Link>
        </Button>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <ContractTypeBadge />
        <DateBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(contractorsQuery.isPending, params)}>
        {contractorsQuery.data?.length === 0 &&
        Object.keys(params).length === 0 ? (
          <EmptyContractors />
        ) : (
          <DataTable
            emptyState={{
              title: intl.formatMessage({
                id: 'contractors.search.notFound',
                defaultMessage: 'No contractors found',
              }),
              description: intl.formatMessage({
                id: 'contractors.search.notFound.description',
                defaultMessage:
                  'There were no contractors that matched your search criteria',
              }),
            }}
            loaderOptions={{ rows: 1 }}
            isLoading={contractorsQuery.isPending}
            columns={columns}
            data={contractorsQuery.data ?? []}
            onRowClick={(contractor) => {
              navigate(
                generatePath(BusinessRoute.ContractorsDetails, {
                  id: contractor.id,
                }),
              )
            }}
          />
        )}
      </MotionDiv>
    </MotionDiv>
  )
}
