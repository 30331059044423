import { formatDate, isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { usePaymentFrequency } from '../../../hooks'
import { ContractorsTransaction, HistoricalPaymentType } from '../../../types'
import { ContractorPaymentAvatar } from '../../ContractorDetails/ContractorPaymentAvatar'

type Props = {
  payment: ContractorsTransaction
}

export const ContractorPaymentCycleCell = ({ payment }: Props) => {
  const frequency = usePaymentFrequency({
    dayType:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDayType
        : undefined,
    day:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentDay
        : undefined,
    frequency:
      payment.type === HistoricalPaymentType.PAYMENT_CYCLE
        ? payment.paymentDetails.paymentFrequency
        : undefined,
  })

  switch (payment.type) {
    case HistoricalPaymentType.PAYMENT_CYCLE:
      return (
        <div className="flex items-center gap-3">
          <ContractorPaymentAvatar payment={payment} />

          <div className="flex flex-col">
            <Typography bold>
              {intersperse(
                [
                  formatDate(
                    payment.paymentDetails.paymentCycleStart,
                    isSameYear(
                      payment.paymentDetails.paymentCycleStart,
                      payment.paymentDetails.paymentCycleEnd,
                    )
                      ? 'dd MMM.'
                      : 'dd MMM. yyyy',
                  ),
                  formatDate(
                    payment.paymentDetails.paymentCycleEnd,
                    'dd MMM. yyyy',
                  ),
                ],
                ' - ',
              )}
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              {frequency}
            </Typography>
          </div>
        </div>
      )

    case HistoricalPaymentType.OFF_CYCLE:
      return (
        <div className="flex items-center gap-3">
          <ContractorPaymentAvatar payment={payment} />

          <div className="flex flex-col">
            <Typography bold>{payment.paymentDetails.paymentLabel}</Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="contractor.paymentCategory.label"
                defaultMessage="{type, select, BONUS {Bonus} COMMISSION {Commission} REIMBURSEMENT {Reimbursement} EXTRA_HOURS {Extra hours} ADJUSTMENT {Adjustment} SETTLEMENT {Settlement} other {}}"
                values={{ type: payment.paymentCategory }}
              />
            </Typography>
          </div>
        </div>
      )

    default:
      return null
  }
}
