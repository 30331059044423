import { zodResolver } from '@hookform/resolvers/zod'
import { Big } from 'big.js'
import { useFieldArray, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import {
  Button,
  Card,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  ContractorPaymentCycleDetails,
  RunPaymentContractor,
} from '../../../types'
import { ContractorAmountRow } from '../../ContractorAmountRow'

import { offCyclePaymentFormSchema, OffCyclePaymentFormSchema } from './schema'

const FIXED_CONTRACTORS_FORM_ID = 'fixed-contractors-form-id'

type Props = {
  contractors: ContractorPaymentCycleDetails['fixedRatePayments']
  onContinue: (contractors: RunPaymentContractor[]) => void
  fixedIdsAndAmounts: RunPaymentContractor[]
}

export const OffCycleFixedContractorsStep = ({
  contractors,
  onContinue,
  fixedIdsAndAmounts,
}: Props) => {
  const initialContractors = contractors.map((contractor) => ({
    contractorId: contractor.contractorId,
    contractId: contractor.contractId,
    amount: fixedIdsAndAmounts.find(
      (idAndAmount) => idAndAmount.contractorId === contractor.contractorId,
    )?.amount ?? {
      currency: Currency.USD,
      amount: '',
    },
  }))

  const form = useForm<OffCyclePaymentFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(offCyclePaymentFormSchema),
    values: { contractors: initialContractors },
  })

  const { fields } = useFieldArray({
    name: 'contractors',
    control: form.control,
  })

  const selectedContractors = form.watch('contractors')

  const calculateTotalAmount = () => {
    return (
      selectedContractors?.reduce(
        (acc, contractor) =>
          acc.plus(
            contractor.amount.amount === ''
              ? Big(0)
              : Big(contractor.amount.amount ?? 0),
          ),
        Big(0),
      ) ?? Big(0)
    ).toNumber()
  }

  const handleSubmit = (data: OffCyclePaymentFormSchema) => {
    const filteredContractors = data.contractors.filter(
      (contractor) => contractor.amount.amount !== '',
    ) as RunPaymentContractor[]

    onContinue(filteredContractors)
  }

  return (
    <>
      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Pay your fixed-rate contractors"
            id="contractors.runPayment.fixedContractors.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="contractors.runPayment.fixedContractors.subtitle"
            defaultMessage="Confirm the fixed-rate contractors you want to pay"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col">
          <div className="mb-2 flex justify-between px-6">
            <div className="flex items-center gap-4">
              <Typography className="text-neutral-gray-600">
                <FormattedMessage
                  id="contractors.runPayment.totalContractors"
                  defaultMessage="Contractors • {count} total"
                  values={{ count: selectedContractors.length }}
                />
              </Typography>
            </div>
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.runPayment.totalAmount"
                defaultMessage="Total • {amount}"
                values={{
                  amount: formatAmount({
                    amount: calculateTotalAmount(),
                    currency: Currency.USDC,
                  }),
                }}
              />
            </Typography>
          </div>

          <Card>
            <Form {...form}>
              <form
                id={FIXED_CONTRACTORS_FORM_ID}
                onSubmit={form.handleSubmit(handleSubmit)}
              >
                {fields.map((field, index) => (
                  <FormField
                    control={form.control}
                    key={field.id}
                    name={`contractors.${index}.amount.amount`}
                    render={({ field: formField }) => (
                      <ContractorAmountRow
                        key={field.id}
                        field={formField}
                        contractor={contractors?.[index]}
                      />
                    )}
                  />
                ))}
              </form>
            </Form>
          </Card>
        </div>

        <StickyContainer>
          <Button
            form={FIXED_CONTRACTORS_FORM_ID}
            onClick={form.handleSubmit(handleSubmit)}
            type="submit"
            width="full"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
