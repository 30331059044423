import { FormattedMessage } from 'react-intl'

import { Widget } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Avatar, MotionDiv, Skeleton, Typography } from '@/shared/ui'

import { useActivePaymentMethod } from '../../hooks/useActivePaymentMethod'
import { ContractorDetails } from '../../types'
import { ContractorPaymentBadge } from '../ContractorPaymentBadge'

type Props = {
  onClick: () => void
  contractor?: ContractorDetails
}

export const ContractorPaymentDetails = ({ onClick, contractor }: Props) => {
  const activePaymentMethod = useActivePaymentMethod(contractor)

  const { title, subtitle, Icon } = activePaymentMethod

  return (
    <Widget
      className="group"
      onClick={onClick}
      title={
        <FormattedMessage
          id="label.paymentDetails"
          defaultMessage="Payment details"
        />
      }
    >
      <MotionDiv
        key={contractor?.id}
        className="flex w-full items-center justify-between"
      >
        {contractor ? (
          <>
            <div className="flex items-center gap-2">
              <Avatar shape="square" icon={<Icon className="size-5" />} />

              <div className="flex flex-col">
                <Typography bold>{title}</Typography>
                <Typography
                  variant="body-small"
                  className="text-neutral-gray-600"
                >
                  {subtitle}
                </Typography>
              </div>
            </div>
            <div className="flex items-center">
              <ContractorPaymentBadge state={contractor.paymentMethod.state} />

              <ChevronRight className="size-8 shrink-0 text-neutral-gray-600 group-hover:translate-x-1 group-hover:transition-all" />
            </div>
          </>
        ) : (
          <>
            <Skeleton className="h-[39px] w-36" />
            <Skeleton className="h-[21px] w-36" />
          </>
        )}
      </MotionDiv>
    </Widget>
  )
}
