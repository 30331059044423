import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { getFullName } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { ContractorPaymentCyclePayment } from '../../types'
import { getBankAccountLabel } from '../../utils'

type Props = {
  contractor: ContractorPaymentCyclePayment
}

export const ManualCSVContractorRow = ({ contractor }: Props) => {
  return (
    <div className="flex items-center justify-between border-b border-neutral-gray-200 last:border-b-0">
      <div className="flex flex-col gap-1 py-3">
        <Typography bold>{getFullName(contractor?.personalDetails)}</Typography>

        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="contractor.runPayment.payTo"
            defaultMessage="Pay to {accountType}"
            values={{
              accountType: (
                <FormattedMessage
                  {...getBankAccountLabel(contractor?.paymentMethodType)}
                />
              ),
            }}
          />
        </Typography>
      </div>

      <div className="flex flex-col">
        <Typography text="right" bold>
          {formatAmount({
            amount: contractor.paymentAmount?.amount ?? 0,
            currency: contractor.paymentAmount?.currency ?? Currency.USD,
          })}
        </Typography>
        <Typography text="right" className="text-neutral-gray-600">
          {formatAmount({
            amount: contractor.paymentAmount?.amount ?? 0,
            currency: Currency.USDC,
          })}
        </Typography>
      </div>
    </div>
  )
}
